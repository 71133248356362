module.exports = {
  services: /(audioitems|filters|eventreports|resources|playlists)/,
  apiVersion: "v1",
  proxy: [
    "audioitems",
    "audioitems/valuesForFieldMatching",
    "audioitems/export",
    "filters",
    "eventreports",
    "resources",
    "playlists"
  ],
  appName: "audio-hub"
};
