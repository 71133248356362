import { computed } from "@vue/composition-api";

export default (guid: string) => {
  const isExternal = computed(() => {
    const PREFIX = "external:";

    return guid?.startsWith(PREFIX);
  });

  return {
    isExternal
  };
};
