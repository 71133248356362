





















































































































































































import { computed, defineComponent, ref, inject } from "@/composition-api";
import useBreakpoints from "@/composition/useBreakpoints";
import useFetchCuratedSearch from "@/composition/useFetchCuratedSearch";
import { useResetFilterRules } from "@/components/nav/navigation.composition";
import useGetUserRole from "@/composition/useGetUserRole";
import store from "@/store";
import router from "@/router";
import {
  Atmo as AtmoIcon,
  Diamond as DiamondIcon,
  Home as HomeIcon,
  Interview as InterviewIcon,
  OTon as OTonIcon,
  Settings as SettingsIcon,
  Survey as SurveyIcon,
  Grid as GridIcon,
  Plus as PlusCircleIcon,
  ListIcon,
  Star as StarIcon
} from "./icons/index";
import NavItem from "./NavItem.vue";
import NavItemLegalLink from "./NavItemLegalLink.vue";
import NavItemCollapsed from "./NavItemCollapsed.vue";
import NavItemContextMenu from "./NavItemContextMenu.vue";
import UiIconClose from "../ui/UiIconClose.vue";
import { isCuratedSearchAndPlaylistsEnabled } from "../../utils/featureFlag";

import {
  /* savedSearches, */ actions,
  curatedSearchNavigation,
  playlistNavigation,
  uplaodAudioNavigation,
  legalLinks,
  defaultSort as sort
} from "./config/index";
import {
  useNavLinkHelpers,
  isExactLink,
  getShortcutQuery
} from "./navigation.composition";
import useSharedSearch from "@/composition/useSharedSearch";
import useMatomoEvent from "@/composition/useMatomoEvent";

export default defineComponent({
  components: {
    AtmoIcon,
    DiamondIcon,
    HomeIcon,
    InterviewIcon,
    NavItem,
    NavItemLegalLink,
    NavItemCollapsed,
    NavItemContextMenu,
    OTonIcon,
    ListIcon,
    StarIcon,
    SettingsIcon,
    SurveyIcon,
    UiIconClose,
    GridIcon,
    PlusCircleIcon
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  setup(_ctx, { root, emit }) {
    const { isMobile, isTablet, isDesktop } = useBreakpoints();
    const showByIndex = ref(null);
    const eventBus: Vue = inject("eventBus");

    const {
      navConfig,
      featureTypesConfig,
      onNavClick,
      close
    } = useNavLinkHelpers(emit)();

    const { resetFilterRules } = useResetFilterRules();

    const { saveSharedFilter } = useSharedSearch(root);
    const showFilterSharingDialog = ({ name, itemId }) => {
      const savedSearch = {
        name: name,
        filterRules: store.getters["filters/getFilterById"](itemId).filterRules
      };
      saveSharedFilter(savedSearch);
      useMatomoEvent({
        category: "Left Navigation Bar Suche teilen",
        action: `Left Navigation Bar "Suche teilen"`,
        name: `Click "Suche teilen" in Left Navigation Bar`
      });
    };

    const savedSearches = computed(
      (): Array<{
        label: string;
        icon: string;
        iconColor: string;
        path?: string;
        query: {
          audioType?: string;
          sort?: string;
          filterId?: string;
        };
        isExactLink: {
          audioType?: string;
          query?: { audioType: string };
          filterId?: string;
        };
      }> => {
        return store.state.filters.filters
          .filter(
            ({ isVisible, curatedSearch }) =>
              isVisible && curatedSearch === false
          )
          .map(({ name, color, id }) => ({
            label: name,
            icon: "DiamondIcon",
            iconColor: color,
            path: "/audioitems",
            query: getShortcutQuery({
              query: {
                filterId: id,
                sort
              }
            }),
            isExactLink: isExactLink({ filterId: id })
          }));
      }
    );

    const currentPath = computed(() => {
      return store.state.route.path;
    });

    const state = computed(() => ({
      navConfig: navConfig.value,
      featureTypesConfig: featureTypesConfig.value,
      savedSearches: savedSearches.value,
      actions: actions.map(action => ({
        ...action,
        isExactLink: currentPath.value?.startsWith(action.to)
      })),
      legalLinks: legalLinks.map(legalLink => ({
        ...legalLink,
        isExactLink: currentPath.value?.startsWith(legalLink.to)
      })),
      curatedSearchNavigation: curatedSearchNavigation.map(curatedSearch => ({
        ...curatedSearch,
        isExactLink: currentPath.value?.startsWith(curatedSearch.to)
      })),
      playlistNavigation: playlistNavigation.map(playlist => ({
        ...playlist,
        isExactLink: currentPath.value?.startsWith(playlist.to)
      })),
      uplaodAudioNavigation: uplaodAudioNavigation.map(uploadAudio => ({
        ...uploadAudio,
        isExactLink: currentPath.value?.startsWith(uploadAudio.to)
      })),
      isTablet: isTablet.value,
      isDesktop: isDesktop.value
    }));

    const onSavedSearchSelected = ({ value, savedSearch }) => {
      const authJWT = store.state.auth.token;

      switch (value) {
        case "edit":
          router.push({
            name: "settingsDetail",
            params: { filterId: savedSearch.query.filterId }
          });
          onNavClick({
            query: {},
            isExactLink: savedSearch.isExactLink
          });
          break;
        case "hide":
          store.dispatch("filters/updateFilterIsVisible", {
            id: savedSearch.query.filterId,
            isVisible: false,
            authJWT
          });
          break;
        case "delete":
          store.dispatch("filters/deleteFilter", {
            filter: store.getters["filters/getFilterById"](
              savedSearch.query.filterId
            ),
            authJWT
          });

          eventBus.$emit("show", {
            title: `"${savedSearch.label}" wurde gelöscht`,
            icon: "delete",
            action() {
              store.dispatch("filters/undo");
            },
            actionTitle: "Rückgängig"
          });
          break;
        case "share":
          showFilterSharingDialog({
            name: savedSearch.label,
            itemId: savedSearch.query.filterId
          });
          break;
        default:
          break;
      }
    };

    const calculateMaxHeight = computed(() => {
      const offset = isDesktop.value ? 95 : 30;

      return { "max-height": `calc(100% - ${offset}px)` };
    });

    const isCuratedSearch = ref(false);

    const { curatedSearches } = useFetchCuratedSearch();

    if (curatedSearches) {
      isCuratedSearch.value = true;
    }

    const { isAdminOrEditor } = useGetUserRole();

    const showCookiesLink = computed(
      () =>
        store.state.stageconfig.config?.features
          ?.enableOneTrustCookieConsentBanner
    );

    return {
      state,
      close,
      onNavClick,
      isMobile,
      isTablet,
      isDesktop,
      screen,
      savedSearches,
      showByIndex,
      onSavedSearchSelected,
      calculateMaxHeight,
      resetFilterRules,
      isCuratedSearch,
      isAdminOrEditor,
      showCookiesLink,
      isCuratedSearchAndPlaylistsEnabled
    };
  }
});
