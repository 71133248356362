/* eslint-disable camelcase */

import { format, startOfDay, parseISO, isValid, isEqual } from "date-fns";
import { GetterTree } from "vuex";
import {
  getAudioitemType,
  getDuration
} from "@/components/AHAudioitem/shared/utils";
import { AudioItemsState } from "./types";
import { RootState } from "../../types";

// RegEx patterns
const HIGHLIGHT_TAG_START = /^<mark/;
// eslint-disable-next-line no-useless-escape
const HIGHLIGHT_TAGS = /<mark class=\"highlight\">|<\/mark>/g;
const ELLIPSIS_START = /^\(…\)\s/;
const ELLIPSIS_END = /\s\(…\)$/;

const getHighlightTokens = (highlights: string): string[] => {
  // EC-3503 workaround for negative lookbehind to split by space excluding highlights
  const excludeHighlightTags = String(highlights);

  return excludeHighlightTags.split(" ");
};

const getFirstHighlightIndex = (snippetHighlightTokens: string[]) =>
  snippetHighlightTokens?.findIndex(token => token.match(HIGHLIGHT_TAG_START));

const getTextFromHighlights = (snippetWithHighlights: string) =>
  snippetWithHighlights?.replaceAll(HIGHLIGHT_TAGS, "");

const isStartOfTranscript = (snippetWithHighlights: string) =>
  !snippetWithHighlights?.match(ELLIPSIS_START);

const isEndOfTranscript = (snippetWithHighlights: string) =>
  !snippetWithHighlights?.match(ELLIPSIS_END);

const getShouldRemoveWordFromStart = (highlightTokens: string[]) => {
  const firstHighlightIndex = getFirstHighlightIndex(highlightTokens);

  return firstHighlightIndex > highlightTokens.length / 2;
};

const getIndexToRemove = ({
  highlightTokens,
  snippetWithHighlights
}: {
  highlightTokens: string[];
  snippetWithHighlights: string;
}) => {
  const shouldRemoveWordFromStart = getShouldRemoveWordFromStart(
    highlightTokens
  );
  let indexToRemove = shouldRemoveWordFromStart
    ? 0
    : highlightTokens.length - 1;

  if (
    shouldRemoveWordFromStart &&
    !isStartOfTranscript(snippetWithHighlights)
  ) {
    indexToRemove += 1;
  }

  if (!shouldRemoveWordFromStart && !isEndOfTranscript(snippetWithHighlights)) {
    indexToRemove -= 1;
  }

  return indexToRemove;
};

const checkTokenPositionInSnippet = ({
  isStart,
  token,
  truncatedSnippet
}: {
  isStart: boolean;
  token: string;
  truncatedSnippet: string;
}) => {
  try {
    return truncatedSnippet.match(
      new RegExp(
        String.raw`${isStart ? "^" : ""}${token}${isStart ? "" : "$"}`,
        "g"
      )
    );
  } catch (e) {
    return false;
  }
};

const formatTruncatedSnippet = (
  snippetWithHighlights: string,
  truncatedSnippet: string
) => {
  const highlightTokens = getHighlightTokens(snippetWithHighlights);
  const firstToken = highlightTokens[0];
  const lastToken = highlightTokens[highlightTokens.length - 1];

  let formattedSnippet = truncatedSnippet;

  const isTruncatedFromStart =
    isStartOfTranscript(snippetWithHighlights) &&
    !checkTokenPositionInSnippet({
      isStart: true,
      token: firstToken,
      truncatedSnippet
    });
  if (isTruncatedFromStart) {
    formattedSnippet = `(…) ${formattedSnippet}`;
  }

  const isTruncatedFromEnd =
    isEndOfTranscript(snippetWithHighlights) &&
    !checkTokenPositionInSnippet({
      isStart: false,
      token: lastToken,
      truncatedSnippet
    });
  if (isTruncatedFromEnd) {
    formattedSnippet = `${formattedSnippet} (…)`;
  }

  return formattedSnippet;
};

const truncateSnippetHighlights = (fragmentSize: number) => (
  snippetWithHighlights: string
) => {
  const highlightTokens = getHighlightTokens(snippetWithHighlights);

  let snippetText = getTextFromHighlights(snippetWithHighlights);
  let numberOfSnippetCharacters = snippetText?.length;
  let truncatedSnippet = snippetWithHighlights;

  const snippetTokens = snippetText?.split(" ");

  while (numberOfSnippetCharacters > fragmentSize) {
    const indexToRemove = getIndexToRemove({
      highlightTokens,
      snippetWithHighlights
    });

    highlightTokens.splice(indexToRemove, 1);
    truncatedSnippet = highlightTokens.join(" ");

    snippetTokens.splice(indexToRemove, 1);
    snippetText = snippetTokens.join(" ");
    numberOfSnippetCharacters = snippetText.length;
  }

  const isSnippetTruncated =
    snippetWithHighlights.length !== truncatedSnippet.length;

  if (isSnippetTruncated) {
    truncatedSnippet = formatTruncatedSnippet(
      snippetWithHighlights,
      truncatedSnippet
    );
  }
  return truncatedSnippet;
};

const matchPersonSubject = ({ name, type }) =>
  !!name && type === "http://cv.iptc.org/newscodes/cpnature/person";

const formatPersonName = (personHighlights: string[]) => ({
  name
}: {
  name: string;
}) => {
  const personsFromHighlights = personHighlights.map(getTextFromHighlights);

  const personIndex = personsFromHighlights.indexOf(name);
  if (personIndex > -1) {
    return personHighlights[personIndex];
  }
  return name;
};

export const getAudioContent = (audioItem: Definitions.AudioItem) =>
  audioItem.remoteContent?.find(({ contentType }) =>
    contentType.match(/^audio\/mp3/)
  );

const getAudioitemRenderProps = (
  audioItem: Definitions.AudioItem,
  // Optional fragmentSize param for list view
  fragmentSize: number | undefined = undefined
) => {
  const audioContent = getAudioContent(audioItem);

  const duration = getDuration(audioContent.duration);

  const description = audioItem.description[0]?.text;

  const { guid, polled } = audioItem;

  const headline = audioItem.headline[0];

  const keywords = audioItem.keyword;
  const isFavorite = audioItem.isFavorite;

  let { highlights } = audioItem;
  const snippet = highlights.transcript || highlights.description;
  if (snippet && fragmentSize) {
    highlights = {
      ...highlights,
      snippet: snippet.map(truncateSnippetHighlights(fragmentSize))
    };
  }

  // Includes <mark>
  const personHighlights = highlights.person || [];

  const persons = audioItem.subject
    .filter(matchPersonSubject)
    .map(formatPersonName(personHighlights));

  const type = getAudioitemType(audioItem.service);

  const { waveform } = audioItem;

  const version_created = format(
    new Date(audioItem.version_created),
    "dd.MM.yyyy HH:mm"
  );

  const formatContentCreated = isoString => {
    if (!isoString) {
      return "";
    }

    const parsedDate = parseISO(isoString);
    if (!isValid(parsedDate)) {
      return "";
    }

    // don't show time HH:mm when only date is given
    const hasTime = !isEqual(parsedDate, startOfDay(parsedDate));

    if (hasTime) {
      return format(parsedDate, "dd.MM.yyyy HH:mm");
    } else {
      return format(parsedDate, "dd.MM.yyyy");
    }
  };

  const content_created = formatContentCreated(audioItem.content_created);

  return {
    audioContent,
    description,
    duration,
    guid,
    polled,
    headline,
    highlights,
    keywords,
    persons,
    type,
    version_created,
    content_created,
    waveform,
    isFavorite
  };
};

export const getters: GetterTree<AudioItemsState, RootState> = {
  getActiveAudioItem(state): ViewModel.AudioItem | null {
    if (state.activeAudioItem !== null) {
      const { transcript } = state.activeAudioItem;
      return {
        ...getAudioitemRenderProps(state.activeAudioItem),
        transcript
      };
    }
    return null;
  },

  audioItemById: (state): Function => (guid: string): ViewModel.AudioItem => {
    const audioItem = state.audioitems.find(item => item.guid === guid);

    if (audioItem) {
      return getAudioitemRenderProps(audioItem, state.fragmentSize);
    }

    return null;
  }
};
