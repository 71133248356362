import { reactive } from "@/composition-api";
import { Component } from "vue";

interface AppBannerState {
  bgColor: string;
  content: string | Component;
  isVisible: boolean;
}

export const appBannerState: AppBannerState = reactive({
  bgColor: "",
  content: "",
  isVisible: false
});
