export interface NavigationState {
  isLeftNavOpen: boolean;
  isSearchOpen: boolean;
}

const state: NavigationState = {
  isLeftNavOpen: false,
  isSearchOpen: false
};

export default {
  state,
  mutations: {
    setIsLeftNavOpen: (state, isLeftNavOpen) => {
      state.isLeftNavOpen = isLeftNavOpen;
    },
    setIsSearchOpen: (state, isSearchOpen) => {
      state.isSearchOpen = isSearchOpen;
    }
  },
  namespaced: true
};
