import { computed } from "@/composition-api";
import store from "@/store";

export default () => ({
  currentFilterRules: computed(
    () => store.state.expandedSearch.currentFilterRules
  ),
  isExpandedSearchOpen: computed(
    () => store.state.expandedSearch.isExpandedSearchOpen
  ),
  numberOfSearchCriteria: computed(
    () => store.getters["filterRules/numberOfSearchCriteria"]
  )
});
