export { default as Atmo } from "@/assets/vectors/icons/type-atmo.svg?inline";
export { default as Interview } from "@/assets/vectors/icons/type-interview.svg?inline";
export { default as OTon } from "@/assets/vectors/icons/type-oton.svg?inline";
export { default as Home } from "@/assets/vectors/icons/home.svg?inline";
export { default as Survey } from "@/assets/vectors/icons/type-survey.svg?inline";
export { default as Settings } from "@/assets/vectors/icons/settings.svg?inline";
export { default as Grid } from "@/assets/vectors/icons/grid-4.svg?inline";
export { default as Plus } from "@/assets/vectors/icons/plus-circle.svg?inline";
export { default as Diamond } from "@/assets/vectors/icons/diamond.svg?inline";
export { default as ListIcon } from "@/assets/vectors/icons/list.svg?inline";
export { default as Star } from "@/assets/vectors/icons/star.svg?inline";
