import Vue from "vue";
import VueI18n from "vue-i18n";
import { getBrowserLang, getAltLang } from "./utils/language";

Vue.use(VueI18n);

export function loadLocaleMessages() {
  const locales = require.context(
    "./locales/messages",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function loadLocaleDateTimeFormats() {
  const locales = require.context(
    "./locales/dateTimeFormats",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const dateTimeFormats = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      dateTimeFormats[locale] = locales(key);
    }
  });
  return dateTimeFormats;
}

const browserLang = getBrowserLang();
const altLang = getAltLang(browserLang);

export default new VueI18n({
  locale: browserLang,
  fallbackLocale: altLang,
  messages: loadLocaleMessages(),
  dateTimeFormats: loadLocaleDateTimeFormats()
});
