





































































import equal from "fast-deep-equal";
import { defaultSort as sort } from "../nav/config/index";
import { defineComponent, computed, ref, watch } from "@/composition-api";
import { getDownloadQuota } from "@/composition/useAuthorizeToken";
import { useResetFilterRules } from "@/components/nav/navigation.composition";
import AHAudioPlayerEventBus from "@/components/AHAudioPlayer/AHAudioPlayerEventBus";
import AHSearch from "../AHSearch/AHSearch.vue";
import AHUser from "./AHUser/AHUser.vue";
import AHHelpDropdown from "./AHHelpDropdown/AHHelpDropdown.vue";
import AuthKeepAlive from "@dpa-connect/dpa-id-user/AuthKeepAlive.vue";
import DpaAudioHubLogo from "./DpaAudioHubLogo.vue";
import DpaIdAppSwitcher from "@dpa-id-components/dpa-id-app-switcher";
import store from "@/store";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";
import UiIconMenu from "@/assets/vectors/icons/menu.svg?inline";
import UiIconSearch from "@/assets/vectors/icons/search.svg?inline";
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import UiIcon from "@dpa-id-components/ui-icon";
import UiIconButton from "@dpa-id-components/ui-icon-button";
import UiMenu from "@dpa-id-components/ui-menu";
import "@dpa-id-components/ui-menu/dist/UiMenu.css";
import UiList from "@dpa-id-components/ui-list";
import UiListItem from "@dpa-id-components/ui-list-item";
import useAuthentication from "@/composition/useAuthentication";
import useBreakpoints from "@/composition/useBreakpoints";
import useDownloadBanner from "@/composition/useDownloadBanner";
import useExpandedSearch from "@/composition/useExpandedSearch";
import useFilterRulesCount from "@/composition/useFilterRulesCount";
import useNavigation from "@/composition/useNavigation";
import useRouter from "@/composition/useRouter";
import redirect from "@dpa-connect/dpa-id-user/store/utils/redirect";
import windowHelper from "@dpa-connect/dpa-id-user/store/utils/window";

const ls = windowHelper().localStorage;

export default defineComponent({
  components: {
    AHExpanededSearch: () => import("../AHSearch/AHExpandedSearch.vue"),
    AHSearch,
    AHUser,
    AHHelpDropdown,
    AuthKeepAlive,
    DpaAudioHubLogo,
    DpaIdAppSwitcher,
    UiButtonIcon,
    UiIconMenu,
    UiIconSearch,
    UiTransitionOpacity,
    UiIcon,
    UiIconButton,
    UiMenu,
    UiList,
    UiListItem
  },
  props: ["token", "profileUrl"],
  setup(props, { emit, root }) {
    const { logout, isAuthenticated, ssoURL, status } = useAuthentication();
    const { screen, isMobile } = useBreakpoints();
    const { isSearchOpen } = useNavigation();
    const { route } = useRouter();
    const { hasFilterRules } = useFilterRulesCount();

    const toggleMenu = () => {
      emit("menuClick");
    };

    const toggleSearch = () => {
      emit("searchClick");
    };

    const closeSearch = () => {
      if (isSearchOpen && isMobile) {
        root.$store.commit("navigation/setIsSearchOpen", false);
      }
    };

    const isInitialState = computed(() => {
      const defaultPath = "/audioitems/list";
      const defaultQuery = { sort };
      const { path, query } = route.value;

      return (
        path === defaultPath &&
        equal(query, defaultQuery) &&
        !hasFilterRules.value
      );
    });

    const isExpandedSearchOpen = computed(
      () => store.state.expandedSearch.isExpandedSearchOpen
    );

    // Calculate positioning and width of expanded search component
    const searchTemplateRef = ref(null);

    const expandedSearchStyles = ref({
      left: 0,
      width: 0
    });

    const setExpandedSearchStyles = () => {
      setTimeout(() => {
        if (searchTemplateRef.value) {
          const inputElement: Element = searchTemplateRef.value.$el;
          const { x, width } = inputElement.getBoundingClientRect();

          expandedSearchStyles.value = {
            left: x,
            width
          };
        }
      }, 250);
    };

    // Update expanded search styles on window resize
    const vw = computed(() => screen.value?.vw);
    watch(vw, setExpandedSearchStyles);

    const showSaveSearchDialog = computed(
      () => store.state.filters.showSaveSearchDialog
    );

    const { resetFilterRules } = useResetFilterRules();
    const { executeSearch } = useExpandedSearch();

    /* search and clear button interaction */
    const onClearButtonClick = () => {
      if (store.state.filterRules.filterRules.length) {
        resetFilterRules();
        executeSearch();
      }
    };

    const closePlayer = () => {
      if (store.state.audioPlayer.isActive) {
        AHAudioPlayerEventBus.$emit("close");
      }
    };

    const { toggleDownloadBanner } = useDownloadBanner();

    const checkQuota = async () => {
      const {
        userQuotaStanding,
        accessStatus,
        quotaAvailable,
        unlimited
      } = await getDownloadQuota();
      toggleDownloadBanner({
        userQuotaStanding,
        accessStatus,
        quotaAvailable,
        unlimited
      });
    };

    // Initial check on login
    watch(
      isAuthenticated,
      () => {
        if (isAuthenticated.value) {
          checkQuota();
        }
      },
      { immediate: true }
    );

    const KEEPALIVE_TIMEOUT = 72000000; // 4hours

    const isAuth0Enabled = computed(() => {
      return store.state.stageconfig.config.features.enableAuth0;
    });

    const clearToken = () => {
      // NB: Remove "stale" JWT token from session storage
      const sessionAuthJWT = ls.getItem("dpaID-token");
      if (sessionAuthJWT) {
        ls.removeItem("dpaID-token");
      }
      if (isAuth0Enabled && isAuth0Enabled.value) {
        // Loutout unless on the unauthorized page
        if (
          store.state.stageconfig.config.ssoURLAuth0 &&
          !window.location.pathname.match(/\/unauthorized/)
        ) {
          const path = status.value === "loggedout" ? "/logout" : "/login";
          redirect(
            `${
              store.state.stageconfig.config.ssoURLAuth0
            }${path}?returnTo=${encodeURIComponent(
              window.location.origin
            )}&client_id=${store.state.stageconfig.config.auth0ClientId}`
          );
        }
      }
    };
    const isClicked = ref(false);

    return {
      closePlayer,
      closeSearch,
      expandedSearchStyles,
      isExpandedSearchOpen,
      isInitialState,
      isMobile,
      isSearchOpen,
      logout,
      onClearButtonClick,
      screen,
      searchTemplateRef,
      setExpandedSearchStyles,
      showSaveSearchDialog,
      ssoURL,
      status,
      timeout: KEEPALIVE_TIMEOUT,
      toggleMenu,
      toggleSearch,
      clearToken,
      isClicked
    };
  }
});
