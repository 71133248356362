import axios from "axios";
import useAuthentication from "@/composition/useAuthentication";

/**
 * authenticate and retrieve a playback token for the given audio file.
 *
 * @param residref {String} - residref of the audio file to authorize.
 *
 * @returns token {String} - token to use against s3 URL for authorization.
 */
const usePlaybackToken = async (residref: string): Promise<string> => {
  const { validateAndRetrieveToken: authJWT } = useAuthentication();

  const res = await axios.get("/useradmin/authorizePlayback", {
    params: { guid: residref },
    headers: {
      Authorization: `Bearer ${authJWT()}`
    }
  });

  return res.data.token;
};

/**
 * authenticate and retrieve a download token for the given audio file.
 *
 * @param residref {String} - residref of the audio file to authorize.
 */
const useDownloadToken = async (residref: string): Promise<string> => {
  const { validateAndRetrieveToken: authJWT } = useAuthentication();

  const res = await axios.get("/useradmin/authorizeDownload", {
    params: { guid: residref },
    headers: {
      Authorization: `Bearer ${authJWT()}`
    }
  });

  return res.data.token;
};

interface AHUserPermissions {
  accessStatus?: string;
  allowedToDownload: boolean;
  quotaAvailable: number | null;
  quotaTotal?: number;
  quotaUsageRequired: boolean;
  quotaUsed?: number;
  unlimited?: boolean;
  userQuotaStanding?: string;
}

/**
 * pre authenticate a download to check for quota limits.
 *
 * @param residref {String} - residref of the audio file to authorize.
 */
const usePreAuthorizeDownload = async (
  residref: string
): Promise<AHUserPermissions> => {
  const { validateAndRetrieveToken: authJWT } = useAuthentication();

  const res = await axios.get("/useradmin/preAuthorizeDownload", {
    params: { guid: residref },
    headers: {
      Authorization: `Bearer ${authJWT()}`
    }
  });

  return {
    accessStatus: res.data.access_status,
    allowedToDownload: res.data.allowed_to_download,
    quotaAvailable: res.data.quota_available || null,
    quotaTotal: res.data.quota_total,
    quotaUsageRequired: res.data.quota_usage_required,
    quotaUsed: res.data.quota_used,
    unlimited: res.data.unlimited,
    userQuotaStanding: res.data.user_quota_standing
  };
};

const getDownloadQuota = async (): Promise<AHUserPermissions> => {
  const { validateAndRetrieveToken: authJWT } = useAuthentication();

  const res = await axios.get("/useradmin/quota", {
    headers: {
      Authorization: `Bearer ${authJWT()}`
    }
  });

  return {
    accessStatus: res.data.access_status,
    allowedToDownload: res.data.allowed_to_download,
    quotaAvailable: res.data.quota_available || null,
    quotaTotal: res.data.quota_total,
    quotaUsageRequired: res.data.quota_usage_required,
    quotaUsed: res.data.quota_used,
    unlimited: res.data.unlimited,
    userQuotaStanding: res.data.user_quota_standing
  };
};

export {
  usePlaybackToken,
  useDownloadToken,
  usePreAuthorizeDownload,
  getDownloadQuota
};
