export const defaultSort = JSON.stringify({
  field: "content_created",
  direction: "desc"
});

export const SERVICE_CODE_MAP = {
  aatmo: {
    name: "Atmo",
    uri: "http://g2.dpa.com/cv/service/aatmo"
  },
  aoton: {
    name: "O-Ton",
    uri: "http://g2.dpa.com/cv/service/aoton"
  },
  atalk: {
    name: "Interview",
    uri: "http://g2.dpa.com/cv/service/atalk"
  },
  aumfrage: {
    name: "Umfrage",
    uri: "http://g2.dpa.com/cv/service/aumfrage"
  }
};

export const types = [
  {
    label: "Alle Inhalte",
    icon: "HomeIcon",
    query: {
      audioType: "",
      sort: defaultSort
    }
  },
  {
    label: "O-Töne",
    icon: "OTonIcon",
    query: {
      audioType: "aoton",
      sort: defaultSort
    }
  },
  {
    label: "Atmo",
    icon: "AtmoIcon",
    query: {
      audioType: "aatmo",
      sort: defaultSort
    }
  },
  {
    label: "Interviews",
    icon: "InterviewIcon",
    query: {
      audioType: "atalk",
      sort: defaultSort
    }
  },
  {
    label: "Umfragen",
    icon: "SurveyIcon",
    query: {
      audioType: "aumfrage",
      sort: defaultSort
    }
  }
];

export const featureTypes = [
  {
    label: "Meine Favoriten",
    icon: "StarIcon",
    query: {
      audioType: "amyFavorites",
      sort: defaultSort
    }
  }
];

export const savedSearches = [
  {
    label: "Top-Themen",
    icon: "DiamondIcon",
    iconColor: "#F5138D"
  },
  {
    label: "Politik",
    icon: "DiamondIcon",
    iconColor: "#30DF7B"
  },
  {
    label: "Jura",
    icon: "DiamondIcon",
    iconColor: "#8E24AA"
  }
];

export const playlistNavigation = [
  {
    label: "Playlists",
    icon: "ListIcon",
    to: "/playlists"
  }
];

export const curatedSearchNavigation = [
  {
    label: "Suchvorschläge",
    icon: "GridIcon",
    to: "/curatedSearches"
  }
];

export const uplaodAudioNavigation = [
  {
    label: "Ton Hochladen",
    icon: "PlusCircleIcon",
    to: "/uploadAudio"
  }
];

export const actions = [
  {
    label: "Einstellungen",
    icon: "SettingsIcon",
    to: "/settings"
  }
];

export const legalLinks = [
  {
    label: "AGB",
    to: "/agb"
  },
  {
    label: "Datenschutz",
    to: "/privacy"
  },
  {
    label: "Impressum",
    to: "/imprint"
  }
];

export default {
  types,
  featureTypes,
  savedSearches,
  actions,
  legalLinks,
  curatedSearchNavigation,
  playlistNavigation,
  uplaodAudioNavigation
};
