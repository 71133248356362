import deepEqual from "fast-deep-equal";
import store from "@/store";
import { outerQuotes } from "../search.utils";

const SPACE_PATTERN = /\s/;
const SEPARATOR_PATTERN = /,\s?/;
const QUOTE_PATTERN = /^"$/;
const START_QUOTE_PATTERN = /^"/;
const END_QUOTE_PATTERN = /"$/;
export const TRAILING_COMMAS_PATTRN = /^,\s?|,\s?$/g;

export const formatWordsOrPhrase = (value: string, shouldPrefixNot = false) => {
  let wordOrPhrase = value.trim();

  const isPhrase = value !== " " && value?.match(SPACE_PATTERN);

  wordOrPhrase = isPhrase ? `"${value}"` : value;

  return `${shouldPrefixNot ? "NICHT " : ""}${wordOrPhrase}`;
};

export const getTextRepresentation = (
  fullTextFilterRules: FilterRules.FilterRule[],
  isAdvancedFilter = true
) =>
  fullTextFilterRules
    .map(({ values, exclude }) => {
      if (values.length === 1) {
        const [value] = values;

        const shouldPrefixNot = exclude && !isAdvancedFilter;

        return formatWordsOrPhrase(value, shouldPrefixNot);
      }

      return values
        .filter(value => !!value)
        .map(value => formatWordsOrPhrase(value))
        .join(" ODER ");
    })
    .join(", ");

export const getWordsOrPhrases = (inputText: string) =>
  inputText
    ?.split(SEPARATOR_PATTERN)
    .map((wordOrPhrase: string) => wordOrPhrase.trim());

export const getUpdatedFilterRules = (
  inputText: string,
  exclude = false,
  isLogicalOr = false
): FilterRules.FilterRule[] => {
  const formattedWordsOrPhrases = getWordsOrPhrases(inputText)
    .map(wordOrPhrase => wordOrPhrase.replaceAll(outerQuotes, ""))
    .filter(wordOrPhrase => !!wordOrPhrase);
  const fields = store.getters["filterRules/fullTextFilteredFields"];

  if (!isLogicalOr) {
    return formattedWordsOrPhrases.map(wordOrPhrase => ({
      type: "FILTER_FULLTEXT_FIELDS",
      values: [wordOrPhrase],
      ...(fields ? { fields } : {}),
      ...(exclude ? { exclude } : {})
    }));
  }

  const orFilterRule = formattedWordsOrPhrases.length
    ? formattedWordsOrPhrases.reduce(
        (filterRule, wordOrPhrase) =>
          wordOrPhrase
            ? {
                ...filterRule,
                values: [...filterRule.values, wordOrPhrase]
              }
            : filterRule,
        {
          type: "FILTER_FULLTEXT_FIELDS",
          values: [],
          ...(fields ? { fields } : {})
        }
      )
    : undefined;

  if (orFilterRule?.values.length === 1) {
    orFilterRule.values.push("");
  }

  return orFilterRule ? [orFilterRule] : [];
};

const getAreRulesChanged = ({ filterRules, updatedFilterRules }) =>
  filterRules.length === updatedFilterRules.length;

const getAddedFilterRules = ({ filterRules, updatedFilterRules }) =>
  updatedFilterRules.filter(
    (updatedRule: FilterRules.FilterRule) =>
      !getAreRulesChanged({ filterRules, updatedFilterRules }) &&
      !filterRules.some((filterRule: FilterRules.FilterRule) =>
        deepEqual(filterRule, updatedRule)
      )
  );

const getRemovedFilterRules = ({ filterRules, updatedFilterRules }) =>
  filterRules.filter(
    (filterRule: FilterRules.FilterRule) =>
      !getAreRulesChanged({ filterRules, updatedFilterRules }) &&
      !updatedFilterRules.some((updatedRule: FilterRules.FilterRule) =>
        deepEqual(filterRule, updatedRule)
      )
  );

const replaceFilterRules = ({ filterRules, updatedFilterRules }) => {
  store.commit("filterRules/replaceFilterRules", {
    filterRules,
    updatedFilterRules
  });

  return true;
};

const addFilterRules = ({ filterRules }) => {
  store.commit("filterRules/addFilterRules", {
    filterRules
  });

  return true;
};

const removeFilterRules = ({ filterRules }) => {
  store.commit("filterRules/removeFilterRules", {
    filterRules
  });

  return true;
};

const performUpdateMutations = ({ filterRules, updatedFilterRules }) => {
  const addedFilterRules = getAddedFilterRules({
    filterRules,
    updatedFilterRules
  });

  const removedRilterRules = getRemovedFilterRules({
    filterRules,
    updatedFilterRules
  });

  const areRulesChanged =
    getAreRulesChanged({
      filterRules,
      updatedFilterRules
    }) && replaceFilterRules({ filterRules, updatedFilterRules });

  const areRulesAdded =
    addedFilterRules.length &&
    addFilterRules({
      filterRules: addedFilterRules
    });

  const areRulesRemoved =
    removedRilterRules.length &&
    removeFilterRules({
      filterRules: removedRilterRules
    });

  return {
    areRulesChanged,
    areRulesAdded,
    areRulesRemoved
  };
};

export const useAdvancedFullTextFilters = () => {
  const updateFilterRules = (payload: {
    inputText: string;
    filterRules: FilterRules.FilterRule[];
    exclude?: boolean;
    isLogicalOr?: boolean;
  }) => {
    const { inputText, filterRules, exclude, isLogicalOr } = payload;

    const updatedFilterRules = getUpdatedFilterRules(
      inputText,
      exclude,
      isLogicalOr
    );

    performUpdateMutations({
      filterRules,
      updatedFilterRules
    });
  };

  const removeOrUpdateRules = ({
    removeCondition,
    currentFilterRule,
    updatedFilterRule
  }: {
    removeCondition: boolean;
    currentFilterRule: FilterRules.FilterRule;
    updatedFilterRule: FilterRules.FilterRule;
  }) => {
    if (removeCondition) {
      store.commit("filterRules/removeFilterRule", {
        filterRule: currentFilterRule
      });
    } else {
      const { type } = updatedFilterRule;
      store.commit("filterRules/setFilterRulesByType", {
        type,
        filterRules: [updatedFilterRule]
      });
    }
  };

  return { updateFilterRules, removeOrUpdateRules };
};

const isSingleQuote = (wordOrPhrase: string) =>
  wordOrPhrase?.match(QUOTE_PATTERN);

const hasStartQuote = (wordOrPhrase: string) =>
  wordOrPhrase?.match(START_QUOTE_PATTERN);

const hasEndQuote = (wordOrPhrase: string) =>
  wordOrPhrase?.match(END_QUOTE_PATTERN);

export const getIsTextComplete = (inputText: string) => {
  const hasTrailingComma = inputText?.match(TRAILING_COMMAS_PATTRN);
  if (hasTrailingComma) {
    return false;
  }

  const wordsOrPhrases = getWordsOrPhrases(inputText);
  return !wordsOrPhrases?.some(
    wordOrPhrase =>
      isSingleQuote(wordOrPhrase) ||
      (hasStartQuote(wordOrPhrase) && !hasEndQuote(wordOrPhrase))
  );
};
