import { computed } from "@/composition-api";
import useRouter from "@dpa-connect/dpa-id-user/compositions/useRouter";
import router from "@/router";
import store from "@/store";

export default () => {
  const { query } = useRouter(router)();

  return {
    audioitemService: computed(() => {
      const { audioType } = query.value;

      if (!audioType) return [];

      if (Array.isArray(audioType)) {
        return audioType.map((type: string) => type);
      }

      return [audioType];
    }),

    audioType: computed(() => {
      const { audioType } = query.value;

      return audioType;
    }),

    hasOnlyAudioTypeFilterRules: computed(() => {
      const audioTypeFilterRules = store.state.filterRules.filterRules.filter(
        ({ type }) => type === "FILTER_SERVICE"
      );

      return (
        audioTypeFilterRules.length ===
        store.state.filterRules.filterRules.length
      );
    })
  };
};
