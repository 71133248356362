


























import { defineComponent, onMounted, ref, inject } from "@/composition-api";
import UiIconCheckmark from "@/assets/vectors/icons/checkmark.svg?inline";
import UiIconDelete from "@/assets/vectors/icons/delete.svg?inline";

export default defineComponent({
  components: { UiIconCheckmark, UiIconDelete },
  setup() {
    const eventBus: Vue = inject("eventBus");

    const show = ref(false);
    const hasAction = ref(false);
    const timer = ref(null);

    const actionBtn = ref(null);

    const internalTitle = ref("");
    const internalIcon = ref("checkmark");
    const internalActionTitle = ref("");

    onMounted(() => {
      eventBus.$on(
        "show",
        ({
          title,
          icon,
          duration = 5000,
          action = null,
          actionTitle = "Rückgängig"
        }: {
          title: string;
          icon: string;
          duration: number;
          action: Function;
          actionTitle: string;
        }) => {
          clearTimeout(timer.value);

          show.value = true;
          hasAction.value = !!action;

          internalTitle.value = title;
          internalIcon.value = icon;
          internalActionTitle.value = actionTitle;

          const actionHandler = () => {
            clearTimeout(timer.value);
            show.value = false;
            if (action && action instanceof Function) {
              action();
            }
            actionBtn.value.removeEventListener("click", actionHandler);
          };

          if (action) {
            actionBtn.value.addEventListener("click", actionHandler);
          }

          timer.value = setTimeout(() => {
            show.value = false;
            if (action) {
              actionBtn.value.removeEventListener("click", actionHandler);
            }
          }, duration);
        }
      );
    });

    return {
      show,
      hasAction,
      actionBtn,
      internalTitle,
      internalIcon,
      internalActionTitle
    };
  }
});
