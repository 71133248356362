







































































import { defineComponent } from "@/composition-api";
import useAuthentication from "@/composition/useAuthentication";
import { ref, watch } from "@vue/composition-api";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiCheckBox from "@/components/ui/UiCheckBox.vue";
import UiButton from "@/components/ui/UiButton.vue";
import useFetchStaticPage from "@/composition/useFetchStaticPage";
import store from "@/store";
import UiFormattedContent from "@/components/ui/UiFormattedContent/UiFormattedContent.vue";

export default defineComponent({
  name: "AHTosDialog",
  components: { UiFormattedContent, UiButton, UiCheckBox, UiDialog },
  setup() {
    const { logout, profile } = useAuthentication();
    const showPrivacyTerms = ref(false);
    const showTOC = ref(false);
    const contentPrivacyTerms = ref("");
    const contentTOC = ref("");
    const agreed = ref(false);
    const hasError = ref(false);
    const userHasGivenConsent = ref(true);

    watch(agreed, () => {
      if (agreed) hasError.value = false;
    });
    // wait until the profie loaded
    setTimeout(() => {
      // use localStorage: https://v2.vuejs.org/v2/cookbook/client-side-storage.html
      if (localStorage.userHasGivenConsent) {
        userHasGivenConsent.value = localStorage.userHasGivenConsent;
      } else {
        userHasGivenConsent.value = profile.value.firstName
          ? !!profile.value.termsAndConditions &&
            !!profile.value.dataPrivacyStatement
          : true;
        localStorage.userHasGivenConsent = userHasGivenConsent.value;
      }
    }, 1500);

    const getContents = async () => {
      contentPrivacyTerms.value = await useFetchStaticPage("Privacy");
      contentTOC.value = await useFetchStaticPage("AGB");
    };

    const submit = async () => {
      hasError.value = false;
      if (!agreed.value) {
        hasError.value = true;
        return;
      }
      await store.dispatch("auth/checkTOS");
      userHasGivenConsent.value = true;
    };

    return {
      logout,
      showPrivacyTerms,
      showTOC,
      agreed,
      hasError,
      getContents,
      contentTOC,
      contentPrivacyTerms,
      submit,
      userHasGivenConsent
    };
  },
  methods: {
    async showTerms(tos = false) {
      if (!this.contentTOC || !this.contentPrivacyTerms) {
        await this.getContents();
      }

      if (tos) {
        this.showTOC = true;
      } else {
        this.showPrivacyTerms = true;
      }
    },
    hideTerms() {
      this.showTOC = false;
      this.showPrivacyTerms = false;
    }
  }
});
