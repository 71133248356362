/* eslint-disable no-param-reassign, no-console, max-len */
import api from "../../../api";

export default {
  state: {
    /*
     * REVIEW: Module could be consumed more easily if we would flatten
     * the initial configuration object, and have a seperate loading state.
     * e.g.:
     *
     * "stage": "DEVELOPMENT",
     * "pubKey": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1/ODIEyhz5HtsWntuR4nzlZmz2WOQjeSL1AXQ98ZmrXhlQx7U5kpIdy1pU6VH2a41iEQGP2ML8/c5WxSHZwV5/+NqL1tqSxBgUxaoltuuzJo2ii2ILjbf9NR8qq1RlK4aXEF+CFfhjRxkvKECX4sTdaInlthgcbbKIVuY+RT57hds6ShriRi9Kb4OZahYMsMNxzm8ukyryKQje6Kw1cOb73O3Kes4jVOyCk3vxX7B1coHOx/ICdCW3QUd1tazHTImYtd/6tpoLZOdTQicYh2AidKVqD1J4y6t7Zh9ViZICAoaVMdDsEVisMO12eoQo+CpqD2co5qJRlNJAd8uhcEOQIDAQAB\n-----END PUBLIC KEY-----",
     * "ssoURL": "https://staging.dpa-id.de/cas",
     * "dpaIdURL": "https://staging.dpa-id.de/useradmin",
     * "gaPropertyID": "UA-74985641-3",
     * "features": {
     *   "enableIcsDownload": true,
     *   "enableMonthlyViewOptimization": true,
     *   "enableEmailNotificationSettings": true
     * }
     *
     * Leaving it as is for backwards compatibility with the rest of the application.
     */
    config: undefined
  },
  actions: {
    async fetchStageConfig({ commit }) {
      try {
        const config = await api.get({
          url: `config.json?t=${new Date().valueOf()}`
        });
        const isGaEnabled = localStorage.getItem("isGaEnabledSelect");
        if (isGaEnabled === "false") delete config.gaPropertyID;
        commit("setConfig", config);
        return true;
      } catch (e) {
        console.warn(e);
        return false;
      }
    }
  },
  getters: {
    gaPropertyID: ({ config }) =>
      config && config.gaPropertyID ? config.gaPropertyID : undefined
  },
  mutations: {
    setConfig: (state, config) => {
      state.config = config;
    },
    removeGaPropertyId: state => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { gaPropertyID, ...newConfig } = state.config;
      state.config = newConfig;
    }
  },
  namespaced: true
};
