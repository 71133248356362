/*
 * Sets the initial filters for the filter store.
 */

import uuidv3 from "uuid/v3";

const NAMESPACE = "d6158c50-1d6c-11e8-8646-e77e26e51655";
const filters = [
  {
    id: uuidv3("pol", NAMESPACE),
    name: "Politik",
    owner: "dpa",
    filterRules: [
      {
        type: "FILTER_CATEGORY",
        values: ["pol"]
      }
    ],
    tokens: [],
    isVisible: true,
    isPublic: false,
    isRessort: true,
    category: "default"
  },
  {
    id: uuidv3("eco", NAMESPACE),
    name: "Wirtschaft",
    owner: "dpa",
    filterRules: [
      {
        type: "FILTER_CATEGORY",
        values: ["eco"]
      }
    ],
    tokens: [],
    isVisible: true,
    isPublic: false,
    isRessort: true,
    category: "default"
  },
  {
    id: uuidv3("pan", NAMESPACE),
    name: "Panorama",
    owner: "dpa",
    filterRules: [
      {
        type: "FILTER_CATEGORY",
        values: ["pan"]
      }
    ],
    tokens: [],
    isVisible: true,
    isPublic: false,
    isRessort: true,
    category: "default"
  },
  {
    id: uuidv3("sci", NAMESPACE),
    name: "Wissenschaft",
    owner: "dpa",
    tokens: [],
    filterRules: [
      {
        type: "FILTER_CATEGORY",
        values: ["sci"]
      }
    ],
    isVisible: true,
    isPublic: false,
    isRessort: true,
    category: "default"
  },
  {
    id: uuidv3("med", NAMESPACE),
    name: "Medien",
    owner: "dpa",
    filterRules: [
      {
        type: "FILTER_CATEGORY",
        values: ["med"]
      }
    ],
    tokens: [],
    isVisible: true,
    isPublic: false,
    isRessort: true,
    category: "default"
  },
  {
    id: uuidv3("cul", NAMESPACE),
    name: "Kultur",
    owner: "dpa",
    filterRules: [
      {
        type: "FILTER_CATEGORY",
        values: ["cul"]
      }
    ],
    tokens: [],
    isVisible: true,
    isPublic: false,
    isRessort: true,
    category: "default"
  },
  {
    id: uuidv3("spo", NAMESPACE),
    name: "Sport",
    owner: "dpa",
    filterRules: [
      {
        type: "FILTER_CATEGORY",
        values: ["spo"]
      }
    ],
    tokens: [],
    isVisible: true,
    isPublic: false,
    isRessort: true,
    category: "default"
  },
  // WIP-Visual: Non-functional filters
  {
    id: uuidv3("!spo", NAMESPACE),
    name: "Alles außer Sport",
    owner: "dpa",
    filterRules: [
      {
        type: "FILTER_CATEGORY",
        values: ["spo"],
        exclude: "true"
      }
    ],
    tokens: [],
    isVisible: true,
    isPublic: false,
    isRessort: true,
    category: "default"
  },
  {
    id: uuidv3("net", NAMESPACE),
    name: "Netzwelt",
    owner: "dpa",
    filterRules: [
      {
        type: "FILTER_CATEGORY",
        values: ["net"]
      }
    ],
    tokens: [],
    isVisible: true,
    isPublic: false,
    isRessort: false,
    category: "default"
  },
  {
    id: uuidv3("tmn", NAMESPACE),
    name: "Themendienst",
    owner: "dpa",
    filterRules: [
      {
        type: "FILTER_CATEGORY",
        values: ["tmn"]
      }
    ],
    tokens: [],
    isVisible: true,
    isPublic: false,
    isRessort: false,
    category: "default"
  }
];

export default () => filters.map(filter => ({ ...filter }));
