/* eslint-disable no-param-reassign, no-console */

import Vue from "vue";
import { scaffoldMutations } from "undo-redo-vuex";
import initialFilters from "../filters.initialFilters";
import { findIndexById } from "./utils";

export default scaffoldMutations({
  setFilters(state, { filters }) {
    state.filters = filters;
    state.error = "";
  },

  emptyState(state) {
    state.filters = initialFilters();
    state.error = "";
  },

  addFilter(state, { filter }) {
    state.filters = [...state.filters, filter];
  },

  removeFilter(state, { filter }) {
    state.filters = state.filters.filter(item => item.id !== filter.id);
  },

  /**
   * Sets the visibility of a filter
   * @param {String} options.id - The id of an existing filter
   * @param {Boolean} options.isVisible - Weather the filter should be displayed.
   */
  setFilterIsVisible(state, { id, isVisible }) {
    const index = findIndexById(id, state.filters);

    if (index > -1) {
      Vue.set(state.filters, index, {
        ...state.filters[index],
        isVisible
      });
    } else {
      console.warn(`Filter with id ${id} not found.`);
    }
  },

  /**
   * Sets the publish state of a curated search
   * @param {String} options.id - The id of an existing filter
   * @param {Boolean} options.isCuratedSearchActive - Weather the filter should be displayed.
   */
  setCuratedSearchIsActive(state, { id, isCuratedSearchActive }) {
    const index = findIndexById(id, state.filters);

    if (index > -1) {
      Vue.set(state.filters, index, {
        ...state.filters[index],
        isCuratedSearchActive
      });
    } else {
      console.warn(`Filter with id ${id} not found.`);
    }
  },

  /**
   * Sets the visibility of a filter
   * @param {String} options.id - The id of an existing filter
   * @param {Boolean} options.isNotificationEnabled - Weather the filter should be displayed.
   */
  setFilterIsEmailNotificationEnabled(state, { id, isNotificationEnabled }) {
    const index = findIndexById(id, state.filters);

    if (index > -1) {
      Vue.set(state.filters, index, {
        ...state.filters[index],
        isNotificationEnabled
      });
    } else {
      console.warn(`Filter with id ${id} not found.`);
    }
  },

  setFilterIsPushNotificationEnabled(state, { id, isPushEnabled }) {
    const index = findIndexById(id, state.filters);

    if (index > -1) {
      Vue.set(state.filters, index, {
        ...state.filters[index],
        isPushEnabled
      });
    } else {
      console.warn(`Filter with id ${id} not found.`);
    }
  },

  setFilter(state, { index, filter }) {
    Vue.set(state.filters, index, filter);
  },

  updateFilter(state, { index, filter }) {
    Object.keys(filter).forEach(key => {
      Vue.set(state.filters[index], key, filter[key]);
    });
  },

  setIsSaved(state, isSaved) {
    state.isSaved = isSaved;
  },

  setErrorStatus(state, { error }) {
    state.status = "error";
    state.error = error;
  },

  setIsLoadingStatus(state) {
    state.status = "loading";
  },

  setLoadedStatus(state) {
    state.status = "";
  },

  setInitialLoad(state) {
    state.initialLoad = true;
  },

  setShowSaveSearchDialog: (state, showSaveSearchDialog) => {
    state.showSaveSearchDialog = showSaveSearchDialog;
  },
  setShowCuratedSearchDialog: (state, showCuratedSearchDialog) => {
    state.showCuratedSearchDialog = showCuratedSearchDialog;
  },
  setShowCuratedSearchEditDialog: (state, showCuratedSearchEditDialog) => {
    state.showCuratedSearchEditDialog = showCuratedSearchEditDialog;
  }
});
