<template>
  <div
    id="app"
    class="flex flex-col h-full"
    :class="
      getViewportPadding({
        isDesktop,
        isMobile,
        isTablet,
        isLeftNavOpen,
        isSearchOpen,
        isAudioPlayerActive
      })
    "
  >
    <dpa-header
      :token="token"
      :profile-url="profileUrl"
      @menuClick="handleMenuClick"
      @searchClick="handleSearchClick"
    />
    <dpa-navigation :is-open="isLeftNavOpen" @closeClick="handleMenuClick" />
    <main class="w-full h-full min-h-full px-4 xl:px-6 overflow-x-hidden">
      <div class="container mx-auto">
        <router-view />
      </div>
      <a-h-audio-player />
      <a-h-sharing-dialog />
    </main>
    <ui-snackbar :style="{ zIndex: highestPossibleZIndex }" />
    <a-h-tos-dialog />
    <portal-target name="dialog" multiple />
  </div>
</template>

<script>
import qs from "query-string";
import Vue from "vue";
import {
  provide,
  defineComponent,
  computed,
  watch,
  onMounted
} from "@/composition-api";
import { mapActions, mapState } from "vuex";
import store from "@/store";
import AHAudioPlayer from "@/components/AHAudioPlayer/AHAudioPlayer.vue";
import DpaHeader from "@/components/nav/DpaHeader.vue";
import DpaNavigation from "@/components/nav/DpaNavigation.vue";
import UiSnackbar from "@/components/ui/UiSnackbar.vue";
import useAuthentication from "@/composition/useAuthentication";
import useBreakpoints from "@/composition/useBreakpoints";
import useNavigation from "@/composition/useNavigation";
import useWatchAuthentication from "@/composition/useWatchAuthentication";
import AHTosDialog from "@/components/AHTosDialog/AHTosDialog";
import AHSharingDialog from "@/components/AHSharingDialog/AHSharingDialog";
import { isCookieConsentBannerActive } from "./cookieBannerState";
import axios from "axios";

const eventBus = new Vue();

export default defineComponent({
  name: "App",
  components: {
    AHSharingDialog,
    AHTosDialog,
    AHAudioPlayer,
    DpaHeader,
    DpaNavigation,
    UiSnackbar
  },
  provide() {
    return {
      eventBus
    };
  },
  setup(props, { root }) {
    const highestPossibleZIndex = 2147483647;
    const { screen, isMobile, isTablet, isDesktop } = useBreakpoints();
    const { isLeftNavOpen, isSearchOpen } = useNavigation();
    const {
      isAuthenticated,
      validateAndRetrieveToken,
      profile,
      logout
    } = useAuthentication();
    useWatchAuthentication();

    const hanleUnauthorizedRequest = ({ status }) => {
      if (status === 401) {
        logout();
      }
    };

    axios.interceptors.response.use(
      response => {
        hanleUnauthorizedRequest(response);
        return response;
      },
      error => {
        if (error.response) hanleUnauthorizedRequest(error.response);
      }
    );

    provide("eventbus", eventBus);

    const query = qs.parse(window.location.search);

    // If redirected from dpa ID login, read the JTW from the ticket query
    const authToken = validateAndRetrieveToken() || query.ticket;
    const profileUrl = computed(() => store.state.stageconfig.config.dpaIdURL);

    // close search when swichting away from mobile viewport
    watch(isMobile, newVal => {
      if (!newVal) {
        root.$store.commit("navigation/setIsSearchOpen", false);
      }
    });

    // left navigation should be expanded per default on desktop view but not on mobile and tablet
    watch(isDesktop, newVal => {
      if (!newVal) {
        root.$store.commit("navigation/setIsLeftNavOpen", false);
      } else {
        root.$store.commit("navigation/setIsLeftNavOpen", true);
      }
    });

    // show cookie consent banner
    onMounted(() => {
      if (isCookieConsentBannerActive.value) {
        setTimeout(() => {
          window.OneTrust?.ToggleInfoDisplay();
          isCookieConsentBannerActive.value = false;
        }, 3000);
      }
    });

    return {
      eventBus,
      isAuthenticated,
      isDesktop,
      isTablet,
      isMobile,
      isLeftNavOpen,
      isSearchOpen,
      profile,
      profileUrl,
      screen,
      token: authToken,
      highestPossibleZIndex
    };
  },
  computed: {
    ...mapState("stageconfig", ["config"]),
    ...mapState("audioPlayer", {
      isAudioPlayerActive: state => state.isActive
    })
  },
  methods: {
    ...mapActions("stageconfig", ["fetchStageConfig"]),

    handleMenuClick() {
      this.$store.commit("navigation/setIsLeftNavOpen", !this.isLeftNavOpen);
    },

    handleSearchClick() {
      this.$store.commit("navigation/setIsSearchOpen", !this.isSearchOpen);
    },

    /**
     * Returns padding classes for the content viewport. This is the central
     * wrapper and no child elements should need to make assumptions about
     * certain ui features (navigation, search, audio player) beeing visible.
     * @returns {Array<String>} Am array of tailwind padding classes
     */
    // TODO: Add bottom padding based on audio player visible state.
    getViewportPadding({
      isMobile,
      isTablet,
      isDesktop,
      isLeftNavOpen,
      isSearchOpen,
      isAudioPlayerActive
    }) {
      let classes = {};

      if (isMobile) {
        classes = {
          top: isSearchOpen ? "pt-32" : "pt-16",
          bottom: isAudioPlayerActive ? "pb-24" : ""
        };
      }

      if (isTablet) {
        classes = {
          left: "pl-20",
          top: "pt-16",
          bottom: isAudioPlayerActive ? "pb-20" : ""
        };
      }

      if (isDesktop) {
        classes = {
          left: isLeftNavOpen ? "pl-64" : "pl-20",
          top: "pt-16",
          bottom: isAudioPlayerActive ? "pb-20" : ""
        };
      }

      return [Object.values(classes)];
    }
  },
  mounted() {
    // inject and create oneTrust cookie consent snippet
    if (
      store.state.stageconfig.config.features.enableOneTrustCookieConsentBanner
    ) {
      const cookieLawScript = document.createElement("script");
      cookieLawScript.setAttribute("type", "text/javascript");
      cookieLawScript.setAttribute("charset", "UTF-8");
      cookieLawScript.setAttribute(
        "src",
        "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      );
      cookieLawScript.setAttribute("data-document-language", "true");
      cookieLawScript.setAttribute(
        "data-domain-script",
        "2a477549-4ad0-4ede-89dc-e7575142429e"
      );
      document.head.appendChild(cookieLawScript);

      const oneTrustScript = document.createElement("script");
      oneTrustScript.setAttribute("type", "text/javascript");
      oneTrustScript.setAttribute("src", "/oneTrust.js");
      document.head.appendChild(oneTrustScript);
    }
  }
});
</script>

<style>
@import "~@dpa-connect/dpa-id-user/lib/dpa-id-user.css";
@import "~@dpa-id-components/dpa-id-app-switcher/lib/dpa-id-app-switcher.css";

/* OneTrust button is replaced by "Cookies" link */
#ot-sdk-btn-floating {
  display: none !important;
}
</style>
