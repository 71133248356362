<template>
  <div>
    <ui-menu size="medium" :options="options" @optionSelect="handleOptionClick">
      <button
        slot="activator"
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="flex items-center justify-center w-8 h-8 hover:bg-gray-400 focus:bg-gray-400 focus:outline-none rounded-3xl"
      >
        <ui-icon-more class="w-6 h-6 fill-current" />
      </button>
    </ui-menu>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "@/composition-api";
import UiIconMore from "@/assets/vectors/icons/more.svg?inline";
import UiMenu from "@/components/ui/UiMenu";

export default defineComponent({
  components: { UiIconMore, UiMenu },
  props: {
    savedSearch: Object
  },
  setup(props, { emit }) {
    const options = ref([
      {
        label: "Bearbeiten",
        value: "edit"
      },
      {
        label: "Nicht im Menü anzeigen",
        value: "hide"
      },
      {
        label: "Löschen",
        value: "delete"
      },
      {
        label: "Suche teilen",
        value: "share"
      }
    ]);

    const savedSearch = computed(() => props.savedSearch);

    const handleOptionClick = option => {
      emit("option-select", {
        value: option.value,
        savedSearch: savedSearch.value
      });
    };

    return { options, open, close, handleOptionClick };
  }
});
</script>
<style>
.context-menu-options {
  right: -2rem;
}
</style>
