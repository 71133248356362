var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col h-full",class:_vm.getViewportPadding({
      isDesktop: _vm.isDesktop,
      isMobile: _vm.isMobile,
      isTablet: _vm.isTablet,
      isLeftNavOpen: _vm.isLeftNavOpen,
      isSearchOpen: _vm.isSearchOpen,
      isAudioPlayerActive: _vm.isAudioPlayerActive
    }),attrs:{"id":"app"}},[_c('dpa-header',{attrs:{"token":_vm.token,"profile-url":_vm.profileUrl},on:{"menuClick":_vm.handleMenuClick,"searchClick":_vm.handleSearchClick}}),_c('dpa-navigation',{attrs:{"is-open":_vm.isLeftNavOpen},on:{"closeClick":_vm.handleMenuClick}}),_c('main',{staticClass:"w-full h-full min-h-full px-4 xl:px-6 overflow-x-hidden"},[_c('div',{staticClass:"container mx-auto"},[_c('router-view')],1),_c('a-h-audio-player'),_c('a-h-sharing-dialog')],1),_c('ui-snackbar',{style:({ zIndex: _vm.highestPossibleZIndex })}),_c('a-h-tos-dialog'),_c('portal-target',{attrs:{"name":"dialog","multiple":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }