<template>
  <div
    class="fixed bottom-0 left-0 z-50 flex items-center w-full px-4 py-4 transition-transform duration-200 ease-in-out transform bg-white shadow-negative"
    :class="{ 'translate-y-full': !active }"
  >
    <a-h-audio-player-metadata
      :title="metadata.title"
      :author="metadata.author"
      :guid="metadata.guid"
    />
    <a-h-audio-player-speed-control v-model="speed" />
    <button
      class="hidden ml-3 mr-2 sm:block group focus:outline-none"
      @click="rewindOrForward(10, true)"
    >
      <icon-rewind-10
        class="text-gray-600 icon group-hover:text-blue group-focus:text-blue"
      />
    </button>
    <button
      class="flex items-center justify-center flex-shrink-0 w-8 h-8 ml-4 text-white rounded-full sm:ml-2 sm:mr-2 bg-blue hover:bg-blue-dark focus:outline-none focus-visible:outline-blue"
      type="button"
      @click="playOrPause"
    >
      <icon-pause v-if="playing" class="text-white fill-current icon" />
      <icon-spinner
        v-else-if="loading"
        class="text-white fill-current animate-spin icon-small"
      />
      <icon-play v-else class="text-white fill-current icon" />
    </button>
    <button
      class="hidden ml-2 mr-3 sm:block group focus:outline-none"
      @click="rewindOrForward(10, false)"
    >
      <icon-forward-10
        class="text-gray-600 icon group-hover:text-blue group-focus:text-blue"
      />
    </button>
    <a-h-audio-player-progress
      v-if="metadata.waveform"
      :seek="seek"
      :duration="duration"
      :waveform="metadata.waveform"
    />
    <a-h-audio-player-volume-control v-model="volume" class="hidden xxl:flex" />
    <a-h-audio-player-actions
      v-if="metadata.guid && metadata.audioContent"
      :metadata="metadata"
    />
    <ui-tooltip class="mx-2" position="top-right">
      <template v-slot:activator>
        <ui-button-icon
          class="ml-2 text-gray-700 sm:ml-0 hover:text-gray-900 focus:text-gray-900"
          @click.native.prevent="close"
        >
          <ui-icon-close class="w-6 h-6 fill-current" />
        </ui-button-icon>
      </template>
      Player schließen
    </ui-tooltip>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch
} from "@/composition-api";
import { Howl } from "howler";
import axios from "axios";
import store from "@/store";
import IconPlay from "@/assets/vectors/icons/play.svg?inline";
import IconSpinner from "@/assets/vectors/icons/spinner.svg?inline";
import IconPause from "@/assets/vectors/icons/pause.svg?inline";
import IconCheckmark from "@/assets/vectors/icons/checkmark.svg?inline";
import IconRewind10 from "@/assets/vectors/icons/rewind-10.svg?inline";
import IconForward10 from "@/assets/vectors/icons/forward-10.svg?inline";
import { usePlaybackToken } from "@/composition/useAuthorizeToken";
import AHAudioPlayerEventBus from "./AHAudioPlayerEventBus";
import AHAudioPlayerMetadata from "./parts/AHAudioPlayerMetadata.vue";
import AHAudioPlayerSpeedControl from "./parts/AHAudioPlayerSpeedControl.vue";
import AHAudioPlayerVolumeControl from "./parts/AHAudioPlayerVolumeControl.vue";
import AHAudioPlayerProgress from "./parts/AHAudioPlayerProgress.vue";
import AHAudioPlayerActions from "./parts/AHAudioPlayerActions.vue";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";
import UiIconClose from "@/assets/vectors/icons/close.svg?inline";

export default defineComponent({
  name: "AHAudioPlayer",
  components: {
    AHAudioPlayerMetadata,
    AHAudioPlayerSpeedControl,
    AHAudioPlayerVolumeControl,
    AHAudioPlayerProgress,
    AHAudioPlayerActions,
    IconPlay,
    IconSpinner,
    IconPause,
    IconCheckmark,
    IconRewind10,
    IconForward10,
    UiTooltip,
    UiButtonIcon,
    UiIconClose
  },
  setup() {
    // get media endpoint url
    const mediaURL = computed(() => store.state.stageconfig.config.mediaURL);

    // Indicates if the audio player should be visible
    const active = ref(false);

    // Indicates if the audio player should be visible
    const loading = ref(true);

    // Current audio playing state
    const playing = ref(false);

    // Current volume level (from 0.0 to 1.0)
    const volume = ref(1);

    const metadata = ref({});

    // Metadata of the current song
    const title = ref("");
    const author = ref("");
    const audioContent = ref(null);
    const guid = ref("");
    const waveform = ref([]);

    // Current playback time in seconds
    const seek = ref(0);

    // Total audio duration in seconds
    const duration = ref(0);

    // audio speed
    const speed = ref(1);

    // Active howler instance
    let howl = null;

    // Active interval for updating playback time
    let interval = null;

    /**
     * Update function gets executed 4 times per second while the audio is playing.
     */
    const update = () => {
      // update current seek position in seconds.
      seek.value = Math.round(howl.seek() || 0);
      store.commit("audioPlayer/setProgress", {
        progress: (seek.value / duration.value) * 100 || 0,
        seek: seek.value,
        duration: duration.value
      });
    };

    /**
     * Watch the playing state to start or stop the update interval function.
     */
    watch(playing, state => {
      if (state) {
        interval = setInterval(update, 500);
      } else {
        clearInterval(interval);
      }
    });

    /**
     * watch the volume value and update the volume accordingly.
     */
    watch(volume, value => {
      return howl.volume(value);
    });

    /**
     * watch the speed value and update the audio speed accordingly.
     */
    watch(speed, value => {
      return howl.rate(value);
    });

    /**
     * Method for playing or pausing the current audio.
     */
    const playOrPause = () => {
      return howl.playing() ? howl.pause() : howl.play();
    };

    const close = () => {
      howl.stop();
      active.value = false;
      store.commit("audioPlayer/setPlayerStatus", false);
    };

    /**
     * Rewind or forward the audio by the given seconds.
     */
    const rewindOrForward = (seconds, rewind = false) => {
      const curSeek = howl.seek();
      let newSeek = rewind ? curSeek - seconds : curSeek + seconds;

      if (newSeek < 0) newSeek = 0;
      if (newSeek > duration) newSeek = duration;

      howl.seek(Math.round(newSeek));
      seek.value = Math.round(howl.seek() || 0);
    };

    /**
     * set playback progress by given percentage.
     */
    const setProgress = progress => {
      const progressInSeconds = Math.round(duration.value * (progress / 100));
      howl.seek(progressInSeconds);
      seek.value = progressInSeconds;
      store.commit("audioPlayer/setProgress", {
        progress,
        seek: seek.value,
        duration: duration.value
      });
    };

    // get signed S3 URL for the audio item to use in howler html5 audio mode
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getSignedUrl = async ({ residref, authorizeToken }) => {
      try {
        const requstSignedURL = await axios.get(
          `${mediaURL.value}/media-signed-url/${residref}`,
          {
            headers: {
              Authorization: `Bearer ${authorizeToken}`
            }
          }
        );

        return atob(requstSignedURL?.data);
      } catch (error) {
        console.warn(error);

        return "";
      }
    };

    /**
     * Method for initializing the player with the given song.
     */
    const init = async ({ residref, authorizeToken, initialSeek = null }) => {
      // Unload previous instance if it exists.
      if (howl && howl instanceof Howl) {
        loading.value = true;
        howl.unload();
      }

      const src = await getSignedUrl({ residref, authorizeToken });

      // create a new howl instance with the given source.
      howl = new Howl({
        src,
        format: ["mp3"],
        autoplay: true,
        /**
         * html5 mode allows playback to start without waiting
         * for the whole audio file to be downloaded
         */
        html5: true,
        xhr: {
          headers: {
            Accept: "audio/mp3"
          }
        },
        onload: () => {
          if (initialSeek) howl.seek(initialSeek);
          loading.value = false;
          store.commit("audioPlayer/setLoadingStatus", false);
        },
        onplay: () => {
          playing.value = true;
          store.commit("audioPlayer/setPlaybackStatus", true);
          store.commit("audioPlayer/setAudioId", residref);
          store.commit("audioPlayer/setProgress", 0);
          duration.value = Math.round(howl.duration());
          howl.volume(volume.value);
          howl.rate(speed.value);
        },
        onend: () => {
          playing.value = false;
          store.commit("audioPlayer/setPlaybackStatus", false);
        },
        onpause: () => {
          playing.value = false;
          store.commit("audioPlayer/setPlaybackStatus", false);
        },
        onstop: () => {
          playing.value = false;
          store.commit("audioPlayer/setPlaybackStatus", false);
        }
      });
    };

    /**
     * Load event handlers when components is mounted.
     */
    onMounted(() => {
      /**
       * Event handler for "play" event. It expects a payload consisting of the title, author and src for the audio to play.
       */
      AHAudioPlayerEventBus.$on("play", async payload => {
        if (
          active.value &&
          payload.audioContent.residref === metadata.value.audioContent.residref
        ) {
          if (payload.initialSeek) howl.seek(payload.initialSeek);
          howl.play();
        } else {
          active.value = true;
          store.commit("audioPlayer/setPlayerStatus", true);
          loading.value = true;
          store.commit("audioPlayer/setLoadingStatus", true);
          store.commit("audioPlayer/setAudioId", payload.audioContent.residref);
          // REVIEW: consider refactor to object ref to increase DRY
          metadata.value = payload;

          // retrieve authorization token
          const authorizeToken = await usePlaybackToken(
            payload.audioContent.residref
          );

          return init({
            residref: payload.audioContent.residref,
            authorizeToken,
            initialSeek: payload.initialSeek || 0
          });
        }
      });
      /**
       * Event handler for "pause" events.
       */
      AHAudioPlayerEventBus.$on("pause", () => {
        howl.pause();
      });

      AHAudioPlayerEventBus.$on("setProgress", progress => {
        setProgress(progress);
      });

      /**
       * Event handler for "close" event.
       */
      AHAudioPlayerEventBus.$on("close", () => {
        howl.stop();
        active.value = false;
        store.commit("audioPlayer/setPlayerStatus", false);
      });
    });

    return {
      active,
      loading,
      playing,
      volume,
      title,
      author,
      waveform,
      guid,
      audioContent,
      seek,
      duration,
      speed,
      playOrPause,
      close,
      rewindOrForward,
      setProgress,
      metadata
    };
  }
});
</script>
