/* eslint-disable no-param-reassign, no-console */
import deepEqual from "fast-deep-equal";
import windowHelper from "../helpers/window";

const ls = windowHelper().localStorage;
const localVuex =
  ls && ls.getItem("vuex")
    ? JSON.parse(ls.getItem("vuex"))
    : {
        settings: {
          combinedFilters: []
        }
      };
const mockApiRemoveCombinedFilter = ({ filter }) =>
  new Promise(resolve =>
    setTimeout(() => {
      const updatedLocalVuex = { ...localVuex };
      updatedLocalVuex.settings.combinedFilters = updatedLocalVuex.settings.combinedFilters.filter(
        f => !deepEqual(f, filter)
      );
      ls.setItem("vuex", updatedLocalVuex);
      resolve();
    }, 100)
  );

export default {
  state: {
    hiddenFilters: [],
    combinedFilters: [],
    showWelcomeBanner: true,
    showResponsiveAlert: true,
    config: undefined
  },
  getters: {
    getPublicCombinedFilters: ({ combinedFilters }) =>
      combinedFilters.filter(filter => !!filter && filter.isPublic === true),
    getUserCombinedFilters: ({ combinedFilters }) =>
      combinedFilters.filter(filter => !!filter && filter.isPublic === false)
  },
  actions: {
    async removeCombinedFilter({ commit }, { authJWT, filter }) {
      commit("removeCombinedFilter", { filter });
      try {
        await mockApiRemoveCombinedFilter({ authJWT, filter });
      } catch (error) {
        console.warn(error);
      }
    }
  },
  mutations: {
    setHiddenFilters: (state, { filters }) => {
      state.hiddenFilters = filters;
    },
    setCombinedFilters: (state, { filters }) => {
      state.combinedFilters = filters;
    },
    addCombinedFilter: (state, { filter }) => {
      state.combinedFilters = [...state.combinedFilters, filter];
    },
    removeCombinedFilter: (state, { filter }) => {
      state.combinedFilters = state.combinedFilters.filter(
        f => !deepEqual(f, filter)
      );
    },
    setShowWelcomeBanner: (state, value) => {
      state.showWelcomeBanner = value;
    },
    setShowResponsiveAlert: (state, value) => {
      state.showResponsiveAlert = value;
    }
  },
  namespaced: true
};
