


























































































































import { defineComponent, reactive, computed, ref } from "@/composition-api";
import useBreakpoints from "@/composition/useBreakpoints";
import useFetchCuratedSearch from "@/composition/useFetchCuratedSearch";
import store from "@/store";
import {
  Atmo as AtmoIcon,
  Diamond as DiamondIcon,
  Home as HomeIcon,
  Interview as InterviewIcon,
  OTon as OTonIcon,
  Settings as SettingsIcon,
  Survey as SurveyIcon,
  Grid as GridIcon,
  Plus as PlusCircleIcon,
  ListIcon,
  Star as StarIcon
} from "./icons/index";
import {
  actions,
  curatedSearchNavigation,
  playlistNavigation,
  uplaodAudioNavigation
} from "./config/index";
import NavItem from "./NavItem.vue";
import NavItemCollapsed from "./NavItemCollapsed.vue";
import DpaNavigationStatic from "./DpaNavigationStatic.vue";
import { useNavLinkHelpers } from "./navigation.composition";
import UiTransitionOpacity from "../ui/UiTransitionOpacity.vue";
import useGetUserRole from "@/composition/useGetUserRole";
import { isCuratedSearchAndPlaylistsEnabled } from "../../utils/featureFlag";

export default defineComponent({
  components: {
    AtmoIcon,
    DiamondIcon,
    HomeIcon,
    InterviewIcon,
    NavItem,
    NavItemCollapsed,
    OTonIcon,
    SettingsIcon,
    SurveyIcon,
    GridIcon,
    ListIcon,
    StarIcon,
    PlusCircleIcon,
    DpaNavigationStatic,
    UiTransitionOpacity
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const { screen, isMobile, isTablet, isDesktop } = useBreakpoints();

    const {
      navConfig,
      featureTypesConfig,
      onNavClick,
      close
    } = useNavLinkHelpers(emit)();

    const currentPath = computed(() => {
      return store.state.route.path;
    });

    const state = reactive({
      navConfig,
      featureTypesConfig,
      isTablet,
      isDesktop,
      actions: actions.map(action => ({
        ...action,
        isExactLink: currentPath.value?.startsWith(action.to)
      }))
    });

    const curatedState = computed(() => ({
      curatedSearchNavigation: curatedSearchNavigation.map(curatedSearch => ({
        ...curatedSearch,
        isExactLink: currentPath.value?.startsWith(curatedSearch.to)
      }))
    }));
    const playlistState = computed(() => ({
      playlistNavigation: playlistNavigation.map(playlist => ({
        ...playlist,
        isExactLink: currentPath.value?.startsWith(playlist.to)
      }))
    }));
    const uploadState = computed(() => ({
      uplaodAudioNavigation: uplaodAudioNavigation.map(uploadAudio => ({
        ...uploadAudio,
        isExactLink: currentPath.value?.startsWith(uploadAudio.to)
      }))
    }));

    const isCuratedSearch = ref(false);

    const { curatedSearches } = useFetchCuratedSearch();

    if (curatedSearches) {
      isCuratedSearch.value = true;
    }

    const { isAdminOrEditor } = useGetUserRole();

    return {
      curatedSearchNavigation,
      curatedState,
      playlistNavigation,
      playlistState,
      uplaodAudioNavigation,
      uploadState,
      isCuratedSearch,
      state,
      close,
      onNavClick,
      isMobile,
      isTablet,
      isDesktop,
      screen,
      isAdminOrEditor,
      isCuratedSearchAndPlaylistsEnabled
    };
  }
});
