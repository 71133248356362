/* eslint-disable no-shadow */

import { findById } from "./utils";
const getPlaylistById = ({ playlists }) => id => findById(id, playlists);
const getPublicPlaylists = ({ playlists }) =>
  playlists.filter(({ isPlaylistActive }) => isPlaylistActive === true);

const getPlaylists = ({ playlists }) => playlists;
const hasPlaylists = ({ playlists }) => playlists.length > 0;
const getShowCreatePlaylistDialog = ({ showCreatePlaylistDialog }) =>
  showCreatePlaylistDialog;

export default {
  getPublicPlaylists,
  getPlaylistById,
  hasPlaylists,
  getPlaylists,
  getShowCreatePlaylistDialog
};
