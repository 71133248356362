import { AudioItemsState } from "./types";

export const state: AudioItemsState = {
  activeAudioItem: null,
  audioitems: [],
  isLoading: true,
  totalResults: 0,
  searchContinuationFields: null,
  isDetailLoading: false,
  fragmentSize: 200
};
