<template>
  <ui-menu
    slot="actions"
    actions-menu
    max-height-none="true"
    :bubble-menu="true"
    :bubble-menu-chevron-left="false"
    :bubble-menu-chevron-right="true"
    :class="isMobile ? 'ml-5' : 'ml-7'"
    style="max-width: 32px;"
    @open="isClicked = true"
    @close="isClicked = false"
  >
    <template v-slot:activator>
      <ui-icon-button
        :class="`${isClicked ? 'pressed-button' : ''}`"
        :size-classes="`w-8 h-8`"
      >
        <ui-icon
          :class="`w-5 h-5 ${isClicked ? 'pressed-icon' : ''}`"
          size="medium"
          name="help"
        />
      </ui-icon-button>
    </template>
    <AHHelpMenu slot="menu" :links-config="linksConfig"></AHHelpMenu>
  </ui-menu>
</template>

<script>
import AHHelpMenu from "./AHHelpMenu";
import useHelpLinks from "./useHelpLinks";
import UiIcon from "@dpa-id-components/ui-icon";
import UiIconButton from "@dpa-id-components/ui-icon-button";
import UiMenu from "@dpa-id-components/ui-menu";
import "@dpa-id-components/ui-menu/dist/UiMenu.css";

export default {
  components: {
    AHHelpMenu,
    UiIconButton,
    UiMenu,
    UiIcon
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const linksConfig = useHelpLinks();
    return {
      linksConfig
    };
  }
};
</script>

<style scoped>
.pressed-button {
  background-color: #003edb !important;
}

.pressed-icon {
  color: white;
}
</style>
