import axios from "axios";
import { getBrowserLang } from "../utils/language";

export default {
  state: {
    language: null
  },
  actions: {
    async fetchLanguage({ commit, dispatch }, token) {
      const config = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      };
      const browserLang = getBrowserLang();
      try {
        const { data } = await axios.get("/useradmin/preferences", config);
        if (data.preferedLanguage) {
          commit("setLanguage", data.preferedLanguage);
        } else {
          dispatch("setLanguage", { language: browserLang, token });
        }
      } catch (e) {
        commit("setLanguage", browserLang);
        console.warn(e);
      }
    },

    async setLanguage({ commit }, { language, token }) {
      commit("setLanguage", language);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      };
      const data = {
        preferedLanguage: language
      };
      try {
        await axios.put("/useradmin/preferences", data, config);
      } catch (e) {
        console.error(e);
      }
    }
  },

  mutations: {
    setLanguage(state, language) {
      state.language = language;
    }
  },

  namespaced: true
};
