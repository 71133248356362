import * as Sentry from "@sentry/vue";
import useAPI from "@dpa-connect/dpa-id-user/store/utils/api";
import API_SETTINGS from "@/constants/apiSettings";

export const setSentryTransactionId = () => {
  const transactionID = Math.random()
    .toString(36)
    .substring(2, 9);

  Sentry.configureScope(scope => {
    scope.setTag("transaction_id", transactionID);
  });

  return transactionID;
};

export default useAPI(API_SETTINGS);
