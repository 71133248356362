// Define RegEx patterns
const orSeparator = /\|\|/;
export const outerQuotes = /(^")|("$)/g;
const individualWordOrQuotation = /(?=\S*['-]?)(['-\wäöüÄÖÜß]+)|"(.*?)"/g;
const startsWithMinus = /^-\w+$/;
const isNotOperator = /^(NOT|NICHT|not|nicht|-)$/;
export const andInputPattern = "(\\s*(AND|and|UND|und)|,\\s*)?";
const notInputPattern = "(NOT|not|NICHT|nicht|-)\\s*";
const orInputPattern = "\\s+(ODER|oder|OR|or)\\s+";

export const getOrTokens = (token: string) =>
  // "||" separators, strip outer '"' quotes
  token
    ?.split(orSeparator)
    ?.map(splitToken => splitToken.trim().replaceAll(outerQuotes, ""));

export const getSearchWordsOrPhrases = (input: string) => [
  // All parameterised search strings: words with hyphens and apostrophes
  ...input.matchAll(individualWordOrQuotation)
];

export const startsWithMinusToken = (input: string) => {
  if (input.match(startsWithMinus)) {
    return {
      fullMatch: input,
      operator: "-",
      token: input.replace("-", "")
    };
  }

  return {};
};

export const getStartsWithMinusTokens = (searchTokens: any[][]) => {
  return searchTokens
    .map(([fullMatch]) => startsWithMinusToken(fullMatch))
    .filter(({ token }) => token);
};

export const getNotTokens = (searchTokens: any[][]) => {
  if (!searchTokens.length) {
    return [];
  }

  return searchTokens.reduce((acc, tokenMatch, index) => {
    const [fullMatch, operator] = tokenMatch || [];
    if (operator && operator.match(isNotOperator)) {
      /**
       * The exact token is either at index 1 or 2 of the next item in searchTokens
       * 1: token is a single word
       * 2: token are words captured within quotes
       */
      const nextSearchToken = searchTokens[index + 1] || [];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [match, firstGroup, secondGroup] = nextSearchToken;

      const token = firstGroup || secondGroup;
      const notToken =
        token !== "-"
          ? {
              fullMatch,
              operator,
              token
            }
          : undefined;
      return notToken ? [...acc, notToken] : acc;
    }

    return acc;
  }, []);
};

export const stripTokenOuterQuotes = ([
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fullMatch,
  firstGroup,
  secondGroup
]: string[]) => {
  const token = firstGroup || secondGroup;
  return token.replaceAll(outerQuotes, "");
};

export const notAndOrExcludesOperator = (token: string) =>
  !token.match(/^(UND|und|AND|and|NICHT|nicht|NOT|not)$/);

export const getCombinedOrTokens = (tokens: string[]) =>
  tokens
    .join("&nbsp;+")
    .replaceAll(/&nbsp;\+(ODER|oder|OR|or)&nbsp;\+/g, "||")
    .split("&nbsp;+");

export const getTokenIfNotExcluded = (excludedTokens: any[]) => (
  token: string
) =>
  // remove tokens which are excluded
  !excludedTokens.some(
    excludedToken =>
      token === excludedToken.token || token === excludedToken.fullMatch
  );

export const getFullTextFilterRules = (payload: {
  tokens: string[];
  exclude?: boolean;
  fields?: string[];
}) =>
  payload.tokens
    .map(token => ({
      type: "FILTER_FULLTEXT_FIELDS",
      values: getOrTokens(token),
      ...(payload.exclude ? { exclude: true } : {}),
      ...(payload.fields?.length ? { fields: payload.fields } : {})
    }))
    .filter(({ values }) => values?.[0]);

export const getOrderedTokens = (input: string) => {
  const tokens = getSearchWordsOrPhrases(input)
    .map(stripTokenOuterQuotes)
    .filter(notAndOrExcludesOperator);

  return getCombinedOrTokens(tokens);
};

export const getOrderedFullTextFilterRules = (payload: {
  orderedTokens: string[];
  filterRules: FilterRules.FilterRule[];
}) => {
  const { orderedTokens, filterRules } = payload;
  const ordered = orderedTokens.reduce((acc, token, index) => {
    // Find filterRule which matches the current token
    const filterRule = filterRules.find(({ values }) => {
      const joinedOrValues = values.join("||");
      const formattedToken = token.replace(/^-/, "").replaceAll("||", "\\|\\|");
      const tokenPattern = RegExp(String.raw`^-?${formattedToken}$`);

      return joinedOrValues.match(tokenPattern);
    });

    // Set the filterRule to its ordered index
    if (filterRule) {
      acc[index] = filterRule;
    }
    return acc;
  }, []);

  return ordered.filter(filterRule => filterRule);
};

export const getFilterRuleInputPattern = (
  filterRule: FilterRules.FilterRule
): RegExp => {
  const { values, exclude } = filterRule;
  const isOrFullTextRule = values.length > 1;

  const singleToken = !isOrFullTextRule && String(values[0]);
  const orTokens =
    isOrFullTextRule &&
    values.map(token => `(${token}|"${token}")`).join(orInputPattern);

  if (exclude && singleToken) {
    return RegExp(
      String.raw`${notInputPattern}(${singleToken}|"${singleToken}")${andInputPattern}|-(${singleToken}|"${singleToken}")${andInputPattern}`,
      "g"
    );
  }

  if (isOrFullTextRule) {
    return RegExp(String.raw`${orTokens}${andInputPattern}`, "g");
  }

  return RegExp(
    String.raw`(${singleToken}|"${singleToken}")${andInputPattern}`,
    "g"
  );
};
