import debounce from "lodash.debounce";
import { ref, computed } from "@/composition-api";
import store from "@/store";

export const showBulkSelectionMenu = ref(false);
export const selectedAudioItems = ref([]);

const storeAudioItems = computed(() => store.state.audioitems.audioitems);

export const allChecked = ref(false);

export const toggleItemSelection = audioitem => {
  const audioitemIndex = selectedAudioItems.value.findIndex(
    ({ guid }) => guid === audioitem.guid
  );

  if (audioitemIndex < 0) {
    selectedAudioItems.value.push(audioitem);
  } else {
    selectedAudioItems.value.splice(audioitemIndex, 1);
  }

  if (selectedAudioItems.value.length === storeAudioItems.value.length) {
    allChecked.value = true;
  } else {
    allChecked.value = false;
  }
};

export const toggleAll = debounce(() => {
  if (selectedAudioItems.value.length === storeAudioItems.value.length) {
    selectedAudioItems.value = [];
  } else {
    selectedAudioItems.value = [...storeAudioItems.value];
  }
});

export const clearBulkSelection = debounce(() => {
  showBulkSelectionMenu.value = false;
  selectedAudioItems.value = [];
  allChecked.value = false;
});

export const isAudioitemSelected = audioitem =>
  !!selectedAudioItems.value.find(({ guid }) => guid === audioitem.guid);
