









import { defineComponent, ref } from "@/composition-api";
import UiProgressBar from "@/components/ui/UiProgressBar.vue";

export default defineComponent({
  components: {
    UiProgressBar
  },
  props: {
    used: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  computed: {
    BarWidth() {
      const percentage = ref();
      percentage.value = (this.used / this.total) * 100;
      return percentage.value;
    }
  }
});
