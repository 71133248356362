import { computed, ComputedRef, ref } from "@/composition-api";
import axios from "axios";
import FileSaver from "file-saver";
import store from "@/store";
import convertAudioTitle from "@/utils/convertAudioTitle";
import { useDownloadToken } from "@/composition/useAuthorizeToken";
import { getAudioitemLink } from "@/components/AHAudioitem/shared/utils";
import AHSharingDialogEventBus from "@/components/AHSharingDialog/AHSharingDialogEventBus";
import state from "./useShareSearchState";
import { clearBulkSelection } from "./useBulkSelection";

export default ({
  guid,
  audioContent,
  headline
}: {
  guid: ComputedRef<string>;
  audioContent: ComputedRef<ViewModel.AudioItem["audioContent"]>;
  headline?: ComputedRef<string>;
}) => {
  const downloadIsLoading = ref(false);

  function share() {
    const baseURL = computed(() => store.state.stageconfig.config?.baseURL);
    const audioitemLink = computed(() =>
      getAudioitemLink(baseURL.value, guid.value)
    );

    state.isSharedSearch = false;
    state.header = "Link Kopieren";
    AHSharingDialogEventBus.$emit("show", {
      title: headline.value,
      url: audioitemLink.value,
      audioId: audioContent.value.residref
    });
  }

  /**
   * TODO: handle the following states: loading, quota, null token response
   */
  const downloadAudio = async () => {
    downloadIsLoading.value = true;

    const mediaURL = computed(() => store.state.stageconfig.config.mediaURL);
    const residref = computed(() => audioContent.value.residref);

    console.assert(typeof mediaURL.value === "string");
    console.assert(typeof residref.value === "string");

    const downloadAuthorizationToken = await useDownloadToken(residref.value);

    if (downloadAuthorizationToken !== null) {
      const requestSignedURL = await axios.get(
        `${mediaURL.value}/media-signed-url/${residref.value}`,
        {
          headers: {
            Authorization: `Bearer ${downloadAuthorizationToken}`
          }
        }
      );

      const res = await axios.get(atob(requestSignedURL?.data), {
        headers: {
          Accept: "audio/mp3"
        },
        responseType: "blob"
      });

      downloadIsLoading.value = false;
      FileSaver.saveAs(
        new Blob([res.data]),
        `${convertAudioTitle(headline.value)}.mp3`
      );
    }
  };

  const showDownloadPendingOverlay = ref(false);

  const downloadBulkAudio = async () => {
    downloadIsLoading.value = true;
    if (downloadIsLoading.value) {
      showDownloadPendingOverlay.value = true;
    }

    const mediaURL = "/audioitems/export/api/v1/mp3";
    const payload = { audioGuids: guid.value };

    const downloadAuthorizationToken = await useDownloadToken(guid.value);

    if (downloadAuthorizationToken !== null) {
      await axios
        .post(mediaURL, payload, {
          headers: {
            Accept: "application/octet-stream",
            Authorization: `Bearer ${downloadAuthorizationToken}`
          },
          responseType: "blob"
        })
        .then(function(res) {
          const blob = new Blob([res.data], {
            type: "application/zip"
          });
          FileSaver.saveAs(blob, "audios.zip");

          clearBulkSelection();
        })
        .catch(function(error) {
          console.error(error);
          throw error;
        });
    }
  };

  return {
    share,
    downloadAudio,
    downloadBulkAudio,
    downloadIsLoading,
    showDownloadPendingOverlay
  };
};
