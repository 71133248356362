var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ui-transition-opacity',[(_vm.isOpen && !_vm.state.isDesktop)?_c('div',{staticClass:"fixed inset-0 z-40",on:{"click":_vm.close}},[_c('div',{staticClass:"absolute inset-0 bg-black opacity-50"})]):_vm._e()]),(_vm.state.isTablet || (_vm.state.isDesktop && !_vm.isOpen))?_c('div',{staticClass:"fixed left-0 w-20 h-full px-2 py-2 text-xs bg-white top-16"},[_vm._l((_vm.state.navConfig),function(ref,index){
var label = ref.label;
var icon = ref.icon;
var query = ref.query;
var isExactLink = ref.isExactLink;
var path = ref.path;
return _c('nav-item-collapsed',{key:index,attrs:{"icon":icon,"is-exact-link":isExactLink,"label":label,"to":{ path: path, query: query }},on:{"click":function($event){return _vm.onNavClick({ query: query, isExactLink: isExactLink })}}})}),(_vm.isCuratedSearchAndPlaylistsEnabled && _vm.isCuratedSearch)?_vm._l((_vm.curatedState.curatedSearchNavigation),function(ref,index){
var label = ref.label;
var icon = ref.icon;
var to = ref.to;
var isExactLink = ref.isExactLink;
return _c('nav-item-collapsed',{key:index,attrs:{"icon":icon,"label":label,"to":to,"is-exact-link":isExactLink},on:{"click":function($event){return _vm.onNavClick({ query: {}, isExactLink: isExactLink })}}})}):_vm._e(),(_vm.isCuratedSearchAndPlaylistsEnabled)?_vm._l((_vm.playlistState.playlistNavigation),function(ref,index){
var label = ref.label;
var icon = ref.icon;
var to = ref.to;
var isExactLink = ref.isExactLink;
return _c('nav-item-collapsed',{key:index,attrs:{"icon":icon,"label":label,"to":to,"is-exact-link":isExactLink},on:{"click":function($event){return _vm.onNavClick({ query: {}, isExactLink: isExactLink, trackEventType: 'playlist' })}}})}):_vm._e(),_vm._l((_vm.state.featureTypesConfig),function(ref,index){
var label = ref.label;
var icon = ref.icon;
var query = ref.query;
var isExactLink = ref.isExactLink;
var path = ref.path;
return _c('nav-item-collapsed',{key:index,attrs:{"icon":icon,"is-exact-link":isExactLink,"label":label,"to":{ path: path, query: query }},on:{"click":function($event){return _vm.onNavClick({ query: query, isExactLink: isExactLink })}}})}),(_vm.isAdminOrEditor && !_vm.isMobile)?_vm._l((_vm.uploadState.uplaodAudioNavigation),function(ref,index){
var label = ref.label;
var icon = ref.icon;
var to = ref.to;
var isExactLink = ref.isExactLink;
return _c('nav-item-collapsed',{key:index,attrs:{"icon":icon,"label":label,"to":to,"is-exact-link":isExactLink},on:{"click":function($event){return _vm.onNavClick({ query: {}, isExactLink: isExactLink })}}})}):_vm._e(),_vm._l((_vm.state.actions),function(ref,index){
var label = ref.label;
var icon = ref.icon;
var to = ref.to;
var isExactLink = ref.isExactLink;
return _c('nav-item-collapsed',{key:index,attrs:{"icon":icon,"label":label,"to":to,"is-exact-link":isExactLink},on:{"click":function($event){return _vm.onNavClick({ query: {}, isExactLink: isExactLink })}}})})],2):_vm._e(),_c('transition',{attrs:{"enter-class":"-translate-x-full","enter-to-class":"translate-x-0","enter-active-class":"transition duration-300 ease-in-out transform","leave-class":"translate-x-0","leave-active-class":"transition duration-300 ease-in-out transform","leave-to-class":"-translate-x-full"}},[(_vm.isOpen && !_vm.state.isDesktop)?_c('dpa-navigation-static',{attrs:{"is-open":_vm.isOpen},on:{"closeClick":_vm.close}}):_vm._e()],1),(_vm.isOpen && _vm.state.isDesktop)?_c('dpa-navigation-static',{attrs:{"is-open":_vm.isOpen},on:{"closeClick":_vm.close}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }