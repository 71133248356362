























































import { computed, defineComponent, inject } from "@/composition-api";
import useSharingOptions from "@/composition/useSharingOptions";
import IconDownload from "@/assets/vectors/icons/download.svg?inline";
import IconShare from "@/assets/vectors/icons/link-2-outline.svg?inline";
import IconSpinner from "@/assets/vectors/icons/spinner.svg?inline";
import AHAudioDownload from "@/components/AHAudioDownload/AHAudioDownload.vue";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import useAudioItemOptions from "@/composition/useAudioItemOptions";
import useAuthentication from "@/composition/useAuthentication";
import UiButton from "@/components/ui/UiButton.vue";
import UiIcon from "@dpa-id-components/ui-icon";
import store from "@/store";

export default defineComponent({
  components: {
    UiTooltip,
    UiButtonIcon,
    AHAudioDownload,
    IconDownload,
    IconShare,
    UiButton,
    UiIcon,
    IconSpinner
  },
  props: {
    metadata: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const eventBus: Vue = inject("eventBus");
    const guid = computed(() => props.metadata.guid);
    const audioContent = computed(() => props.metadata.audioContent);
    const headline = computed(() => props.metadata.title);
    const { share } = useSharingOptions({
      guid,
      audioContent,
      headline
    });
    const isFavorite = computed(() => props.metadata.isFavorite);
    const showFavoriteOption = computed(
      () => store.state.stageconfig.config.features.enableFavorites
    );

    const favoriteTooltip = computed(() => {
      return isFavorite.value
        ? "Als Favorit entfernen"
        : "Zu Favoriten hinzufügen";
    });
    const { toggleFavorites } = useAudioItemOptions({ guid, headline });

    const toggleFavorite = () => {
      const { validateAndRetrieveToken } = useAuthentication();

      toggleFavorites(validateAndRetrieveToken(), props.metadata);
      eventBus.$emit("show", {
        title: `Als Favorit ${
          !props.metadata.isFavorite ? "hinzugefügt" : "entfernt"
        }`,
        icon: "checkmark"
      });
    };
    const starSwitch = computed(() => {
      return isFavorite.value ? "starFilled" : "star";
    });
    return {
      share,
      favoriteTooltip,
      toggleFavorite,
      isFavorite,
      starSwitch,
      guid,
      audioContent,
      headline,
      showFavoriteOption
    };
  }
});
