import { scaffoldState } from "undo-redo-vuex";
import { FilterState } from "./types";

const state: FilterState = {
  error: null,
  filters: [],
  initialLoad: false,
  isSaved: false,
  showSaveSearchDialog: false,
  showCuratedSearchDialog: false,
  showCuratedSearchEditDialog: false,
  status: ""
};

export default scaffoldState(state);
