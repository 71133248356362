























import { defineComponent } from "@/composition-api";
import AHWaveform from "@/components/AHWaveform/AHWaveform.vue";
import AHAudioPlayerEventBus from "@/components/AHAudioPlayer/AHAudioPlayerEventBus";
import useBreakpoints from "@/composition/useBreakpoints";

export default defineComponent({
  name: "AHAudioPlayerProgress",
  props: {
    seek: {
      type: Number,
      required: true
    },
    duration: {
      type: Number,
      required: true
    },
    waveform: {
      type: Array,
      required: true
    }
  },
  components: {
    AHWaveform
  },
  setup() {
    const breakpoint = useBreakpoints();

    function formatAudioTime(secs: number) {
      const minutes = Math.floor(secs / 60) || 0;
      const seconds = secs - minutes * 60 || 0;

      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }

    function handleProgressChange(e): void {
      AHAudioPlayerEventBus.$emit("setProgress", e);
    }

    return {
      formatAudioTime,
      handleProgressChange,
      screen: breakpoint.screen
    };
  }
});
