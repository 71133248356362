import { appBannerState } from "./useAppBanner";

// Banner content fragments
import NoDownloadPermissionsBanner from "@/components/fragments/NoDownloadPermissionsBanner.vue";
import QuotaReachedBanner from "@/components/fragments/QuotaReachedBanner.vue";
import QuotaExpiredBanner from "@/components/fragments/QuotaExpiredBanner.vue";
import SubscriptionExpiredBanner from "@/components/fragments/SubscriptionExpiredBanner.vue";

// String patterns
const PATTERN_TEST_USER = /^Test$/;
const PATTERN_SUBSCRIPTION_USER = /^Abo$/;

/**
 * EC-3873: "UNDETERMINED" indicates scenario other than a deactivated trial
 * or expired subscription: check available quota if the quota is exhausted,
 * AND whether the download quota is "unlimited"
 */

const PATTERN_UNDETERMINED = /^UNDETERMINED$/;
const PATTERN_DEACTIVATED = /^TRIAL_DEACTIVATED$/;
const PATTERN_QUOTA_NOT_ACTIVE = /^CUSTOMER_QUOTA_NOT_ACTIVE$/;
const PATTERN_QUOTA_EXPIRED = /^QUOTA_EXPIRED$/;
const PATTERN_SUBSCRIPTION_EXPIRED = /^SUBSCRIPTION_EXPIRED$/;

export default () => {
  const isTrialDeactivated = ({ userQuotaStanding, accessStatus }) =>
    accessStatus.match(PATTERN_TEST_USER) &&
    userQuotaStanding.match(PATTERN_DEACTIVATED);

  const isSubscriptionOver = ({ userQuotaStanding, accessStatus }) =>
    accessStatus.match(PATTERN_SUBSCRIPTION_USER) &&
    userQuotaStanding.match(PATTERN_QUOTA_NOT_ACTIVE);

  const isSubscriptionExpired = ({ userQuotaStanding, accessStatus }) =>
    accessStatus.match(PATTERN_SUBSCRIPTION_USER) &&
    userQuotaStanding.match(PATTERN_SUBSCRIPTION_EXPIRED);

  const isQuotaReached = ({ userQuotaStanding, quotaAvailable, unlimited }) =>
    userQuotaStanding.match(PATTERN_UNDETERMINED) &&
    // Might be a negative number
    quotaAvailable < 1 &&
    !unlimited;

  const isQuotaExpired = ({ userQuotaStanding }) => {
    return userQuotaStanding.match(PATTERN_QUOTA_EXPIRED);
  };

  // Toggle banner logic
  const getBannerContent = ({
    userQuotaStanding,
    accessStatus,
    quotaAvailable,
    unlimited
  }) => {
    if (
      !accessStatus ||
      isTrialDeactivated({ userQuotaStanding, accessStatus }) ||
      isSubscriptionOver({ userQuotaStanding, accessStatus })
    ) {
      return NoDownloadPermissionsBanner;
    }

    if (isQuotaReached({ userQuotaStanding, quotaAvailable, unlimited })) {
      return QuotaReachedBanner;
    }

    if (isQuotaExpired({ userQuotaStanding })) {
      return QuotaExpiredBanner;
    }

    if (isSubscriptionExpired({ userQuotaStanding, accessStatus })) {
      return SubscriptionExpiredBanner;
    }
  };

  const toggleDownloadBanner = ({
    userQuotaStanding,
    accessStatus,
    quotaAvailable,
    unlimited
  }) => {
    appBannerState.content = getBannerContent({
      userQuotaStanding,
      accessStatus,
      quotaAvailable,
      unlimited
    });
    appBannerState.isVisible = !!appBannerState.content;
    appBannerState.bgColor = appBannerState.isVisible ? "bg-orange" : "";
  };

  return { toggleDownloadBanner };
};
