<template>
  <div class="items-center mx-3 group focus:outline-none">
    <button
      class="mr-3 focus:outline-none focus-visible:outline-blue"
      @click="toggleVolume"
    >
      <icon-volume-off
        v-if="value === 0"
        class="text-gray-700 icon group-hover:text-blue group-focus:text-blue"
      />
      <icon-volume-half
        v-else-if="value <= 0.5"
        class="text-gray-700 icon group-hover:text-blue group-focus:text-blue"
      />
      <icon-volume-full
        v-else
        class="text-gray-700 icon group-hover:text-blue group-focus:text-blue"
      />
    </button>
    <div class="relative h-1 bg-gray-300 rounded-full" style="width: 158px;">
      <input
        type="range"
        min="0"
        max="100"
        :value="value * 100"
        class="a-h-audio-player-volume-control"
        @input="handleVolumeSlider"
      />
      <div
        class="h-1 transition-colors duration-200 ease-in-out bg-gray-700 rounded-full"
        :style="{ width: `${value * 100}%` }"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@/composition-api";
import IconVolumeOff from "@/assets/vectors/icons/volume-off.svg?inline";
import IconVolumeHalf from "@/assets/vectors/icons/volume-half.svg?inline";
import IconVolumeFull from "@/assets/vectors/icons/volume-full.svg?inline";

export default defineComponent({
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  components: {
    IconVolumeOff,
    IconVolumeHalf,
    IconVolumeFull
  },
  methods: {
    handleVolumeSlider(e) {
      this.$emit("input", e.target.value / 100);
    },
    toggleVolume() {
      this.$emit("input", this.value === 0 ? 1 : 0);
    }
  }
});
</script>

<style lang="postcss" scoped>
.a-h-audio-player-volume-control {
  @apply absolute top-0 left-0 w-full h-1 bg-transparent appearance-none opacity-0 transition-opacity duration-200 ease-in-out;
}

.a-h-audio-player-volume-control:hover,
.a-h-audio-player-volume-control:focus {
  @apply opacity-100 outline-none;
}

.a-h-audio-player-volume-control:hover::-webkit-slider-thumb,
.a-h-audio-player-volume-control:focus::-webkit-slider-thumb {
  @apply scale-100;
}

.a-h-audio-player-volume-control:hover + div,
.a-h-audio-player-volume-control:focus + div {
  @apply bg-blue;
}

.a-h-audio-player-volume-control::-webkit-slider-thumb {
  @apply appearance-none h-4 w-4 bg-blue rounded-full transform scale-0 origin-center transition-transform duration-200 ease-out shadow-md;
}

.a-h-audio-player-volume-control::-webkit-slider-thumb:hover {
  @apply scale-100;
}
</style>
