import { FilterRulesState } from "./types";

export default {
  fullTextFilterRules: (state: FilterRulesState) =>
    state.filterRules.filter(({ type }) => type === "FILTER_FULLTEXT_FIELDS"),

  fullTextFilterRulesExcludingSpeakers: (state: FilterRulesState) =>
    state.filterRules.filter(
      ({ type, fields }) =>
        type === "FILTER_FULLTEXT_FIELDS" &&
        !(fields?.length === 1 && fields?.[0] === "FIELD_PERSON")
    ),

  speakerFilterRules: (state: FilterRulesState, getters) => {
    const { fullTextFilterRules } = getters;

    const speakerFilterRules = fullTextFilterRules?.filter(
      ({ fields }) => fields?.length === 1 && fields?.[0] === "FIELD_PERSON"
    );

    return speakerFilterRules;
  },

  fullTextFilteredFields: (state: FilterRulesState, getters) => {
    const { fullTextFilterRulesExcludingSpeakers } = getters;

    return fullTextFilterRulesExcludingSpeakers.find(
      (filterRule: FilterRules.FilterRule) => filterRule.fields?.length
    )?.fields;
  },

  hasDateRangeFilter: (state: FilterRulesState) =>
    !!state.filterRules.find(({ type }) => type === "FILTER_CONTENT_CREATED"),

  numberOfSearchCriteria: (state: FilterRulesState, getters) => {
    const speakerFilterRules = getters?.["speakerFilterRules"];
    const hasSpeakerFilterRules = !!speakerFilterRules.length;

    /* Search Fields Advanced Filter */
    const fullTextFilterRules = getters.fullTextFilterRulesExcludingSpeakers;
    const hasFilteredFullTextFields = fullTextFilterRules?.some(
      ({ fields }) => !!fields
    );

    /* Audio Type Advanced Filter */
    const hasAudioServiceFilterRule = !!state.filterRules.find(
      ({ type }) => type === "FILTER_SERVICE"
    );

    const { hasDateRangeFilter } = getters;

    const hasDurationFilterRule = state.filterRules.find(
      ({ type }) => type === "FILTER_DURATION"
    );

    // TODO: FOR SAVED SEARCHES
    // Only include advanced search criteria for saved searches
    // which are additionally applied from what is saved
    const appliedCriteria = [
      hasAudioServiceFilterRule,
      hasSpeakerFilterRules,
      hasFilteredFullTextFields,
      hasDateRangeFilter,
      hasDurationFilterRule
    ].filter(isApplied => isApplied);

    return appliedCriteria.length;
  }
};
