import { Module } from "vuex";
import { state } from "./state";
import { mutations } from "./mutations";
import { RootState } from "../../types";
import { AudioPlayerState } from "./types";

const namespaced = true;

const audioItemsStore: Module<AudioPlayerState, RootState> = {
  namespaced,
  state,
  mutations
};

export default audioItemsStore;
