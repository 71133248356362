import { computed } from "@/composition-api";
import { useAdvancedFullTextFilters } from "@/components/AHSearch/AdvancedFilters/advancedFullTextFilters.utils";
import store from "@/store";

export default () => {
  const MIN_VALUE = 0;
  const MAX_VALUE = 1800;

  const { removeOrUpdateRules } = useAdvancedFullTextFilters();

  const durationFilterRule = computed(() =>
    store.state.filterRules.filterRules.find(
      ({ type }) => type === "FILTER_DURATION"
    )
  );

  const durationRange = computed({
    get: () => {
      let from = MIN_VALUE;
      let to = MAX_VALUE;
      if (durationFilterRule.value) {
        [from, to] = durationFilterRule.value.values;
      }

      return { from, to };
    },
    set: ({ from, to }) => {
      const hasDefaultValues = from === MIN_VALUE && to === MAX_VALUE;

      removeOrUpdateRules({
        removeCondition:
          hasDefaultValues && durationFilterRule.value !== undefined,
        currentFilterRule: durationFilterRule.value,
        updatedFilterRule: {
          type: "FILTER_DURATION",
          values: [from, to]
        }
      });
    }
  });

  const durationRangeInMinutes = computed(() => ({
    from: Math.round(Number(durationRange.value.from) / 60),
    to: Math.round(Number(durationRange.value.to) / 60)
  }));

  const displayLabelOfSelectedRange = computed(() => {
    if (
      Number(durationRangeInMinutes.value.from) === 0 &&
      Number(durationRangeInMinutes.value.to) === 30
    ) {
      return `keine Einschränkungen`;
    }
    if (
      Number(durationRangeInMinutes.value.from) !== 1 &&
      Number(durationRangeInMinutes.value.to) === 30
    ) {
      return `ab ${durationRangeInMinutes.value.from} Minuten`;
    }
    if (
      Number(durationRangeInMinutes.value.from) === 1 &&
      Number(durationRangeInMinutes.value.to) === 30
    ) {
      return `ab ${durationRangeInMinutes.value.from} Minute`;
    }
    if (
      Number(durationRangeInMinutes.value.from) === 0 &&
      Number(durationRangeInMinutes.value.to) === 1
    ) {
      return `bis ${durationRangeInMinutes.value.to} Minute`;
    }
    if (
      Number(durationRangeInMinutes.value.from) === 0 &&
      Number(durationRangeInMinutes.value.to)
    ) {
      return `bis ${durationRangeInMinutes.value.to} Minuten`;
    }
    if (
      Number(durationRangeInMinutes.value.from) ===
        Number(durationRangeInMinutes.value.to) &&
      Number(durationRangeInMinutes.value.from) !== 1 &&
      Number(durationRangeInMinutes.value.to) !== 1
    ) {
      return `${durationRangeInMinutes.value.to} Minuten`;
    }
    if (
      Number(durationRangeInMinutes.value.from) === 1 &&
      Number(durationRangeInMinutes.value.to) === 1
    ) {
      return `${durationRangeInMinutes.value.to} Minute`;
    }
    return `${durationRangeInMinutes.value.from} bis ${durationRangeInMinutes.value.to} Minuten`;
  });

  return { durationRange, displayLabelOfSelectedRange };
};
