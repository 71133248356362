export interface ExpandedSearchState {
  isExpandedSearchOpen: boolean;

  /**
   * A "copy" of the currently applied filterRules while the
   * expanded search component is open
   */
  currentFilterRules: Array<FilterRules.FilterRule>;
}

const expandedSearchState: ExpandedSearchState = {
  isExpandedSearchOpen: false,
  currentFilterRules: []
};

export default {
  state: expandedSearchState,
  mutations: {
    setIsExpandedSearchOpen: (
      state: ExpandedSearchState,
      isExpandedSearchOpen: boolean
    ) => {
      state.isExpandedSearchOpen = isExpandedSearchOpen;
    },
    setCurrentFilterRules: (
      state: ExpandedSearchState,
      currentFilterRules: Array<FilterRules.FilterRule>
    ) => {
      state.currentFilterRules = currentFilterRules;
    }
  },
  actions: {
    closeExpandedSearch: ({ commit }) => {
      commit("setIsExpandedSearchOpen", false);
    }
  },
  getters: {
    appliedFullTextRules: (state: ExpandedSearchState) =>
      state.currentFilterRules.filter(
        ({ type, fields }) =>
          type === "FILTER_FULLTEXT_FIELDS" &&
          !(fields?.length === 1 && fields?.[0] === "FIELD_PERSON")
      )
  },
  namespaced: true
};
