import { computed } from "@/composition-api";
import store from "@/store";

export default () => {
  const isLeftNavOpen = computed(() => store.state.navigation.isLeftNavOpen);

  const isSearchOpen = computed(() => store.state.navigation.isSearchOpen);

  return { isLeftNavOpen, isSearchOpen };
};
