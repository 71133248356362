import Vue from "vue";
import Router from "vue-router";
import multiguard from "vue-router-multiguard";
import qs from "query-string";
import store from "@/store";
import redirect from "@/utils/redirect";
import { v4 as uuidv4 } from "uuid";
import { computed } from "@/composition-api";
import { isCookieConsentBannerActive } from "./cookieBannerState";

const Home = () => import(/* webpackChunkName: "Home" */ "./views/Home.vue");
const Feed = () => import(/* webpackChunkName: "Feed" */ "./views/Feed.vue");
const AHSettingsSavedSearchDetailsPage = () =>
  import(
    /* webpackChunkName: "AHSettingsSavedSearch" */ "./components/AHSettingsSavedSearch/AHSettingsSavedSearchDetailsPage.vue"
  );

const AHNotAuthorizedPage = () =>
  /* webpackChunkName: "AHNotAuthorized" */ import(
    "./components/AHNotAuthorized/AHNotAuthorizedPage"
  );

const AHCuratedSearches = () =>
  import(
    /* webpackChunkName: "AHCuratedSearch" */ "./components/AHCuratedSearch/AHCuratedSearches.vue"
  );

const AHPlaylists = () =>
  import(
    /* webpackChunkName: "AHPlaylist" */ "./components/AHPlaylist/AHPlaylists.vue"
  );

const AHAudioUpload = () =>
  import(
    /* webpackChunkName: "AHAudioUpload" */ "./components/AHAudioUpload/AHAudioUpload.vue"
  );

const Settings = () =>
  import(/* webpackChunkName: "Feed" */ "./views/Settings.vue");

const Legal = () => import(/* webpackChunkName: "Legal" */ "./views/Legal.vue");

Vue.use(Router);

// NB: These routes are navigable to the /audioitems path via the back button in the AppBar
export const backButtonNavigationRoutes = /^(\/settings|\/submit|\/terms|\/legal|\/privacy|\/terms|\/imprint|\/feedback).*/;
export const backToHomeRoutes = /^(\/terms|\/legal|\/privacy|\/terms|\/imprint|\/feedback).*/;

const parseSearch = location => {
  const query = qs.parse(location.search);
  if (query.ticket) {
    const newQuery = { ...query };
    delete newQuery.ticket;
    return `?${qs.stringify({
      ...newQuery
    })}`;
  }
  return `?${qs.stringify(query)}`;
};

const getDeepLink = location => {
  const searchParams = location.search ? `${parseSearch(location)}&` : "?";
  const searchPath = !parseSearch(location).match(/path=*/)
    ? `path=${location.pathname.replace(/\/app/g, "")}`
    : "";

  if (location.pathname === "/cookies") {
    isCookieConsentBannerActive.value = true;
  }

  return `/home${searchParams}${searchPath}`;
};

const stageconfigGuard = async (to, from, next) => {
  if (!store.state.stageconfig.config) {
    await store.dispatch("stageconfig/fetchStageConfig");
  }
  next();
};

const jwtTicketGuard = async (to, from, next) => {
  const query = qs.parse(window.location.search);
  const { pubKey } = store.state.stageconfig.config;
  if (
    query.ticket &&
    (await store.dispatch("auth/authenticate", {
      token: query.ticket,
      cert: pubKey
    }))
  ) {
    next();
  } else {
    next();
  }
};

const isAuth0Enabled = computed(() => {
  return store.state.stageconfig.config.features.enableAuth0;
});

const authenticatedGuard = async (to, from, next) => {
  if (!store.getters["auth/isAuthenticated"]) {
    if (isAuth0Enabled && isAuth0Enabled.value) {
      const { auth0ClientId, ssoURLAuth0 } = store.state.stageconfig.config;

      const redirectUri = `${window.location.origin}/useradmin/token-exchange`;
      const uriComponent = `${window.location.origin}/?uuid=${uuidv4()}`;
      const href = `${ssoURLAuth0}/authorize?response_type=code&client_id=${auth0ClientId}&redirect_uri=${redirectUri}&scope=openid profile email address phone&state=${encodeURIComponent(
        uriComponent
      )}`;
      redirect(href);
    } else {
      const service = `${
        window.location.origin
      }/useradmin/tokenexchange?service=${
        window.location.origin
      }/app${getDeepLink(window.location)}`;
      const { ssoURL } = store.state.stageconfig.config;

      const href = `${ssoURL}/login?service=${encodeURIComponent(service)}`;
      redirect(href);
    }
  } else {
    next();
  }
};

const deeplinkGuard = (to, from, next) => {
  if (from.path === "/" && window.location.pathname !== "/") {
    next(getDeepLink(window.location));
  } else {
    next();
  }
};

const initializeAppcues = async (to, from, next) => {
  await authenticatedGuard(to, from, next);
  // NB: only if Appcues is injected
  if (window.Appcues) {
    window.Appcues.identify(`${store.state.user.profile.account_id}`, {
      createdAt: new Date(store.state.user.profile.createdAt),
      accessStatus: store.state.user.profile.accessStatus
    });
  }
  next();
};

const defaultGuards = [
  stageconfigGuard,
  jwtTicketGuard,
  authenticatedGuard,
  deeplinkGuard,
  initializeAppcues
];

export default new Router({
  mode: "history",
  base: "/app/",
  routes: [
    {
      path: "/",
      redirect: {
        path: "/audioitems/list"
      }
    },
    {
      name: "home",
      path: "/home",
      beforeEnter: multiguard([stageconfigGuard]),
      component: Home
    },
    {
      name: "unauthorized",
      path: "/unauthorized",
      beforeEnter: multiguard([stageconfigGuard]),
      component: AHNotAuthorizedPage
    },
    {
      path: "/audioitems",
      beforeEnter: multiguard(defaultGuards),
      redirect: {
        path: "/audioitems/list"
      }
    },
    {
      path: "/audioitems/:viewMode",
      beforeEnter: multiguard(defaultGuards),
      name: "audioitems",
      component: Feed
    },
    {
      name: "detail",
      path: "/audioitems/:viewMode/detail/:guid",
      beforeEnter: multiguard(defaultGuards),
      component: Feed,
      props: true
    },
    {
      name: "settings",
      path: "/settings",
      beforeEnter: multiguard(defaultGuards),
      component: Settings
    },
    {
      name: "settingsDetail",
      path: "/settings/:filterId",
      beforeEnter: multiguard(defaultGuards),
      component: AHSettingsSavedSearchDetailsPage
    },
    {
      name: "curatedSearches",
      path: "/curatedSearches",
      beforeEnter: multiguard(defaultGuards),
      component: AHCuratedSearches,
      /** To hide curated search feature: https://jira.mecom.de/browse/DPAID-2914 **/
      redirect: {
        path: "/home"
      }
    },
    {
      name: "playlists",
      path: "/playlists",
      beforeEnter: multiguard(defaultGuards),
      component: AHPlaylists,
      /** To hide playlists feature: https://jira.mecom.de/browse/DPAID-2914 **/
      redirect: {
        path: "/home"
      }
    },
    {
      name: "uploadAudio",
      path: "/uploadAudio",
      beforeEnter: multiguard(defaultGuards),
      component: AHAudioUpload
    },
    {
      name: "agb",
      path: "/agb",
      beforeEnter: multiguard(defaultGuards),
      component: Legal,
      props: {
        title: "AGB",
        source: "AGB"
      }
    },
    {
      name: "privacy",
      path: "/privacy",
      beforeEnter: multiguard(defaultGuards),
      component: Legal,
      props: {
        title: "Datenschutz",
        source: "Privacy"
      }
    },
    {
      name: "imprint",
      path: "/imprint",
      beforeEnter: multiguard(defaultGuards),
      component: Legal,
      props: {
        title: "Impressum",
        source: "Imprint"
      }
    },
    {
      path: "/cookies",
      beforeEnter: multiguard(defaultGuards),
      redirect: {
        path: "/audioitems/list"
      }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    let position;

    if (savedPosition) {
      position = savedPosition;
    } else if (to.hash) {
      position = {
        selector: to.hash,
        offset: { y: 64 }
      };
    } else if (!to.path.startsWith("/audioitems")) {
      position = {
        x: 0,
        y: 0
      };
    }

    return position;
  }
});
