import { MutationTree } from "vuex";
import { AudioPlayerState } from "./types";

export const mutations: MutationTree<AudioPlayerState> = {
  setPlayerStatus(state, status: boolean) {
    state.isActive = status;
  },
  setLoadingStatus(state, status: boolean) {
    state.isLoading = status;
  },
  setPlaybackStatus(state, status: boolean) {
    state.isPlaying = status;
  },
  setProgress(
    state,
    {
      progress,
      seek,
      duration
    }: { progress: number; seek: number; duration: number }
  ) {
    state.progress = progress;
    state.seek = seek;
    state.duration = duration;
  },
  setAudioId(state, audioId: string) {
    state.audioId = audioId;
  }
};
