import Vue from "vue";
import store from "@/store";
import {
  getTextRepresentation,
  TRAILING_COMMAS_PATTRN
} from "@/components/AHSearch/AdvancedFilters/advancedFullTextFilters.utils";
import useFullTextSearch from "@/components/AHSearch/useFullTextSearch";
import useFilterSearchFields from "@/components/AHSearch/AdvancedFilters/useFilterSearchFields";
import router from "@/router";
import useExpandedSearchModule from "./useExpandedSearchModule";
import useFetchAudioItems from "./useFetchAudioItems";
import { computed } from "@vue/composition-api";

export default () => {
  const { currentFilterRules } = useExpandedSearchModule();
  const hasFilterRules = computed(
    () => store.state.filterRules.filterRules.length > 0
  );

  const redirectToListView = () => {
    if (!router.currentRoute.path.startsWith("/audioitems")) {
      router.push({ path: "/audioitems" });
    }
  };

  const closeExpandedSearch = (
    payload: { isCancelled: boolean } = { isCancelled: false }
  ) => {
    if (payload.isCancelled) {
      // "Reset" filterRules store state if the search is cancelled
      store.commit("filterRules/setFilterRules", {
        filterRules: currentFilterRules.value
      });
    }

    Vue.nextTick(() => {
      store.dispatch("expandedSearch/closeExpandedSearch");
    });
  };

  const { fetchAudioitems } = useFetchAudioItems();

  const executeSearch = () => {
    fetchAudioitems({
      hasNewFilterRules: true,
      hasNewSort: false
    });

    const { fullTextFieldsFilterRules } = useFilterSearchFields();
    const { fullTextSearch } = useFullTextSearch();
    const textRepresentation = getTextRepresentation(
      fullTextFieldsFilterRules.value,
      false
    )
      .replaceAll(TRAILING_COMMAS_PATTRN, "")
      .trim();

    fullTextSearch.value = textRepresentation;

    closeExpandedSearch();

    if (!hasFilterRules.value) {
      router.push({ path: "/audioitems" });
    } else {
      redirectToListView();
    }
  };

  return {
    closeExpandedSearch,
    executeSearch,
    redirectToListView
  };
};
