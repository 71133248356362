/* eslint-disable no-shadow */

import { findById, findIndexById } from "./utils";
import {
  filterIsVisible,
  sortByIsPublic
} from "../../../utils/filterPassBadges";

/**
 * Returns the color of a filter.
 *
 * @module store/modules/filter
 * @param {Object} state
 * @param {Object} getters
 * @param {Function} getters.getFilterById
 * @return {Function}
 * @param {String} id - The id of the filter
 * @return {(String|undefined)} - The color of the matched filter, if not found undefined.
 */
const getColorById = (state, { getFilterById }) => id => {
  const { color } = getFilterById(id) || { color: "" };
  return color;
};

/**
 * Returns the name of a filter.
 *
 * @module store/modules/filter
 * @param {Object} state
 * @param {Object} getters
 * @param {Function} getters.getFilterById
 * @returns {Function}
 * @param {String} id - The id of the filter
 * @returns {(String|undefined)} - The name of the matched filter, if not found undefined.
 */
const getNameById = (state, { getFilterById }) => id => {
  const { name } = getFilterById(id) || { name: "" };
  return name;
};

export const getFilterCategoryValuesByName = state => name => {
  const filter = state.filters.find(f => f.name === name) || {
    filterRules: []
  };
  const { filterRules } = filter;
  const categoryFilterRule = filterRules.find(
    ({ type }) => type === "FILTER_CATEGORY"
  ) || {
    values: []
  };

  return categoryFilterRule.values;
};
/**
 * Returns a filter object for a given link
 * @param {Object} state
 * @param {Array} state.filters
 * @returns {Function}
 * @param {String} link - The link of the filter, this is a HATEOS ressource, e.g. https://devel.agenda-reloaded.de/filters/api/v1/public-filter-dpa-NoSports
 * @returns {Object|Boolean} - The found filter object or false if not found
 */
export const getFilterByLink = ({ filters }) => link =>
  filters.find(({ _links }) =>
    !!_links && _links.self ? _links.self.href === link : false
  );

/**
 * Returns an array of FilterPassBadge objects from an array of HATEOAS links.
 * @param  {[type]} state       [description]
 * @param  {[type]} getters     [description]
 * @param  {[type]} rootState   [description]
 * @param  {[type]} rootGetters [description]
 * @returns {Function}
 * @param {Array} links - An array of HATEOAS links
 * @return {Array}
 */
const getFilterPassBadgesFromLinks = (state, getters) => links =>
  links
    .filter(link => !!getters.getFilterByLink(link))
    .map(link => {
      const {
        color,
        name,
        isPublic,
        order,
        isVisible
      } = getters.getFilterByLink(link);
      return {
        color,
        name,
        isPublic,
        order,
        isVisible
      };
    })
    .filter(filterIsVisible)
    .sort(sortByIsPublic);

const getFilterById = ({ filters }) => id => findById(id, filters);

const mapFilterToGroup = group => item => ({ ...item, group });

const getFiltersSplitByOwner = ({ filters }) => [
  filters.filter(item => item.isPublic === false).map(mapFilterToGroup("own")),
  filters
    .filter(item => item.isPublic === true && item.category === "default")
    .map(mapFilterToGroup("default")),
  filters
    .filter(item => item.isPublic === true && item.category === "external")
    .map(mapFilterToGroup("external"))
];

const getFirstFilter = ({ filters }) =>
  getFiltersSplitByOwner({ filters }).reduce((acc, group) => [
    ...acc,
    ...group
  ])[0];

/**
 * Returns the index of a filter.
 * @param {Object} state
 * @param {Array} state.filters
 * @returns {Function}
 * @param {String} id - The id of the filter
 * @returns {Number} - The index in the filter array, if not found -1.
 */
export const getFilterIndexById = ({ filters }) => id =>
  findIndexById(id, filters);

/*
 * Returns only visible filters
 *
 * @param {Object} state
 * @param {Array} state.filters
 * @returns {Array} An array of visible filters.
 */
const getVisibleFilters = ({ filters }) =>
  filters.filter(filter => filter.isVisible);

/*
 * Returns the next visible filter
 *
 * @param {Object} state
 * @param {Array} state.filters
 * @returns {Function}
 * @param {String} id - The id of the filter
 * @returns {(Object|undfined)} - The next filter object if found, else undefined.
 */
const getNextVisibleFilterById = (state, { getNextFilterById }) => id =>
  getNextFilterById(id, true);

// TODO: DRY, compare with getNextVisibleFilterById
const getNextFilterById = ({ filters }, { getVisibleFilters }) => (
  id,
  isVisible = false
) => {
  const filterList = isVisible ? getVisibleFilters : filters;
  const index = findIndexById(id, filterList);
  let nextIndex;

  if (index !== -1 && index < filterList.length - 1) {
    nextIndex = index + 1;
  } else if (index === filterList.length - 1) {
    nextIndex = 0;
  }

  return nextIndex !== undefined ? filterList[nextIndex] : undefined;
};

const getFilterNames = ({ filters }) => filters.map(({ name }) => name);

const getPublicFilters = ({ filters }) =>
  filters.filter(({ isPublic }) => isPublic === true);

const getUserFilters = ({ filters }) =>
  filters.filter(({ isPublic }) => isPublic === false);

const hasFilters = ({ filters }) => filters.length > 0;

export default {
  getFilterById,
  getFiltersSplitByOwner,
  getFirstFilter,
  getColorById,
  getNameById,
  getFilterIndexById,
  getVisibleFilters,
  getNextVisibleFilterById,
  getNextFilterById,
  getFilterNames,
  getPublicFilters,
  getUserFilters,
  getFilterByLink,
  getFilterCategoryValuesByName,
  getFilterPassBadgesFromLinks,
  hasFilters
};
