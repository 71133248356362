


































import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
});
