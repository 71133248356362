import { format } from "date-fns";
import useBreakpoints from "@/composition/useBreakpoints";
/**
 * @see: https://innologica.github.io/vue2-daterange-picker/#props
 * @returns {Object} A set of properties that should be set on a date picker instance
 */
export const createDatePickerProps = () => {
  const localeData = {
    direction: "ltr",
    format: "dd.mm.yyyy",
    separator: " – ",
    applyLabel: "Anwenden",
    cancelLabel: "Abbrechen",
    weekLabel: "W",
    customRangeLabel: "Custom Range",
    // Need to prevent duplicate keys, therfore no single characters possible.
    daysOfWeek: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    monthNames: [
      "Jan",
      "Feb",
      "Mrz",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez"
    ],
    firstDay: 1
  };

  const createRanges = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return {
      Heute: [today, today],
      Gestern: [yesterday, yesterday],
      "Dieser Monat": [thisMonthStart, thisMonthEnd],
      "Dieses Jahr": [
        new Date(today.getFullYear(), 0, 1),
        new Date(today.getFullYear(), 11, 31)
      ],
      "Letzter Monat": [
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        new Date(today.getFullYear(), today.getMonth(), 0)
      ],
      "Letztes Jahr": [
        new Date(today.getFullYear() - 1, 0, 1),
        new Date(today.getFullYear() - 1, 11, 31)
      ],
      "Gesamte Zeit": [null, null]
    };
  };

  const { screen } = useBreakpoints();

  /**
   * @param dropdownList {HTMLUListElement}
   * @param component {Vue} current instance of vue date range picker
   * @param width {int} calculated width in pixels of the dropdown menu
   * @param top {int} absolute position top value in pixels relative to the document
   * @param left {int} absolute position left value in pixels relative to the document
   * @param right {int} absolute position right value in pixels relative to the document
   * @return {function|void}
   */
  const calculatePosition = (
    dropdownList,
    component,
    { width, top, left, right }
  ) => {
    if (
      screen.value.vh <= 750 ||
      (screen.value.vw >= 1024 && screen.value.vw <= 1280)
    ) {
      dropdownList.style.position = "fixed";
      dropdownList.style.left = "50%";
      dropdownList.style.top = "50%";
      dropdownList.style.transform = "translate(-50%, -50%)";
      dropdownList.style.boxShadow = "0 0 0 10000px rgba(0, 0, 0, 0.5)";
      dropdownList.classList.add("daterangepicker--hidden-caret");
    } else {
      if (component.opens === "center") {
        dropdownList.style.left = left + width / 2 + "px";
      } else if (component.opens === "left") {
        dropdownList.style.right = window.innerWidth - right + "px";
      } else if (component.opens === "right") {
        dropdownList.style.left = left + "px";
      }
      dropdownList.style.top = top + "px";
    }
  };

  return {
    autoApply: true,
    appendToBody: true,
    showDropdowns: true,
    localeData,
    ranges: createRanges(),
    calculatePosition
  };
};

export const formatDateRange = (
  startDate: Date | null,
  endDate: Date | null
) => {
  const DATE_FORMAT_STR = "dd.MM.yyyy";

  if (!!startDate && !!endDate) {
    return `${format(startDate, DATE_FORMAT_STR)} – ${format(
      endDate,
      DATE_FORMAT_STR
    )}`;
  }

  return "";
};

export default {
  createDatePickerProps,
  formatDateRange
};
