import Vue from "vue";
import VueCompositionApi, { ref } from "@vue/composition-api";

Vue.use(VueCompositionApi);

export const isCookieConsentBannerActive = ref(false);

export default {
  isCookieConsentBannerActive
};
