






import { defineComponent } from "@/composition-api";

export default defineComponent({
  name: "UiFormattedContent",
  props: {
    content: {
      type: String,
      required: false
    },
    size: {
      type: String,
      default: "default",
      validator(val: string) {
        return ["default", "sm", "lg", "xl", "2xl"].includes(val);
      }
    }
  },
  computed: {
    sizeClasses(): string {
      const classes = {
        default: "",
        sm: "prose-sm",
        lg: "prose-lg",
        xl: "prose-xl",
        "2xl": "prose-2xl"
      };

      return classes[this.size];
    }
  }
});
