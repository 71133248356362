import axios from "axios";

const GENRE_SCHEME_URI = ["http://g2.dpa.com/cv/textgenre/"];
const GENRE = {
  schemeUri: GENRE_SCHEME_URI,
  setter: "setGenres"
};

const RESSORT_SCHEME_URI = [
  "http://g2.dpa.com/cv/category/",
  "http://g2.dpa.com/cv/audience/"
];
const RESSORT = {
  schemeUri: RESSORT_SCHEME_URI,
  setter: "setRessorts"
};

const AREA_SCHEME_URI = [
  "http://g2.dpa.com/cv/country/",
  "http://g2.dpa.com/cv/area/"
];

const REGION = {
  schemeUri: AREA_SCHEME_URI,
  setter: "setAreas"
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchConceptItemsByType = ({ schemeUri, setter, names = [] }) => async (
  { commit },
  token
) => {
  try {
    const data = {
      _source: ["uri", "names"],
      query: {
        bool: {
          should: schemeUri.map(uri => ({
            bool: {
              must: {
                match: {
                  schemeUri: uri
                }
              }
            }
          }))
        }
      },
      size: 1000
    };
    const config = {
      baseURL: "https://cr.dpa-connect.de/v1",
      headers: { Authorization: `Bearer ${token}` }
    };

    commit("setIsLoading", true);

    const response = await axios.post("/concept/search", data, config);
    const { hits } = response.data.hits;
    const items = hits.map(({ _id, _source }) => ({
      id: _id,
      names: _source.names.map(({ lang, value, role }) => ({
        locale: lang,
        text: value,
        role
      }))
    }));

    commit(setter, items);
    commit("setIsLoading", false);
  } catch (error) {
    console.warn(error);
    commit("setIsLoading", false);
  }
};

const fetchGenres = fetchConceptItemsByType(GENRE);
const fetchRessorts = fetchConceptItemsByType(RESSORT);
const fetchAreas = fetchConceptItemsByType(REGION);

export default {
  fetchGenres,
  fetchRessorts,
  fetchAreas
};
