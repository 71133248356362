import deepEqual from "fast-deep-equal";
import { computed } from "@/composition-api";
import useBreakpoints from "@/composition/useBreakpoints";
import useAudioTypeQuery from "@/composition/useAudioTypeQuery";
import useRouter from "@dpa-connect/dpa-id-user/compositions/useRouter";
import router from "@/router";
import store from "@/store";
import { getTextRepresentation } from "@/components/AHSearch/AdvancedFilters/advancedFullTextFilters.utils";
import { featureTypes, SERVICE_CODE_MAP, types } from "./config/index";
import useMatomoEvent from "@/composition/useMatomoEvent";
import useFetchAudioItems from "@/composition/useFetchAudioItems";

export const removePropFromObject = ({
  originalObject,
  propName,
  propNames = []
}: {
  originalObject: Record<string, any>;
  propName?: string;
  propNames?: string[];
}) => {
  if (propName && !propNames.length) {
    propNames.push(propName);
  }

  const result = Object.keys(originalObject).reduce((acc, key) => {
    if (!propNames.some(name => name === key)) {
      return {
        ...acc,
        [key]: originalObject[key]
      };
    }

    return acc;
  }, {});

  return result;
};

export const getShortcutQuery = ({
  $route,
  query
}: {
  $route?: any;
  query: {
    audioType?: string;
    sort?: string;
    filterId?: string;
    playlistId?: string;
  };
}) => {
  const { audioType, sort, filterId, playlistId } = query;
  if (audioType) {
    const newQuery = removePropFromObject({
      originalObject: $route.query,
      propNames: ["filterId", "playlistId"]
    });

    return {
      ...newQuery,
      audioType,
      sort
    };
  }

  return removePropFromObject({
    originalObject: {
      ...($route?.query || {}),
      sort,
      ...(filterId ? { filterId } : playlistId ? { playlistId } : {})
    },
    propNames: [
      "audioType",
      ...(filterId || playlistId ? [] : ["filterId", "playlistId"])
    ]
  });
};

export const isExactLink = ({
  audioType: serviceCode,
  query,
  filterId,
  path
}: {
  audioType?: string;
  query?: { audioType: string };
  filterId?: string;
  path?: string;
}) => {
  if (filterId) {
    const filterIdQuery = store.state.route.query.filterId;
    const savedSearch = store.getters["filters/getFilterById"](filterId);
    const savedSearchFilterRules = savedSearch?.filterRules;
    const savedSearchId = savedSearch?.id;
    const isMatchingId = filterIdQuery === savedSearchId;
    const { filterRules } = store.state.filterRules;

    return isMatchingId && deepEqual(savedSearchFilterRules, filterRules);
  }

  const audioServiceFilterRule = store.state.filterRules.filterRules.find(
    ({ type }) => type === "FILTER_SERVICE"
  );

  // "Alle Inhalte" shortcut
  if (
    !router.currentRoute.query.filterId &&
    !router.currentRoute.query.playlistId &&
    router.currentRoute.path.startsWith(path) &&
    !serviceCode &&
    !query?.audioType &&
    !audioServiceFilterRule
  ) {
    return true;
  }

  // Audio type shortcuts
  if (serviceCode) {
    return serviceCode === query?.audioType;
  }

  // TODO: saved filters shortcuts

  return false;
};

// eslint-disable-next-line no-shadow
export const applyAudioServiceShortcutFilterRules = (serviceCode: string) => {
  const filterRules =
    serviceCode === "amyFavorites"
      ? [
          {
            type: "FILTER_IS_FAVORITE",
            values: ["true"]
          }
        ]
      : [
          // Audio type filter shortcuts
          ...(SERVICE_CODE_MAP[serviceCode]
            ? [
                {
                  type: "FILTER_SERVICE",
                  values: [SERVICE_CODE_MAP[serviceCode]?.uri]
                }
              ]
            : []) // "Alle Inhalte"
        ];

  if (serviceCode === "amyFavorites") {
    store.commit("filterRules/setFilterRules", { filterRules });
    store.commit("filterRules/setFullTextSearch", "");
  } else {
    const removedFilterRules = store.state.filterRules.filterRules.filter(
      ({ type }) => type === "FILTER_SERVICE"
    );
    store.dispatch("filterRules/replaceFilterRules", {
      removedFilterRules,
      addedFilterRules: filterRules
    });
  }
};

export const applySavedSearchFilterRules = (filterId: string) => {
  const savedSearch = store.getters["filters/getFilterById"](filterId);
  const filterRules = savedSearch?.filterRules;
  const allFilterRules = store.state.filterRules.filterRules;
  const hasDateRangeFilter = store.getters["audioitems/hasDateRangeFilter"];

  // Prevent unnecessary data fetching
  if (
    (filterRules && !deepEqual(filterRules, allFilterRules)) ||
    hasDateRangeFilter
  ) {
    store.commit("filterRules/setFilterRules", {
      filterRules: [...filterRules]
    });

    const fullTextFilterRules =
      store.getters["filterRules/fullTextFilterRulesExcludingSpeakers"];

    const textRepresentation = getTextRepresentation(fullTextFilterRules);
    store.commit("filterRules/setFullTextSearch", textRepresentation);
  }
};

export const removeSavedSearchFilterRules = (filterId: string) => {
  const savedSearch = store.getters["filters/getFilterById"](filterId);
  const filterRules = savedSearch?.filterRules;

  store.commit("filterRules/removeFilterRules", {
    filterRules: [...filterRules]
  });

  const fullTextFilterRules =
    store.getters["filterRules/fullTextFilterRulesExcludingSpeakers"];

  const textRepresentation = getTextRepresentation(fullTextFilterRules);
  store.commit("filterRules/setFullTextSearch", textRepresentation);
};

const resetFilterRules = () => {
  store.commit("filterRules/setFilterRules", {
    filterRules: []
  });

  store.commit("filterRules/setFullTextSearch", "");
};

export const useResetFilterRules = () => ({ resetFilterRules });

export const useNavLinkHelpers = (emit: Function) => () => {
  const { audioType } = useAudioTypeQuery();
  const { query } = useRouter(router)();

  const close = () => {
    emit("closeClick");
  };

  const { isDesktop } = useBreakpoints();

  const filterFeatureFlags = it => {
    if (
      store.state.stageconfig.config.features &&
      !store.state.stageconfig.config.features.enableFavorites
    ) {
      return it.query.audioType !== "amyFavorites";
    }
    return it;
  };

  const navConfig = computed(() =>
    types.map(type => ({
      ...type,
      isExactLink: isExactLink({
        audioType: audioType.value,
        query: type.query,
        path: "/audioitems"
      }),
      path: "/audioitems",
      query: getShortcutQuery({
        $route: {
          query: query.value
        },
        query: type.query
      })
    }))
  );

  const featureTypesConfig = computed(() =>
    featureTypes
      .filter(it => {
        return filterFeatureFlags(it);
      })
      .map(type => ({
        ...type,
        isExactLink: isExactLink({
          audioType: audioType.value,
          query: type.query,
          path: "/audioitems"
        }),
        path: "/audioitems",
        query: getShortcutQuery({
          $route: {
            query: query.value
          },
          query: type.query
        })
      }))
  );

  const { fetchAudioitems } = useFetchAudioItems();
  const isOpen = computed(() => store.state.navigation.isLeftNavOpen);
  const onNavClick = (config: {
    query: { audioType?: string; filterId?: string; playlistId?: string };
    isExactLink: boolean;
    label?: string;
    trackEventType?: string;
  }) => {
    if (!isDesktop.value && isOpen.value) {
      close();
    }

    if (config.trackEventType) {
      if (config.trackEventType === "quickfilters") {
        useMatomoEvent({
          category: "Left Navigation Bar",
          action: `Click ${config.label}`,
          name: "Quick Filters"
        });
      } else if (config.trackEventType === "playlist") {
        useMatomoEvent({
          category: "Left Navigation Bar",
          action: `Click Playlist`,
          name: "Playlist"
        });
      } else if (config.trackEventType === "favorites") {
        useMatomoEvent({
          category: "Left Navigation Bar",
          action: `Click Favoriten`,
          name: "Favoriten"
        });
      } else {
        useMatomoEvent({
          category: "Left Navigation Bar",
          action: `Click Saved Search`,
          name: "Saved Search"
        });
      }
    }

    // eslint-disable-next-line no-shadow
    const { query, isExactLink } = config;

    if (!isExactLink) {
      const updateFilterRules = query.filterId
        ? () => applySavedSearchFilterRules(query.filterId)
        : () => applyAudioServiceShortcutFilterRules(query.audioType);

      updateFilterRules();
      fetchAudioitems({
        hasNewFilterRules: true,
        hasNewSort: true
      });
    }
  };

  return { navConfig, featureTypesConfig, onNavClick, close };
};
