import { MutationTree } from "vuex";
import orderBy from "lodash.orderby";
import { AudioItemsState } from "./types";
import { getAudioContent } from "./getters";
import Vue from "vue";

export const mutations: MutationTree<AudioItemsState> = {
  setAudioitems: (state, audioitems) => {
    state.audioitems = audioitems;
  },
  setSearchContinuationFields: (state, searchContinuationFields) => {
    state.searchContinuationFields = searchContinuationFields;
  },
  appendAudioitems: (state, audioitems) => {
    state.audioitems = [...state.audioitems, ...audioitems];
  },
  setTotalResults: (state, totalResults) => {
    state.totalResults = totalResults;
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setIsDetailLoading: (state, isDetailLoading) => {
    state.isDetailLoading = isDetailLoading;
  },
  setActiveAudioitem: (state, payload) => {
    state.activeAudioItem = payload;
  },
  setFragmentSize: (state, fragmentSize) => {
    state.fragmentSize = fragmentSize;
  },
  mergePolledAudioItems: (state, { audioItems, sortSpecs }) => {
    const mergedAudioItems = [...state.audioitems, ...audioItems];
    const [sortSpec] = sortSpecs;
    const orderedAudioItems = orderBy(
      mergedAudioItems,
      (audioItem: Definitions.AudioItem) => {
        const value = audioItem[sortSpec.field];
        if (sortSpec.field === "content_created") {
          return new Date(value);
        }

        if (sortSpec.field === "duration") {
          const { duration } = getAudioContent(audioItem);
          return Number(duration);
        }

        return value;
      },
      sortSpec.direction
    );
    state.audioitems = orderedAudioItems;
  },
  resetPolledAudioItems: state => {
    const audioitems = state.audioitems.map(audioitem => {
      const newAudioitem = {
        ...audioitem
      };

      if (newAudioitem.polled) {
        delete newAudioitem.polled;
      }

      return newAudioitem;
    });

    state.audioitems = audioitems;
  },
  deleteAudioitem: (state, guid) => {
    const indexToRemove = state.audioitems.findIndex(
      audioitem => audioitem.guid === guid
    );
    if (indexToRemove > -1) {
      state.audioitems.splice(indexToRemove, 1);
    }
  },
  updateAudioItem: (state, audioItem) => {
    const indexToUpdate = state.audioitems.findIndex(
      audioitem => audioitem.guid === audioItem.guid
    );
    if (indexToUpdate > -1) {
      Vue.set(state.audioitems, indexToUpdate, {
        ...state.audioitems[indexToUpdate],
        ...audioItem
      });
    }
  }
};
