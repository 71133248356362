<template>
  <label
    class="flex self-center text-small"
    :class="
      disabled ? 'text-gray-400 cursor-default' : 'text-gray-900 cursor-pointer'
    "
  >
    <input
      class="relative self-center w-0 h-0 appearance-none focus:outline-none"
      :class="disabled ? 'cursor-default' : 'cursor-pointer'"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
      @keydown.enter="checked = !checked"
    />
    <span class="relative flex items-center">
      <slot></slot>
    </span>
  </label>
</template>
<script>
export default {
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    checked: Boolean,
    disabled: Boolean
  }
};
</script>

<style scoped>
/* Box */
input + span::before {
  @apply inline-block box-border w-5 h-5 border-2 border-blue;
  content: "";
  transition: border-color 0.2s, background-color 0.2s;
}

input:hover + span::before {
  @apply border-blue-dark;
}

input:focus-visible + span::before {
  @apply outline-blue;
}

input:checked:hover + span::before {
  @apply bg-blue-dark;
}

/* Box margin-right if slot has content */
input + span:not(:empty)::before {
  @apply mr-2;
}

input:disabled + span::before {
  @apply bg-gray-400 border-gray-400 !important;
}

input:checked + span::before {
  @apply bg-blue;
}

/* Checkmark */
input + span::after {
  @apply block absolute;
  content: "";
  height: 20px;
  width: 20px;
}

/* SVG checkmark icon */
input:checked + span::after {
  content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'><rect /><path fill-rule='evenodd' clip-rule='evenodd' d='M8.21965 15C7.98965 15 7.76965 14.905 7.61215 14.7375L3.55965 10.4217C3.24381 10.0867 3.26131 9.55918 3.59631 9.24418C3.93215 8.92918 4.45965 8.94585 4.77381 9.28085L8.21131 12.94L15.218 5.27168C15.5296 4.93085 16.0563 4.90835 16.3963 5.21835C16.7355 5.52835 16.7588 6.05585 16.4488 6.39501L8.83465 14.7283C8.67881 14.9 8.45715 14.9983 8.22548 15H8.21965Z' fill='white'/></svg>");
}
</style>
