

































import { defineComponent } from "@/composition-api";
import IconCheckmark from "@/assets/vectors/icons/checkmark.svg?inline";

export default defineComponent({
  name: "AHAudioPlayerProgress",
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  components: {
    IconCheckmark
  },
  data() {
    return {
      show: false,
      steps: [0.6, 0.8, 1.0, 1.2, 1.5, 2.0]
    };
  },
  methods: {
    formatSpeed(speed) {
      return `${speed.toFixed(1).replace(".", ",")}x`;
    }
  }
});
