import { ref, onMounted, computed } from "@/composition-api";
import throttle from "lodash.throttle";
import tailwindConfig from "../../tailwind.config.default";

export const currentBreakpoint = ({ innerWidth, innerHeight } = window) => {
  const { screens } = tailwindConfig.theme;
  const breakpoints = Object.keys(screens)
    .map(key => ({
      breakpoint: key,
      // eslint-disable-next-line radix
      width: parseInt(screens[key])
    }))
    .sort((a, b) => a.width - b.width);
  const vw = Math.max(
    document?.documentElement?.clientWidth || 0,
    innerWidth || 0
  );

  const vh = Math.max(
    document?.documentElement?.clientHeight || 0,
    innerHeight || 0
  );

  let result = { ...breakpoints[0], vw, vh };
  for (let i = 0; i < breakpoints.length; i += 1) {
    if (vw < breakpoints[i].width) {
      break;
    }
    result = { ...breakpoints[i], vw, vh };
  }
  return result;
};

export default () => {
  const screen = ref();

  const getCurrentBreakpoint = throttle(() => {
    screen.value = currentBreakpoint();
  }, 250);

  onMounted(() => {
    getCurrentBreakpoint();

    window.addEventListener("resize", getCurrentBreakpoint);
  });

  const isMobile = computed(
    () => screen.value && screen.value.breakpoint === "sm"
  );

  const isTablet = computed(
    () =>
      screen.value &&
      (screen.value.breakpoint === "md" || screen.value.breakpoint === "lg")
  );

  const isDesktop = computed(
    () => screen.value && screen.value.breakpoint === "xl"
  );

  return { screen, isMobile, isTablet, isDesktop };
};
