





















import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ""
    },
    author: {
      type: String,
      default: ""
    },
    guid: {
      type: String,
      default: ""
    }
  }
});
