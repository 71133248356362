import { computed } from "@vue/composition-api";
import uuidv1 from "uuid/v1";
import useAuthentication from "@/composition/useAuthentication";
import store from "@/store";
import state from "./useShareSearchState";
import AHSharingDialogEventBus from "@/components/AHSharingDialog/AHSharingDialogEventBus";

export default root => {
  const profile = store.getters["user/getProfile"];
  const { validateAndRetrieveToken } = useAuthentication();
  const currentPath = computed(() => root.$route.path);

  const saveSharedFilter = ({ name, filterRules }) => {
    const newSharedFilter = {
      id: uuidv1(),
      color: "#ff0000",
      name,
      owner: profile.sub,
      ownerDpaIdAccountId: profile.account_id,
      description: "",
      filterRules,
      isVisible: true,
      isPublic: false,
      isShared: true
    };
    state.sharedUrl = `${window.location.protocol}//${window.location.host}/app${currentPath.value}?sharedSearchId=${newSharedFilter.id}`;
    state.title = newSharedFilter.name;
    state.isSharedSearch = true;
    state.header = "Suche teilen";
    store.dispatch("filters/saveFilter", {
      filter: newSharedFilter,
      authJWT: validateAndRetrieveToken()
    });
    AHSharingDialogEventBus.$emit("show", {
      title: state.title,
      url: state.sharedUrl
    });
  };

  return {
    saveSharedFilter
  };
};
