import { onMounted, onBeforeUnmount } from "@/composition-api";

export default () => {
  onMounted(() => {
    document.documentElement.classList.add("scrolling-disabled");
  });

  onBeforeUnmount(() => {
    document.documentElement.classList.remove("scrolling-disabled");
  });
};
