import { Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { RootState } from "../../types";
import { AudioItemsState } from "./types";

const namespaced = true;

const audioItemsStore: Module<AudioItemsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default audioItemsStore;
