<template>
  <div class="relative z-10 group">
    <slot name="activator"></slot>
    <div
      :style="styles"
      class="transition-opacity duration-200 ease-linear supports-hover:group-hover:visible supports-hover:group-hover:opacity-100 opacity-0 invisible absolute items-center w-auto h-6 px-2 mt-2 text-xs leading-5 text-white bg-gray-900 rounded-full py-half flex"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@/composition-api";
import UiTransitionOpacity from "./UiTransitionOpacity.vue";

export default defineComponent({
  components: { UiTransitionOpacity },
  props: {
    position: {
      type: String,
      default: "bottom-center",
      validator(val) {
        return [
          "top-left",
          "top-center",
          "top-right",
          "right",
          "bottom-right",
          "bottom-center",
          "bottom-left",
          "left"
        ].includes(val);
      }
    },
    xOffset: {
      type: String,
      default: "-50%"
    },
    yOffset: {
      type: String,
      default: "-50%"
    }
  },
  data() {
    return {
      isVisible: false
    };
  },
  computed: {
    styles() {
      const baseStyles = "white-space: nowrap;";
      const styles = {
        "top-left": "left: 0; bottom: 100%;",
        "top-center": `left: 50%; bottom: 100%;; transform: translateX(${this.xOffset});`,
        "top-right": "right: 0; bottom: 100%;",
        right: `left: 100%; top: 50%; transform: translateY(${this.yOffset});`,
        "bottom-right": "right: 0; top: 100%;",
        "bottom-center": `left: 50%; top: 100%; transform: translateX(${this.xOffset});`,
        "bottom-left": "left: 0; top: 100%;",
        left: `right: 100%; top: 50%; transform: translateY(${this.yOffset});`
      };

      return `${baseStyles}${styles[this.position]}`;
    }
  }
});
</script>
<style scoped>
/*
 * Show tooltips only on hover capable devices, this circumenvents the tooltips
 * appearing on tap on touch devices. Once upgraded to tailwind 3 this can be
 * achieved with a feature flag within Tailwind itself instead.
 *
 * @see https://github.com/tailwindlabs/tailwindcss/pull/8394
 */
@media (hover: hover) and (pointer: fine) {
  .group:hover .supports-hover\:group-hover\:visible {
    visibility: visible;
  }
  .group:hover .supports-hover\:group-hover\:opacity-100 {
    opacity: 1;
  }
}
</style>
