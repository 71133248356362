


































































































import { computed, defineComponent, ref } from "@/composition-api";
import useSharingOptions from "@/composition/useSharingOptions";
import useDownloadBanner from "@/composition/useDownloadBanner";
import UiTooltip from "@/components/ui/UiTooltip.vue";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";
import UiIconSpinner from "@/assets/vectors/icons/spinner.svg?inline";
import UiIconDownload from "@/assets/vectors/icons/download.svg?inline";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import { usePreAuthorizeDownload } from "@/composition/useAuthorizeToken";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiButton from "@/components/ui/UiButton.vue";
import useMatomoEvent from "@/composition/useMatomoEvent";
import useGuid from "../AHAudioitem/shared/useGuid";
import UiSpinner from "@dpa-id-components/ui-spinner";
import "@dpa-id-components/ui-spinner/dist/UiSpinner.css";
import { selectedAudioItems } from "@/composition/useBulkSelection";

export default defineComponent({
  name: "AHAudioDownload",
  components: {
    UiButton,
    UiDialog,
    UiTooltip,
    UiButtonIcon,
    UiIconSpinner,
    UiIconClose,
    UiIconDownload,
    UiSpinner
  },
  props: {
    guid: {
      type: String,
      required: true
    },
    audioContent: {
      type: Object,
      required: true
    },
    headline: {
      type: String,
      required: true
    },
    origin: {
      type: String,
      required: true,
      validator(val: string) {
        return ["listview", "detailview", "player"].includes(val);
      }
    },
    buttonLabel: {
      type: String,
      default: "DOWNLOAD"
    },
    multipleItems: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const quotaUsageRequired = ref(false);
    const allowedToDownload = ref(true);
    const userQuotaStanding = ref("");
    /**
     * Creating computed references cause "useSharingOptions" expects them
     */
    const guid = computed(() => props.guid);
    const audioContent = computed(() => props.audioContent);
    const headline = computed(() => props.headline);

    const {
      downloadBulkAudio,
      showDownloadPendingOverlay,
      downloadAudio,
      downloadIsLoading
    } = useSharingOptions({
      guid,
      audioContent,
      headline
    });

    /**
     * Additional confirmation dialog for external content
     */
    const { isExternal } = useGuid(props.guid);
    const isExternalDialogConfirmed = ref(false);
    const showExternalDialog = ref(false);

    /**
     * Reset dialog triggers to default values to hide dialogs.
     */
    const closeDialog = () => {
      quotaUsageRequired.value = false;
      allowedToDownload.value = true;
      userQuotaStanding.value = "";
      showExternalDialog.value = false;
      isExternalDialogConfirmed.value = false;
      showDownloadPendingOverlay.value = false;
    };

    /**
     * Do the actual download and close dialogs.
     */
    const download = async () => {
      const names = {
        listview: "List View",
        detailview: "Detail View",
        player: "Player"
      };

      const matomoPayload = props.multipleItems
        ? {
            category: "Multiselect download",
            name: "Download is selected via Multiselect Menu",
            action:
              'When user clicks "Herunterladen" in multiselect menu => send event to count the downloads.'
          }
        : {
            category: "Downloads",
            action: "Click herunterladen",
            name: names[props.origin]
          };
      useMatomoEvent(matomoPayload);

      closeDialog();

      props.multipleItems ? await downloadBulkAudio() : await downloadAudio();
    };

    const { toggleDownloadBanner } = useDownloadBanner();

    /**
     * Check if the given audio file can be downloaded and if quota is required.
     */

    const moreThenTen = computed(() => selectedAudioItems.value.length > 10);
    const undeterminedCalculator = () => {
      if (props.multipleItems && !moreThenTen.value) {
        return {
          header: "Einheiten verbraucht",
          content: `Leider können Sie die Dateien nicht herunterladen, da sie nicht genügend Einheiten zur Verfügung haben. Bitte kontaktieren Sie den dpa-Vertrieb, um Ihr Kontingent aufzuladen.`
        };
      }
      if (props.multipleItems && moreThenTen.value) {
        return {
          header: "Zu viele Einheiten ausgewählt",
          content: "Es können maximal Zehn Audios heruntergeladen werden."
        };
      } else {
        return {
          header: "Einheiten verbraucht",
          content: `Leider können Sie die Datei nicht herunterladen, da alle Einheiten
          verbraucht sind. Bitte kontaktieren Sie den Vertrieb, um Ihr
          Kontingent aufzuladen.`
        };
      }
    };

    const checkDownload = async () => {
      const downloadValidationProcess = async () => {
        showExternalDialog.value = false;
        const preAuthorizeDownload = await usePreAuthorizeDownload(
          !props.multipleItems ? props.audioContent.residref : props.guid
        );
        const tooManySelected =
          props.multipleItems &&
          preAuthorizeDownload.quotaAvailable < selectedAudioItems.value.length;

        quotaUsageRequired.value = preAuthorizeDownload.quotaUsageRequired;
        allowedToDownload.value =
          tooManySelected || moreThenTen.value
            ? false
            : preAuthorizeDownload.allowedToDownload;

        userQuotaStanding.value = preAuthorizeDownload.userQuotaStanding;

        if (allowedToDownload.value && !quotaUsageRequired.value) {
          await download();
        }

        toggleDownloadBanner({
          userQuotaStanding: preAuthorizeDownload.userQuotaStanding,
          accessStatus: preAuthorizeDownload.accessStatus,
          quotaAvailable: preAuthorizeDownload.quotaAvailable,
          unlimited: preAuthorizeDownload.unlimited
        });
      };

      if (props.multipleItems) {
        await downloadValidationProcess();
      }
      if (isExternal.value && !isExternalDialogConfirmed.value) {
        showExternalDialog.value = true;
      } else {
        await downloadValidationProcess();
      }
    };

    const confirmExternalDialog = () => {
      isExternalDialogConfirmed.value = true;
      checkDownload();
    };

    const errorMessage = computed(() => {
      const LOOKUP = {
        CUSTOMER_QUOTA_NOT_ACTIVE: {
          header: "Kontingent inaktiv",
          content: `Leider können Sie die Datei nicht herunterladen, da Ihr Kontingent
          nicht mehr aktiv ist. Bitte kontaktieren Sie den Vertrieb, um Ihr
          Kontingent zu aktivieren.`
        },
        QUOTA_EXPIRED: {
          header: "Kontingent abgelaufen",
          content: `Leider können Sie die Datei nicht herunterladen, da Ihr Kontingent
          abgelaufen ist. Bitte kontaktieren Sie den Vertrieb, um einen neues
          Kontingent abzuschließen.`
        },
        TRIAL_DEACTIVATED: {
          header: "Testzugang inaktiv",
          content: `Leider können Sie die Datei nicht herunterladen, da Ihre Testzugang
          nicht aktiv ist. Bitte kontaktieren Sie den Vertrieb, um Ihren
          Testzugang zu aktivieren.`
        },
        SUBSCRIPTION_EXPIRED: {
          header: "Abo abgelaufen",
          content: `Leider können Sie die Datei nicht herunterladen, da Ihr Abo abgelaufen
          ist. Bitte kontaktieren Sie den Vertrieb, um Ihr Abo aufzuladen.`
        },
        UNDETERMINED: undeterminedCalculator()
      };

      return LOOKUP[userQuotaStanding.value];
    });

    return {
      allowedToDownload,
      checkDownload,
      closeDialog,
      confirmExternalDialog,
      download,
      downloadIsLoading,
      errorMessage,
      quotaUsageRequired,
      showExternalDialog,
      userQuotaStanding,
      showDownloadPendingOverlay,
      moreThenTen
    };
  }
});
