/**
 * Declaring the matomo window object for typescript.
 */
declare global {
  interface Window {
    _paq: any;
  }
}

export default ({
  category,
  action,
  name = null,
  value = null
}: {
  category: string;
  action: string;
  name?: string;
  value?: string;
}) => {
  if (window?._paq) {
    window._paq.push(["trackEvent", category, name, action, value]);
  }
};
