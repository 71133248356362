import { computed } from "@/composition-api";
import useRouter from "@dpa-connect/dpa-id-user/compositions/useRouter";
import useAuthentication from "@/composition/useAuthentication";
import debounce from "lodash.debounce";
import router from "@/router";
import store from "@/store";
import useSearchMatches from "./useSearchMatches";
import { defaultSort } from "../components/nav/config/index";
import { clearBulkSelection } from "./useBulkSelection";

export default () => {
  const { query } = useRouter(router)();
  const { validateAndRetrieveToken } = useAuthentication();

  const sort = computed(() => {
    try {
      return query.value.sort
        ? JSON.parse(query.value.sort)
        : JSON.parse(defaultSort);
    } catch (error) {
      console.warn(error); /* eslint-disable: no-console */
      return "";
    }
  });

  const filterRules = computed(() => {
    const rules: FilterRules.FilterRule[] = store.state.filterRules.filterRules;

    return {
      ...(rules.length ? { filterRules: rules } : [])
    };
  });

  const {
    isNewFilterRuleSearch,
    isNewSearch,
    isNewSortSearch
  } = useSearchMatches();

  const fetchAudioitems: Function = debounce(
    ({
      hasNewFilterRules,
      hasNewSort
    }: {
      hasNewFilterRules: boolean;
      hasNewSort: boolean;
    }) => {
      isNewFilterRuleSearch.value = hasNewFilterRules;
      isNewSortSearch.value = hasNewSort;

      const sortSpecs = sort.value ? { sortSpecs: [sort.value] } : {};

      const searchContinuationFields = isNewSearch.value
        ? {}
        : {
            searchContinuationFields:
              store.state.audioitems.searchContinuationFields
          };

      if (isNewFilterRuleSearch || isNewSearch || isNewSortSearch) {
        clearBulkSelection();
      }

      store.dispatch("audioitems/fetchAudioitems", {
        authJWT: validateAndRetrieveToken(),
        ...sortSpecs,
        ...searchContinuationFields,
        ...filterRules.value
      });

      store.commit(
        "expandedSearch/setCurrentFilterRules",
        store.state.filterRules.filterRules
      );
    }
  );

  const isLoading = computed(() => store.state.audioitems.isLoading);

  return {
    fetchAudioitems,
    filterRules,
    sort,
    isLoading
  };
};
