import { computed, watch, ref } from "@/composition-api";
import store from "@/store";
import router from "@/router";
import { defaultSort as sort } from "../components/nav/config/index";
import orderBy from "lodash.orderby";

export default () => {
  const curatedSearchImageURL = computed(
    () => store.state.stageconfig.config.curatedSearchImageURL
  );

  const curatedSearches = computed(() => {
    return store.state.filters.filters
      .filter(
        ({ curatedSearch, isCuratedSearchActive }) =>
          curatedSearch === true && isCuratedSearchActive === true
      )
      .map(
        ({
          name,
          imageIdentifier,
          description,
          updatedAt,
          id,
          isCuratedSearchActive,
          order
        }) => ({
          label: name,
          src: `${curatedSearchImageURL.value}/${imageIdentifier}`,
          id,
          description,
          updatedAt: new Date(updatedAt),
          isCuratedSearchActive,
          order
        })
      );
  });

  const allSavedSearches = computed(() => store.state.filters.filters);

  const getAllCuratedSearches = () => {
    const curatedSearches = allSavedSearches.value.filter(
      ({ curatedSearch, isCuratedSearchActive }) =>
        curatedSearch || isCuratedSearchActive
    );
    const mappedImgSrc = curatedSearches.map(
      ({
        name,
        imageIdentifier,
        description,
        updatedAt,
        id,
        isCuratedSearchActive,
        order
      }) => ({
        label: name,
        src: `${curatedSearchImageURL.value}/${imageIdentifier}`,
        imageIdentifier,
        id,
        description,
        updatedAt: updatedAt,
        isCuratedSearchActive,
        order
      })
    );
    const sorted = orderBy(mappedImgSrc, ["order"], ["asc"]);

    return sorted;
  };

  const onCardClick = id => {
    router.push({ path: "/audioitems", query: { sort, filterId: id } });
  };

  const allCuratedSearches = ref([]);

  /**
   * There are cases when the initial store state of the curated searches are
   * empty (e.g. first logged in session in a new browser). As we only want to
   * "copy" the initially loaded searches, we're using watch to hook on this change.
   **/
  watch(
    allSavedSearches,
    () => {
      if (allSavedSearches.value.length && !allCuratedSearches.value.length) {
        allCuratedSearches.value = getAllCuratedSearches();
      }
    },
    { immediate: true, deep: true }
  );

  const setAllCuratedSearches = searches => {
    allCuratedSearches.value = searches;
  };

  const orderCuratedSearches = curatedSearches => {
    return orderBy(curatedSearches.value, ["order"], ["asc"]);
  };

  const removeCuratedSearch = ({ id }) => {
    const updatedCuratedSearches = [...allCuratedSearches.value];
    const removedIndex = updatedCuratedSearches.findIndex(
      search => search.id === id
    );

    updatedCuratedSearches.splice(removedIndex, 1);

    allCuratedSearches.value = updatedCuratedSearches;
  };

  return {
    curatedSearches,
    allCuratedSearches,
    onCardClick,
    setAllCuratedSearches,
    removeCuratedSearch,
    orderCuratedSearches
  };
};
