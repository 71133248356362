





















































import UiDialog from "@/components/ui/UiDialog.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import store from "@/store";
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  ref,
  toRefs,
  watch
} from "@/composition-api";
import AHSharingDialogEventBus from "@/components/AHSharingDialog/AHSharingDialogEventBus";
import UiInput from "@dpa-id-components/ui-input";
import UiToggle from "@/components/ui/UiToggle.vue";
import UiButton from "@/components/ui/UiButton.vue";
import copyToClipboard from "@/utils/copyToClipboard";
import shareSearchState from "@/composition/useShareSearchState";
import useAuthentication from "@/composition/useAuthentication";
import useRouter from "@/composition/useRouter";
import useExpandedSearch from "@/composition/useExpandedSearch";
import router from "@/router";

export default defineComponent({
  name: "AHSharingDialog",
  components: { UiButton, UiToggle, UiInput, UiDialog, UiIconClose },
  setup() {
    const eventBus = inject("eventBus") as Vue;
    const title = ref("");
    const baseUrl = ref("");
    const visible = ref(false);
    const useTimestamp = ref(false);
    const timestamp = ref("0:00");
    const { header, isSharedSearch } = toRefs(shareSearchState);
    const { validateAndRetrieveToken } = useAuthentication();
    const { route } = useRouter();
    const { executeSearch } = useExpandedSearch();

    const showDialog = () => {
      visible.value = true;
    };

    const hideDialog = () => {
      visible.value = false;
    };

    const url = computed(() => {
      const sanitizedTimestamp = timestamp.value
        .split(":")
        .reverse()
        .reduce((prev, curr, i) => prev + Number(curr) * Math.pow(60, i), 0);
      return (
        baseUrl.value + (useTimestamp.value ? `?t=${sanitizedTimestamp}` : "")
      );
    });

    const handleMailBtn = () => {
      const body = isSharedSearch.value
        ? `Hallo, ich habe die Suche ${title.value} im dpa-Audio Hub erstellt, die für dich interessant sein könnte: ${url.value}`
        : `Hallo, ich habe folgende Audio-Datei im dpa Audio-Hub gefunden, die dich vielleicht interessieren könnte ${url.value}`;
      return (window.location.href = `mailto:%20?body=${encodeURI(
        body
      )},&subject=${encodeURI(
        isSharedSearch.value
          ? `Gespeicherte Suche ${title.value} von dpa-Audio Hub`
          : title.value
      )}`);
    };

    const handleCopyBtn = () => {
      copyToClipboard(url.value);
      eventBus.$emit("show", {
        title: "Link kopiert"
      });
      hideDialog();
    };

    onMounted(() => {
      AHSharingDialogEventBus.$on("show", payload => {
        title.value = payload.title || "";
        baseUrl.value = payload.url;

        /**
         * Check if audio is currently played and set timestamp based on current seek
         */
        if (
          payload.audioId &&
          store.state.audioPlayer.isActive &&
          store.state.audioPlayer.audioId === payload.audioId
        ) {
          timestamp.value = new Date(store.state.audioPlayer.seek * 1000)
            .toISOString()
            .substr(
              store.state.audioPlayer.seek >= 3600 ? 11 : 14,
              store.state.audioPlayer.seek >= 3600 ? 8 : 5
            );
        } else {
          timestamp.value = "0:00";
        }
        showDialog();
      });
    });

    // Retrieve and apply shared search from filters service
    watch(route, async () => {
      if (route.value.query.sharedSearchId) {
        const { filterRules } = await store.dispatch(
          "filters/getSharedSearch",
          {
            sharedSearchId: route.value.query.sharedSearchId,
            authJWT: validateAndRetrieveToken()
          }
        );

        store.commit("filterRules/setFilterRules", { filterRules });
        executeSearch();

        const newQuery = {
          ...route.value.query
        };
        delete newQuery.sharedSearchId;
        router.push({ query: newQuery });
      }
    });

    return {
      header,
      isSharedSearch,
      visible,
      url,
      timestamp,
      useTimestamp,
      showDialog,
      hideDialog,
      handleMailBtn,
      handleCopyBtn
    };
  }
});
