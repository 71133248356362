import Vue from "vue";
import { getCurrentInstance, reactive, toRefs, watch } from "@/composition-api";
import { Route } from "vue-router";

export const getRuntimeVM = (): Vue => {
  const vm = getCurrentInstance();
  if (vm) return vm;

  throw new ReferenceError("[vue-hooks] Not found vue instance.");
};

type State = {
  route: Route;
};

const useRouter = () => {
  const vm = getRuntimeVM();
  const state = reactive<State>({
    route: vm.$route
  });

  watch(
    () => vm.$route,
    r => {
      state.route = r as any;
    }
  );

  return { ...toRefs(state), router: vm.$router };
};

export default useRouter;
