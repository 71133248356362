const genres: any[] = [];
const ressorts: any[] = [];
const areas: any[] = [];
const isLoading = false;

export default {
  genres,
  ressorts,
  areas,
  isLoading
};
