import { computed, ComputedRef } from "@/composition-api";
import store from "@/store";
import {
  getSearchWordsOrPhrases,
  getStartsWithMinusTokens,
  getNotTokens,
  getCombinedOrTokens,
  stripTokenOuterQuotes,
  notAndOrExcludesOperator,
  getTokenIfNotExcluded,
  getFullTextFilterRules,
  getOrderedTokens,
  getOrderedFullTextFilterRules,
  getFilterRuleInputPattern
} from "./search.utils";
import useFilterSearchFields from "./AdvancedFilters/useFilterSearchFields";

export default () => {
  const fullTextSearch = computed({
    get: () => String(store.state.filterRules.fullTextSearch),
    set: text => {
      store.commit("filterRules/setFullTextSearch", text);
    }
  });

  const searchTokens = computed(() =>
    getSearchWordsOrPhrases(fullTextSearch.value)
  );

  const startsWithMinusTokens = computed(() =>
    getStartsWithMinusTokens(searchTokens.value)
  );

  const notTokens: ComputedRef<any[]> = computed(() =>
    getNotTokens(searchTokens.value)
  );

  const excludedTokens = computed(() => [
    ...startsWithMinusTokens.value,
    ...notTokens.value
  ]);

  const fullTextWithoutExcludes = computed(() => {
    const tokens = searchTokens.value
      .map(stripTokenOuterQuotes)
      .filter(notAndOrExcludesOperator)
      .filter(getTokenIfNotExcluded(excludedTokens.value));

    return getCombinedOrTokens(tokens);
  });

  const {
    fullTextFieldsFilterRules,
    fullTextHasFilteredFields
  } = useFilterSearchFields();

  const fullTextFilterRules = computed(() => {
    const fields = fullTextHasFilteredFields.value
      ? fullTextFieldsFilterRules.value[0].fields
      : [];

    const includedFullTextRules = getFullTextFilterRules({
      tokens: fullTextWithoutExcludes.value,
      fields
    });

    const excludedFullTextRules = getFullTextFilterRules({
      tokens: excludedTokens.value.map(({ token }) => token),
      exclude: true,
      fields
    });

    // Included filterRules before excluded rules
    const filterRules = [...includedFullTextRules, ...excludedFullTextRules];

    const orderedTokens = getOrderedTokens(fullTextSearch.value);

    // Restore the order of filterRules as entered by the user
    const ordered = getOrderedFullTextFilterRules({
      orderedTokens,
      filterRules
    });
    return ordered;
  });

  const removeFilterRuleInputRepresentation = (
    filterRule: FilterRules.FilterRule
  ) => {
    const patternToRemove = getFilterRuleInputPattern(filterRule);

    fullTextSearch.value = fullTextSearch.value
      .replace(patternToRemove, "")
      .trim();
  };

  return {
    fullTextSearch,
    fullTextFilterRules,
    removeFilterRuleInputRepresentation
  };
};
