const setGenres = (state, genres: any[]) => {
  state.genres = genres;
};

const setRessorts = (state, ressorts: any[]) => {
  state.ressorts = ressorts;
};

const setAreas = (state, areas: any[]) => {
  state.areas = areas;
};

const setIsLoading = (state, value: boolean) => {
  state.isLoading = value;
};

export default {
  setGenres,
  setRessorts,
  setAreas,
  setIsLoading
};
