const mockLocalStorage =
  !window || !window.localStorage
    ? require("mock-local-storage")
    : window.localStorage;

export default () => {
  if (!window || !window.localStorage) {
    return {
      localStorage: mockLocalStorage
    };
  }
  return window;
};
