export default () => {
  return [
    {
      icon: "lightbulb",
      link: "https://www.dpa.com/kampagnen/09/Schnellhilfe_dpa-Audio_Hub.pdf",
      target: "_blank",
      title: "Häufige Fragen"
    },
    {
      icon: "emoji",
      link: "mailto: audiohub@dpa.com",
      target: "_self",
      title: "Feedback geben"
    },
    {
      icon: "envelope",
      link: "mailto: kundenservice.desk@dpa.com",
      target: "_self",
      title: "Support kontaktieren"
    }
  ];
};
