












import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    size: {
      type: String,
      default: "medium",
      validator(val: string) {
        return ["small", "medium", "large"].includes(val);
      }
    },
    color: {
      type: String,
      default: "blue",
      validator(val: string) {
        return ["blue", "white", "gray"].includes(val);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: [Object, null],
      required: false,
      default: null
    }
  },
  computed: {
    variantClassName(): string {
      const variant = this.disabled ? "disabled" : this.color;

      const map = {
        blue: "text-white bg-blue hover:bg-blue-dark",
        white: "text-blue bg-white hover:text-blue-dark",
        gray: "text-blue bg-white border-2 hover:text-blue-dark",
        disabled: "text-gray-700 bg-gray-300 cursor-default"
      };

      return map[variant];
    },
    sizeClassName(): string {
      const lookup = {
        small: "h-8 text-sm",
        medium: "h-10 text-base",
        large: "h-12 text-base"
      };

      return lookup[this.size];
    },
    tagName(): string {
      if (this.to !== null) {
        return "router-link";
      }
      return "button";
    }
  }
});
