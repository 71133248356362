import { computed } from "@/composition-api";
import useAuthentication from "@/composition/useAuthentication";

export default () => {
  const { profile } = useAuthentication();
  const isAdmin = computed(() => profile.value.roles?.includes("admin"));
  const isEditor = computed(() => profile.value.roles?.includes("editor"));
  const isUser = computed(() => profile.value.roles?.includes("user"));
  const isAdminOrEditor = computed(() => isAdmin.value || isEditor.value);
  const isReader = computed(
    () =>
      profile.value.roles?.length === 1 && profile.value.roles?.[0] === "reader"
  );

  return {
    isAdmin,
    isEditor,
    isUser,
    isAdminOrEditor,
    isReader
  };
};
