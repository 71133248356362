import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import createPersistedState from "vuex-persistedstate";
import undoRedo from "undo-redo-vuex";
import authModule from "@dpa-connect/dpa-id-user/store/modules/auth";
import user from "@dpa-connect/dpa-id-user/store/modules/user";
import filters from "./modules/filters";
import filterRules from "./modules/filterRules";
import settings from "./modules/settings";
import stageconfig from "./modules/stageconfig";
import conceptItems from "./modules/conceptItems";
import language from "./language";
import audioitems from "./modules/audioItems";
import audioPlayer from "./modules/audioPlayer";
import navigation from "./modules/navigation";
import expandedSearch from "./modules/expandedSearch";
import playlists from "./modules/playlists";
import API_SETTINGS from "../constants/apiSettings";
import { RootState } from "./types";

const auth = authModule(API_SETTINGS);

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

/*
 * NB: Rehydrates created date from local storage.
 */

function getState(key, storage) {
  let result;

  try {
    const item = storage.getItem(key);
    if (item !== null) {
      result = JSON.parse(item);

      // Do not persist UI state for save search dialog
      if (result.filters) {
        result.filters.showSaveSearchDialog = false;
        result.filters.showCuratedSearchDialog = false;
        result.filters.showCuratedSearchEditDialog = false;
      }
    }
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
  }

  return result;
}

const store: StoreOptions<RootState> = {
  plugins: [
    createPersistedState({
      paths: ["filters", "settings"],
      getState
    }),
    undoRedo({
      paths: [
        {
          namespace: "filters",
          ignoreMutations: [
            "setErrorStatus",
            "setFilterIsVisible",
            "setIsLoadingStatus",
            "setLoadedStatus"
          ]
        }
      ]
    })
  ],
  modules: {
    audioitems,
    playlists,
    audioPlayer,
    auth,
    conceptItems,
    expandedSearch,
    filterRules,
    filters,
    language,
    navigation,
    settings,
    stageconfig,
    user
  },
  strict: debug
};

export default new Vuex.Store<RootState>(store);
