import { ComputedRef } from "@/composition-api";
import AHAudioEditEventBus from "@/components/AHAudioEdit/AHAudioEditEventBus";
import AHAudioDeleteEventBus from "@/components/AHAudioDelete/AHAudioDeleteEventBus";
import AHAddToPlaylistDialogEventBus from "@/components/AHPlaylist/AHAddToPlaylistDialogEventBus";
import store from "@/store";

export default ({
  guid,
  headline
}: {
  guid: ComputedRef<string>;
  headline?: ComputedRef<string>;
}) => {
  function editAudioItem() {
    AHAudioEditEventBus.$emit("showEditModal", {
      headline: headline.value,
      guid: guid
    });
  }

  function deleteAudioItem() {
    AHAudioDeleteEventBus.$emit("showDeleteModal", {
      headline: headline.value,
      guid: guid
    });
  }

  function createPlaylist() {
    AHAddToPlaylistDialogEventBus.$emit("createPlaylist", {
      guid: guid
    });
  }

  function toggleFavorites(authJWT, audioItem) {
    store.dispatch("audioitems/toggleFavorite", {
      authJWT: authJWT,
      audioItem: audioItem
    });
  }

  function toggleBulkFavorites(authJWT, audioItems) {
    store.dispatch("audioitems/toggleBulkFavorite", {
      authJWT,
      audioItems
    });
  }
  return {
    editAudioItem,
    deleteAudioItem,
    createPlaylist,
    toggleFavorites,
    toggleBulkFavorites
  };
};
