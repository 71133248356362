import { computed } from "@/composition-api";
import useExpandedSearchModule from "@/composition/useExpandedSearchModule";
import displayLabelOfSelectedRange from "./audioLengthFormatter";
import { formatDateRange } from "@/components/AHSearch/AdvancedFilters/lib/createDatePicker";

// Utils for formatting tooltip descriptions from filer rules

const getServiceNamesFromRule = ({ values }: { values: string[] }) => {
  return values
    .map(entry =>
      entry
        .replace("http://g2.dpa.com/cv/service/aoton", "O-Ton")
        .replace("http://g2.dpa.com/cv/service/atalk", "Interview")
        .replace("http://g2.dpa.com/cv/service/aumfrage", "Umfrage")
        .replace("http://g2.dpa.com/cv/service/aatmo", "Atmo")
    )
    .join(", ");
};

const getFilteredTextFieldsFromRule = ({ fields }: { fields?: string[] }) => {
  return fields
    .map(field =>
      field
        .replace("FIELD_HEADLINE", "Titel")
        .replace("FIELD_TRANSCRIPT", "Transkript")
        .replace("FIELD_KEYWORD", "Stichwörter")
        .replace("FIELD_PERSON", "Sprecher/in")
        .replace("FIELD_DESCRIPTION", "Kurz-Beschreibung")
    )
    .join(", ");
};

export default () => {
  const { currentFilterRules } = useExpandedSearchModule();

  // Computed refs for format description text

  const categoryTooltip = computed(() => {
    const description = getServiceNamesFromRule(
      currentFilterRules.value.find(
        ({ type }) => type === "FILTER_SERVICE"
      ) || { values: [] }
    );

    return description
      ? [
          {
            title: "Kategorie",
            description
          }
        ]
      : [];
  });

  const textFieldsTooltip = computed(() => {
    const description = getFilteredTextFieldsFromRule(
      currentFilterRules.value.find(
        ({ type, fields }) => type === "FILTER_FULLTEXT_FIELDS" && !!fields
      ) || { fields: [] }
    );

    return description
      ? [
          {
            title: "Suche in",
            description
          }
        ]
      : [];
  });

  const datesTooltip = computed(() => {
    const dateRange = currentFilterRules.value.find(
      ({ type }) => type === "FILTER_CONTENT_CREATED"
    )?.values;
    return dateRange
      ? [
          {
            title: "Datum",
            description: formatDateRange(dateRange[0], dateRange[1])
          }
        ]
      : [];
  });

  const lengthTooltip = computed(() => {
    const description =
      currentFilterRules.value.find(({ type }) => type === "FILTER_DURATION") &&
      displayLabelOfSelectedRange().displayLabelOfSelectedRange.value;

    return description
      ? [
          {
            title: "Länge",
            description
          }
        ]
      : [];
  });

  const expandedSearchTooltipInfo = computed(() => [
    // Order of tooltip descriptions will be fixed
    ...categoryTooltip.value,
    ...textFieldsTooltip.value,
    ...datesTooltip.value,
    ...lengthTooltip.value
  ]);

  return {
    expandedSearchTooltipInfo
  };
};
