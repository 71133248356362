import { computed, ref } from "@/composition-api";
import store from "@/store";
import useExpandedSearchModule from "./useExpandedSearchModule";

export default () => {
  /**
   * @member { Ref<Boolean> } isNewFilterRuleSearch - whether filterRules have changed
   */
  const isNewFilterRuleSearch = ref(true);

  /**
   * @member { Ref<Boolean> } isNewSortSearch - whether the sort option has changed
   */
  const isNewSortSearch = ref(true);

  /**
   * @member { ComputedRef<Boolean> } isNewSearch - whether a new search should be executed
   */
  const isNewSearch = computed(
    () => isNewFilterRuleSearch.value || isNewSortSearch.value
  );

  const { currentFilterRules } = useExpandedSearchModule();
  const hasFilterRules = computed(
    () => store.state.filterRules.filterRules.length
  );
  const hasDateRangeFilter = computed(() => {
    const { from, to } = store.state.audioitems;

    return from !== null || to !== null;
  });

  const totalResults = computed(() => store.state.audioitems.totalResults);

  const shouldDisplaySearchMatches = computed(() => {
    const { isLoading } = store.state.audioitems;
    const hasSearchMatches = totalResults?.value > 0;
    const isNewSortAndFilterRules =
      isNewSortSearch.value && isNewFilterRuleSearch.value;
    const isLoadingMore = isLoading && !isNewSearch.value;
    const isLoadingNewSort = isNewSortSearch.value && !isNewSortAndFilterRules;
    const areFilterRulesUnchanged =
      isLoadingMore || isLoadingNewSort || !isLoading;
    const hasAppliedFilterRules = !!currentFilterRules.value.length;

    return areFilterRulesUnchanged && hasAppliedFilterRules && hasSearchMatches;
  });

  const hasNoSearchMatches = computed(() => {
    const hasSearchMatches = totalResults?.value > 0;

    return (
      (hasFilterRules.value || hasDateRangeFilter.value) && !hasSearchMatches
    );
  });

  return {
    shouldDisplaySearchMatches,
    hasNoSearchMatches,
    isNewFilterRuleSearch,
    isNewSearch,
    isNewSortSearch
  };
};
