
































































































































































































import {
  computed,
  defineComponent,
  inject,
  reactive,
  ref,
  watch
} from "@/composition-api";
import deepEqual from "fast-deep-equal";
import { defaultSort as sort } from "../nav/config/index";
import { useResetFilterRules } from "@/components/nav/navigation.composition";
import ChevronDownIcon from "@/assets/vectors/icons/chevron-down.svg?inline";
import CloseIcon from "@/assets/vectors/icons/close.svg?inline";
import SearchIcon from "@/assets/vectors/icons/search.svg?inline";
import store from "@/store";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";
import UiFilterChip from "@/components/ui/UiFilterChip.vue";
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import useBreakpoints from "@/composition/useBreakpoints";
import useExpandedSearch from "@/composition/useExpandedSearch";
import useExpandedSearchModule from "@/composition/useExpandedSearchModule";
import useFilterRulesCount from "@/composition/useFilterRulesCount";
import useFilterSearchFields from "./AdvancedFilters/useFilterSearchFields";
import useExpandedSearchTooltipInfo from "./AdvancedFilters/expandedSearchTooltipInfo";
import useFullTextSearch from "./useFullTextSearch";
import useMatomoEvent from "@/composition/useMatomoEvent";
import useRouter from "@/composition/useRouter";
import { default as ListIcon } from "@/assets/vectors/icons/list.svg?inline";
import { default as StarIcon } from "@/assets/vectors/icons/star-filled.svg?inline";
import UiButton from "@dpa-id-components/ui-button";
import "@dpa-id-components/ui-button/dist/UiButton.css";
import UiIcon from "@dpa-id-components/ui-icon";
import "@dpa-id-components/ui-icon/dist/UiIcon.css";
import UiTooltip from "@dpa-id-components/ui-tooltip";
import "@dpa-id-components/ui-tooltip/dist/UiTooltip.css";
import Vue from "vue";

export default defineComponent({
  components: {
    SearchIcon,
    CloseIcon,
    UiFilterChip,
    UiButtonIcon,
    ChevronDownIcon,
    UiTransitionOpacity,
    ListIcon,
    StarIcon,
    UiButton,
    UiIcon,
    UiTooltip
  },
  setup(_, { emit }) {
    /* Compositions used */

    const { isMobile } = useBreakpoints();
    const {
      fullTextSearch,
      fullTextFilterRules,
      removeFilterRuleInputRepresentation
    } = useFullTextSearch();
    const { updateFullTextFilterRules } = useFilterSearchFields();
    const { numberOfSearchCriteria } = useExpandedSearchModule();
    const { hasFilterRules } = useFilterRulesCount();
    const { expandedSearchTooltipInfo } = useExpandedSearchTooltipInfo();

    const {
      closeExpandedSearch,
      executeSearch,
      redirectToListView
    } = useExpandedSearch();

    const { resetFilterRules } = useResetFilterRules();

    /* Declare UI states */

    const searchButtonRef = ref();
    const clearButtonRef = ref();

    const inputState = reactive({
      focused: false,
      hovered: false
    });

    const iconState = reactive({
      search: {
        hovered: false
      },
      clear: {
        hovered: false
      }
    });

    const searchInputRef = ref(null);
    function editSearchInputRef() {
      searchInputRef.value.focus();
    }

    const isExpandedSearchOpen = computed(
      () => store.state.expandedSearch.isExpandedSearchOpen
    );

    /* Filter chips logic */

    const storeFullTextRules = computed(
      () => store.getters["filterRules/fullTextFilterRulesExcludingSpeakers"]
    );

    const appliedFullTextRules = computed(
      () => store.getters["expandedSearch/appliedFullTextRules"]
    );

    const areTextRulesApplied = computed(() => {
      const appliedText = appliedFullTextRules.value.map(
        ({ values, exclude }) => ({
          values,
          exclude
        })
      );
      const typedText = fullTextFilterRules.value.map(
        ({ values, exclude }) => ({ values, exclude })
      );

      return deepEqual(appliedText, typedText);
    });

    const { route, router } = useRouter();
    const playlistId = computed(() => route.value.query.playlistId);

    const areFilterChipsVisible = computed(() => {
      if (isExpandedSearchOpen.value) {
        return areTextRulesApplied.value;
      }

      return (
        !inputState.focused &&
        (!!storeFullTextRules.value.length ||
          !!playlistId.value ||
          route.value.query.audioType === "amyFavorites")
      );
    });

    const isFixedFilterChip = computed(() => {
      return (
        !!playlistId.value || route.value.query.audioType === "amyFavorites"
      );
    });
    const removePlaylistQuery = () => {
      const updatedQuery = { ...route.value.query };
      delete updatedQuery.playlistId;
      router.push({ query: updatedQuery });

      store.commit("filterRules/removeFilterRulesByType", {
        type: "FILTER_GUID"
      });

      executeSearch();
    };

    const removeFavoriteQuery = () => {
      const updatedQuery = { ...route.value.query };
      delete updatedQuery.audioType;
      router.push({ query: updatedQuery });

      store.commit("filterRules/removeFilterRulesByType", {
        type: "FILTER_IS_FAVORITE"
      });

      executeSearch();
    };

    const deleteChip = (index: number) => {
      // remove filterRule in store
      const filterRule = fullTextFilterRules.value[index];
      store.commit("filterRules/removeFilterRule", { filterRule });

      // remove string fragment representing filterRule from fullTextSearch
      removeFilterRuleInputRepresentation(filterRule);

      // redirect to homepage if no filter rules are left after chip deletion.
      if (!hasFilterRules.value) {
        router.push({ path: "/audioitems" });
        executeSearch();
      } else if (!isExpandedSearchOpen.value) {
        executeSearch();
      }
    };

    /* Interaction events from advanced search */

    const eventBus: Vue = inject("eventBus");
    eventBus.$on("search-click", () => {
      inputState.focused = false;
    });

    const onSearchCancelled = () => {
      if (!areFilterChipsVisible.value) {
        editSearchInputRef();
      }
    };

    eventBus.$on("cancel-search", onSearchCancelled);

    /* Enter, blur and toggle handling */

    const onEnter = ({ isEnter } = { isEnter: true }) => {
      // "clean-up" input if no rules are found
      const inputHasNoFilterRules =
        fullTextFilterRules.value && !fullTextFilterRules.value.length;
      if (inputHasNoFilterRules) {
        fullTextSearch.value = "";
      }

      if (!deepEqual(storeFullTextRules.value, fullTextFilterRules.value)) {
        updateFullTextFilterRules({
          updatedFilterRules: fullTextFilterRules.value
        });
      }

      if (
        isEnter &&
        !deepEqual(storeFullTextRules.value, appliedFullTextRules.value)
      ) {
        executeSearch();
        useMatomoEvent({
          category: "Search",
          action: "Search Bar Hit Enter",
          name: "Search Bar"
        });
      }

      redirectToListView();

      if (isEnter) searchInputRef.value.blur();
      inputState.focused = false;
    };

    const onBlur = () => {
      onEnter({ isEnter: false });
    };

    const toggleExpandedSearch = () => {
      if (!isExpandedSearchOpen.value) {
        emit("expanded-search-open");
        store.commit("expandedSearch/setIsExpandedSearchOpen", true);
        useMatomoEvent({
          category: "Advanced Search",
          action: 'Click "Erweiterte Suche"',
          name: "Open Advanced Search"
        });
      } else {
        closeExpandedSearch();
      }
    };

    /* Criteria label */

    const getLabelText = (num: number) => {
      if (!num) {
        return "";
      }
      if (num > 1) {
        return `+${num} Suchkriterien`;
      }
      return "+1 Suchkriterium";
    };
    const criteriaLabelText = computed(() =>
      getLabelText(numberOfSearchCriteria.value)
    );

    /* Computed classes */
    const toggleExpandedSearchButtonClasses = computed(() => {
      if (
        isExpandedSearchOpen.value ||
        (areFilterChipsVisible.value && !inputState.focused)
      ) {
        return "grayBtn";
      }

      if (fullTextSearch.value && !appliedFullTextRules.value) {
        return inputState.focused ? "text-blue" : "text-gray-800";
      }

      if (inputState.focused) {
        return "defaultBtn text-blue";
      }

      return "defaultBtn text-gray-700";
    });

    const searchInputClasses = computed(() => ({
      "text-transparent": areFilterChipsVisible.value,
      "border-gray-400": inputState.hovered && !inputState.focused,
      "pr-64": !isMobile.value && !criteriaLabelText.value,
      "pr-80": !isMobile.value && criteriaLabelText.value
    }));

    const buttonClasses = computed(() => {
      const inputStateSearchClasses = inputState.focused
        ? "bg-blue hover:bg-blue-dark"
        : "hover:bg-blue";

      const inputStateClearClasses = inputState.focused
        ? "text-blue hover:text-blue-dark hover:bg-gray-300"
        : "hover:bg-gray-300";

      const searchHoverClasses = iconState.search.hovered
        ? "focus:outline-none"
        : "";

      const clearHoverClasses = iconState.clear.hovered
        ? "focus:outline-none"
        : "";

      return {
        search: `${inputStateSearchClasses} ${searchHoverClasses}`,
        clear: `${inputStateClearClasses} ${clearHoverClasses}`
      };
    });

    const iconClasses = computed(() => ({
      search:
        iconState.search.hovered || inputState.focused
          ? "text-white"
          : "text-gray-900",
      clear: inputState.focused
        ? "text-blue hover:text-blue-dark"
        : "text-gray-800 hover:text-gray-900"
    }));

    const resetFocus = (element: HTMLElement | Vue) => {
      if (element instanceof HTMLElement) {
        element.blur();
      } else {
        const el = element.$el as HTMLElement;
        el?.blur();
      }
    };

    const trackSearchIconEvent = () => {
      useMatomoEvent({
        category: "Search",
        action: "Search Bar Click Search Icon",
        name: "Search Bar"
      });
    };

    /* search and clear button interaction */
    const onClearButtonClick = () => {
      if (store.state.filterRules.filterRules.length) {
        router.push({ path: "/audioitems", query: { sort } });
        resetFilterRules();
        executeSearch();
      }

      resetFocus(clearButtonRef.value);
    };

    const onSearchButtonClick = () => {
      executeSearch();
      resetFocus(searchButtonRef.value);
      trackSearchIconEvent();
    };

    const searchContainerClasses = computed(() => {
      if (inputState.focused === true) {
        return ["border-blue"];
      }

      if (inputState.hovered === true) {
        return ["border-gray-400"];
      }

      return [];
    });

    const tooltipInfo = expandedSearchTooltipInfo;

    // Force re-render chips with :key prop: https://medium.com/emblatech/ways-to-force-vue-to-re-render-a-component-df866fbacf47
    const chipsRenderKey = ref(0);
    watch(
      appliedFullTextRules,
      () => {
        chipsRenderKey.value = chipsRenderKey.value + 1;
      },
      { deep: true, immediate: true }
    );

    return {
      appliedFullTextRules,
      areFilterChipsVisible,
      buttonClasses,
      chipsRenderKey,
      clearButtonRef,
      criteriaLabelText,
      deleteChip,
      editSearchInputRef,
      executeSearch,
      fullTextFilterRules,
      fullTextSearch,
      iconClasses,
      iconState,
      inputState,
      isExpandedSearchOpen,
      isMobile,
      onBlur,
      onClearButtonClick,
      onEnter,
      onSearchButtonClick,
      resetFilterRules,
      resetFocus,
      searchButtonRef,
      searchInputClasses,
      searchInputRef,
      toggleExpandedSearchButtonClasses,
      toggleExpandedSearch,
      trackSearchIconEvent,
      searchContainerClasses,
      playlistId,
      removePlaylistQuery,
      removeFavoriteQuery,
      tooltipInfo,
      isFixedFilterChip
    };
  }
});
