<template>
  <transition
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    :enter-active-class="
      `transition-opacity duration-${enterDuration} ease-linear`
    "
    leave-class="opacity-100"
    :leave-active-class="
      `transition-opacity duration-${leaveDuration} ease-linear`
    "
    leave-to-class="opacity-0"
    @after-enter="$emit('after-enter')"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  props: {
    enterDuration: {
      type: String,
      default: "200"
    },
    leaveDuration: {
      type: String,
      default: "200"
    }
  }
};
</script>
