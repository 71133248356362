import "@babel/polyfill";
import "unfetch/polyfill";
import "intersection-observer";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import Vue from "vue";
import { sync } from "vuex-router-sync";
import VueObserveVisibility from "vue-observe-visibility";
import PortalVue from "portal-vue";
import VueMatomo from "vue-matomo";
import replaceAll from "string.prototype.replaceall";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import App from "./App.vue";
import "@/assets/styles/index.css";
import getMatomoConfig from "../matomo.config";

// Polyfill for String.prototype.replaceAll
if (!String.prototype.replaceAll) {
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = replaceAll.getPolyfill();
}

if (window.navigator && navigator.serviceWorker) {
  // Removes broken service worker registrations
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach((registration: ServiceWorkerRegistration) => {
      registration.unregister();
    });
  });
}

Vue.use(VueObserveVisibility);
Vue.use(PortalVue);

// sync router in vuex state
sync(store, router);

Vue.config.productionTip = false;

async function awaitStageConfig() {
  if (!store.state.stageconfig.config) {
    await store.dispatch("stageconfig/fetchStageConfig");
  }
}

awaitStageConfig()
  .then(function success() {
    /**
     * Matomo (Tracking) Configuration
     */
    if (store.state.stageconfig.config.features.enableMatomoTracking) {
      Vue.use(VueMatomo, getMatomoConfig(router));
    }

    /**
     * Sentry Error Logging
     */
    Sentry.init({
      Vue,
      dsn: store.state.stageconfig.config["sentry.dsn"],
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["app.dpa-audio-hub.de"]
        })
      ],
      ignoreErrors: ["Network Error", "unknown", "ChunkLoadError"],
      // We have set the tracesSampleRate from 1.0 to 0.5 to reduce the transaction counts in the sentry.
      tracesSampleRate: 0.5,
      environment: store.state.stageconfig.config.stage
    });

    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount("#app");
  })
  .catch(err => console.log(err));
