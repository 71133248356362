import { MutationTree } from "vuex";
import { PlaylistState } from "./types";
import Vue from "vue";

export const mutations: MutationTree<PlaylistState> = {
  setPlaylists(state, playlists) {
    state.playlists = playlists;
  },
  setPlaylist(state, { index, playlist }) {
    Vue.set(state.playlists, index, playlist);
  },
  deletePlaylist(state, { index }) {
    Vue.delete(state.playlists, index);
  },
  addAudioItemToPlaylist(state, { audioItems, playlist }) {
    const playlistIndex = state.playlists.findIndex(
      ({ id }) => id === playlist.id
    );
    Vue.set(
      state.playlists[playlistIndex],
      "audioItems",
      playlist.audioItems.concat(...audioItems)
    );
  },
  removeAudioItemFromPlaylist(state, { audioItem, playlist }) {
    const audioItemIndex = playlist.audioItems.indexOf(audioItem.guid);
    const audioItems = [...playlist.audioItems];
    audioItems.splice(audioItemIndex, 1);
    const playlistIndex = state.playlists.findIndex(
      ({ id }) => id === playlist.id
    );
    Vue.set(state.playlists[playlistIndex], "audioItems", audioItems);
  },
  setShowCreatePlaylistDialog(state, value) {
    state.showCreatePlaylistDialog = value;
  },
  setIsLoadingStatus(state) {
    state.status = "loading";
  },
  setLoadedStatus(state) {
    state.status = "";
  }
};
