function average(arr: number[]) {
  const sum = arr.reduce((acc, val) => {
    return acc + Math.abs(val);
  }, 0);

  return sum / arr.length;
}

export function chunks(data: number[], chunkSize: number) {
  return (acc: number[], val: number, index: number) => {
    if (index % chunkSize === 0) {
      return [...acc, data.slice(index, index + chunkSize)];
    }
    return acc;
  };
}

/**
 * Reduces the number of data points in a waveform array while preserving an
 * approximation of its shape. The objective here is to render smaller versions
 * of waveform
 */
export function downsample(data: number[], size: number): number[] {
  const chunkSize = Math.floor(data.length / size);
  const result = data.reduce(chunks(data, chunkSize), []).map(average);

  return result;
}

export function multiplier(data: number[]) {
  return Math.max(...data) ** -1;
}

export function normalize(data: number[]) {
  const result = data.map(n => n * multiplier(data));

  return result;
}

function reduceWaveformData(data: number[], size: number) {
  if (!data.length) return [];

  return normalize(downsample(data, size));
}

export default reduceWaveformData;
