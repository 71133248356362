<template>
  <portal :to="portalTargetName">
    <ui-transition-opacity>
      <div
        v-bind="$attrs"
        class="fixed inset-0 z-50 modalWrapper"
        role="dialog"
      >
        <div
          class="fixed inset-0 bg-black z-10 opacity-60"
          data-test="background"
          @click="$emit('close')"
        />
        <div
          class="container shadow-lg z-50 scrollableModal"
          :class="{ 'overflow-y-auto scrolling-touch': scrollable }"
          :style="`max-height: ${maxHeight};`"
        >
          <header
            v-if="$slots.header"
            class="flex items-center justify-between h-12 py-3 pl-4 pr-2 bg-gray-100 lg:h-14 lg:pr-3 lg:pl-6 lg:py-4"
          >
            <slot name="header" />
          </header>
          <main class="px-4 pt-6 pb-3 lg:px-6">
            <slot />
          </main>
          <footer
            v-if="$slots.footer"
            class="flex justify-between px-4 py-6 lg:px-6"
          >
            <slot name="footer" />
          </footer>
        </div>
      </div>
    </ui-transition-opacity>
  </portal>
</template>
<script>
/**
 * Copied from campus_collab_frontend
 */
import { defineComponent } from "@/composition-api";
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import useModalScrollPrevention from "@/composition/useModalScrollPrevention";

export default defineComponent({
  components: {
    UiTransitionOpacity
  },
  inheritAttrs: false,
  props: {
    scrollable: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: String,
      default: null
    },
    portalTargetName: {
      type: String,
      default: "dialog"
    }
  },
  setup() {
    useModalScrollPrevention();
  }
});
</script>
<style>
.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 600px;
  margin: 0 auto;
}

.scrollableModal {
  background-color: white;
  overflow-y: scroll;
  max-height: 100vh;
}
</style>
