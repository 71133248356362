interface ConceptItemsOptions {
  type: string;
  filterNames: string[];
}

const DEFAULT_LOCALE = process.env.VUE_APP_I18N_LOCALE;

const GENRE_NAMES: string[] = [
  "Meldung",
  "Überblick",
  "Zusammenfassung",
  "Achtung",
  "KORR-Bericht",
  "Interview",
  "Infobox",
  "Umfrage"
];

const GENRE: ConceptItemsOptions = {
  type: "genres",
  filterNames: GENRE_NAMES
};

const RESSORT_NAMES: string[] = [
  "Politik",
  "Wirtschaft",
  "Panorama",
  "Wissen",
  "Medien",
  "Kultur",
  "Sport"
];

const RESSORTS: ConceptItemsOptions = {
  type: "ressorts",
  filterNames: RESSORT_NAMES
};

const AREA_NAMES: string[] = [
  "Baden-Württemberg",
  "Bayern",
  "Berlin",
  "Brandenburg",
  "Bremen",
  "Hamburg",
  "Hessen",
  "Mecklenburg-Vorpommern",
  "Niedersachsen",
  "Nordrhein-Westfalen",
  "Rheinland-Pfalz",
  "Saarland",
  "Sachsen",
  "Sachsen-Anhalt",
  "Schleswig-Holstein",
  "Thüringen",
  "Deutschland",
  "International"
];

const AREAS: ConceptItemsOptions = {
  type: "areas",
  filterNames: AREA_NAMES
};

const getOptionsByType = ({ type, filterNames = [] }: ConceptItemsOptions) => (
  state: any
) => (locale = DEFAULT_LOCALE) =>
  state[type]
    .map(({ id, names }) => ({
      value: id,
      text: names
        .filter(
          name =>
            (name.locale ? name.locale === locale : true) &&
            // NB: If the UI requires only certain names to be displayed
            (filterNames.length
              ? names.some(({ text }) => filterNames.includes(text))
              : true)
        )
        .reduce((acc, { role, text }) => {
          // If there is no text at all
          if (!acc) {
            return text;
          }

          // Replace if a "gui" or "display" text is found
          if (acc && (role || "").match(/\/gui|\/display/)) {
            return text;
          }

          return acc;
        }, "")
    }))
    .filter(({ text }) => !!text);

const getAreaOptions = getOptionsByType(AREAS);

const getGenreOptions = getOptionsByType(GENRE);

const getRessortOptions = getOptionsByType(RESSORTS);

export default {
  getAreaOptions,
  getGenreOptions,
  getRessortOptions
};
