interface FilterRule {
  exclude: boolean;
  type: string;
  values: string[];
}

interface HateosLink {
  href: string;
}

interface FilterPassBadgeLinks {
  profile: HateosLink;
  search: HateosLink;
  self: HateosLink;
}

export interface FilterPassBadgeAPI {
  _links: FilterPassBadgeLinks;
  color: string;
  description: string;
  filterRules: FilterRule[];
  isNotificationsEnabled: boolean;
  isPublic: boolean;
  isVisible: boolean;
  name: string;
  order: number;
  owner: string;
}

// REVIEW: Could use partials?
// @see: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-1.html
export interface FilterPassBadge {
  color: string;
  isPublic: boolean;
  isVisible: boolean;
  name: string;
  order: number;
}

export const filterIsVisible = (passBadge: FilterPassBadge): boolean =>
  passBadge.isVisible;

export const sortByIsPublic = (
  a: FilterPassBadge,
  b: FilterPassBadge
): number => {
  if (!a.isPublic && a.isPublic === b.isPublic) {
    return 0;
  }
  if (a.isPublic && a.isPublic === b.isPublic) {
    return a.order - b.order;
  }
  if (a.isPublic && !b.isPublic) {
    return 1;
  }
  return -1;
};

export const createFilterPassBadge = (
  passBadge: FilterPassBadgeAPI
): FilterPassBadge => {
  const { color, isPublic, isVisible, name, order } = passBadge;
  return {
    color,
    name,
    isPublic,
    order,
    isVisible
  };
};
