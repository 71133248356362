/* eslint-disable no-console */

import api from "@/api";
import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "../../types";
import { PlaylistState } from "./types";
import orderBy from "lodash.orderby";

const playlistsServiceURL = "playlists";

export const getPlaylists = ({ authJWT }) =>
  new Promise((resolve, reject) =>
    api
      .get({
        url: `${playlistsServiceURL}?size=100`,
        authJWT
      })
      .then(res => resolve(res.playlists))
      .catch(err => reject(err))
  );

export const searchPlaylistsNames = ({ authJWT, search }) =>
  new Promise((resolve, reject) =>
    api
      .post({
        url: `${playlistsServiceURL}/names`,
        authJWT,
        payload: {
          search,
          page: 0,
          size: 100
        }
      })
      .then(res => resolve(res.content))
      .catch(err => reject(err))
  );

const insertPlaylist = ({ playlist, authJWT }) =>
  new Promise((resolve, reject) =>
    api
      .post({
        url: playlistsServiceURL,
        payload: playlist,
        authJWT
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  );

const addToExistingPlaylist = ({ playlistId, audioItems, authJWT }) =>
  new Promise((resolve, reject) =>
    api
      .post({
        url: `${playlistsServiceURL}/${playlistId}/bulk-items`,
        payload: { guids: audioItems },
        authJWT
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
  );

const updatePlaylist = async ({ playlist, authJWT }) => {
  try {
    const { playlists } = await api.put({
      url: `${playlistsServiceURL}/${playlist.id}`,
      payload: playlist,
      authJWT
    });
    return playlists?.[0];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deletePlaylist = async ({ playlist, authJWT }) => {
  try {
    await api.delete({
      url: `${playlistsServiceURL}/${playlist.id}`,
      authJWT
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deleteAudioItemsFromPlaylist = async ({
  playlist,
  audioItems,
  authJWT
}) => {
  try {
    await axios.delete(
      `/${playlistsServiceURL}/api/v1/${playlist.id}/bulk-items`,
      {
        headers: {
          Authorization: `Bearer ${authJWT}`
        },
        data: {
          guids: audioItems
        }
      }
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const actions: ActionTree<PlaylistState, RootState> = {
  fetchPlaylists: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit, state, rootGetters, rootState },
    { authJWT, search }
  ) => {
    const res: any = search?.length
      ? await searchPlaylistsNames({ authJWT, search })
      : await getPlaylists({ authJWT });

    const playlists = orderBy(res, ["order"], ["asc"]);

    if (playlists) {
      commit("setPlaylists", playlists);
    }

    return playlists;
  },
  savePlaylist: async ({ commit, state }, { authJWT, playlist }) => {
    commit("setIsLoadingStatus");

    const savedPlaylist = await insertPlaylist({ playlist, authJWT });
    const playlistIndex = state.playlists.findIndex(
      ({ name }) => name === playlist.name
    );
    commit("setPlaylist", {
      index: playlistIndex,
      playlist: savedPlaylist
    });
    commit("setLoadedStatus");
  },
  addToPlaylist: async (
    { commit, state },
    { authJWT, playlistId, audioItems }
  ) => {
    commit("setIsLoadingStatus");

    await addToExistingPlaylist({ playlistId, audioItems, authJWT });
    const playlist = state.playlists.find(it => it.id === playlistId);
    commit("addAudioItemToPlaylist", {
      audioItems,
      playlist
    });
    commit("setLoadedStatus");
  },
  updatePlaylistOrder: async ({ commit, state }, { authJWT, playlist }) => {
    try {
      commit("setIsLoadingStatus");
      const existingOrder = state.playlists.findIndex(
        ({ order }) => order === playlist.order
      );
      const updatedPlaylist = await updatePlaylist({ playlist, authJWT });
      commit("setPlaylist", {
        index: existingOrder,
        playlist: updatedPlaylist
      });
      commit("setLoadedStatus");
      return updatedPlaylist;
    } catch (error) {
      console.error(error);
      commit("setLoadedStatus");
      throw error;
    }
  },

  updateExistingPlaylist: async ({ commit, state }, { authJWT, playlist }) => {
    try {
      commit("setIsLoadingStatus");
      const existingIndex = state.playlists.findIndex(
        ({ id }) => id === playlist.id
      );
      const updatedPlaylist = await updatePlaylist({ playlist, authJWT });
      commit("setPlaylist", {
        index: existingIndex,
        playlist: updatedPlaylist
      });
      commit("setLoadedStatus");
      return updatedPlaylist;
    } catch (error) {
      console.error(error);
      commit("setLoadedStatus");
      throw error;
    }
  },

  deleteExistingPlaylist: async ({ commit, state }, { authJWT, playlist }) => {
    try {
      commit("setIsLoadingStatus");
      const existingIndex = state.playlists.findIndex(
        ({ id }) => id === playlist.id
      );
      deletePlaylist({ playlist, authJWT });
      commit("deletePlaylist", {
        index: existingIndex
      });
      commit("setLoadedStatus");
    } catch (error) {
      console.error(error);
      commit("setLoadedStatus");
      throw error;
    }
  },
  removeAudioItemsFromPlaylist: async (
    { commit },
    { authJWT, playlist, audioItems }
  ) => {
    try {
      deleteAudioItemsFromPlaylist({ playlist, authJWT, audioItems });
      audioItems.forEach(audioItem => {
        commit("removeAudioItemFromPlaylist", {
          audioItem,
          playlist
        });
      });
      commit("setLoadedStatus");
    } catch (error) {
      console.error(error);
      commit("setLoadedStatus");
      throw error;
    }
  }
};
