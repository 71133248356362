import { computed, ComputedRef } from "@/composition-api";
import store from "@/store";

const MIN_SEARCH_TEXT_LENGTH = 1;

export default () => {
  const fullTextFieldsFilterRules: ComputedRef<FilterRules.FilterRule[]> = computed(
    () => store.getters["filterRules/fullTextFilterRulesExcludingSpeakers"]
  );

  const logicalAndFullTextFilterRules = computed(() =>
    fullTextFieldsFilterRules.value?.filter(
      ({ exclude, values }) => !exclude && values.length === 1
    )
  );

  const logicalNotFullTextFilterRules = computed(() =>
    fullTextFieldsFilterRules.value?.filter(
      ({ exclude, values }) => exclude && values.length === 1
    )
  );

  const logicalOrFullTextFilterRules = computed(() =>
    fullTextFieldsFilterRules.value?.filter(
      ({ exclude, values }) => !exclude && values.length > 1
    )
  );

  const fullTextHasFilteredFields = computed(() =>
    fullTextFieldsFilterRules.value?.some(
      filterRule => filterRule.fields?.length
    )
  );

  const isDisabled = computed(() => {
    const anyFullText = fullTextFieldsFilterRules.value.find(
      ({ values }) => values.length
    )?.values[0];

    return anyFullText ? anyFullText.length < MIN_SEARCH_TEXT_LENGTH : true;
  });

  // Helper to check if a full text search field is already checked
  const getIsFieldValueInitiallyChecked = (searchFieldName: string) => {
    if (fullTextHasFilteredFields.value) {
      const filterRule = fullTextFieldsFilterRules.value[0];
      const checkedValues = filterRule.fields;

      return checkedValues.some(value => value === searchFieldName);
    }

    return true;
  };

  const updateFullTextFilterRules = (payload: {
    updatedFilterRules: FilterRules.FilterRule[];
  }) => {
    const { updatedFilterRules } = payload;
    const speakerFilterRules = store.getters["filterRules/speakerFilterRules"];

    store.commit("filterRules/setFilterRulesByType", {
      type: "FILTER_FULLTEXT_FIELDS",
      // EC-2663: do not alter filterRules for speakers
      filterRules: [...updatedFilterRules, ...speakerFilterRules]
    });
  };

  return {
    fullTextFieldsFilterRules,
    fullTextHasFilteredFields,
    isDisabled,
    getIsFieldValueInitiallyChecked,
    logicalAndFullTextFilterRules,
    logicalNotFullTextFilterRules,
    logicalOrFullTextFilterRules,
    updateFullTextFilterRules
  };
};
