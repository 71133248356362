






































import { PropType } from "vue";
import { defineComponent } from "@/composition-api";
import { reduceWaveformData } from "./lib";

export default defineComponent({
  name: "AHWaveform",
  props: {
    data: {
      type: Array as PropType<number[]>,
      required: true
    },
    progress: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 24
    },
    width: {
      type: Number,
      default: 128
    }
  },
  data() {
    return {
      padding: 1,
      progressHover: 0,
      hoverActive: false,
      mouseDownActive: false
    };
  },
  computed: {
    bars(): number[] {
      const dataPoints = reduceWaveformData(this.data, this.width / 4);

      return dataPoints.map(p => p * (this.height - this.padding * 2));
    },
    barWidth(): number {
      return (this.width - this.padding * 2) / this.bars.length;
    }
  },
  watch: {
    /**
     * Trigger mouseenter if progress changes, covers the scenario where the
     * user is already inside the bounding box of the waveform while the audio
     * starts playing.
     */
    progress: {
      immediate: true,
      handler(newVal) {
        if (newVal > 0) {
          this.handleMouseEnter();
        }
      }
    }
  },
  methods: {
    getProgress(e: MouseEvent) {
      const { offsetX } = e;
      const { width } = (this.$refs.slider as Element).getBoundingClientRect();
      const xBound = Math.min(Math.max(offsetX, 0), width);
      const result = (xBound / width) * 100;

      return result;
    },
    handleMouseMove(e: MouseEvent) {
      if (this.hoverActive) {
        this.progressHover = this.getProgress(e);
      }

      if (this.mouseDownActive) {
        this.$emit("progressChange", this.getProgress(e));
      }
    },
    handleMouseEnter() {
      if (this.progress > 0) {
        this.hoverActive = true;
      }
    },
    handleMouseLeave(e: MouseEvent) {
      this.hoverActive = false;
      this.progressHover = 0;

      if (this.mouseDownActive) {
        this.$emit("progressChange", this.getProgress(e));
      }
    },
    handleMouseDown() {
      this.mouseDownActive = true;
    },
    handleMouseUp(e: MouseEvent) {
      this.mouseDownActive = false;
      this.$emit("progressChange", this.getProgress(e));
    },
    getColorClass({
      progress,
      progressHover,
      index,
      bars
    }: {
      progress: number;
      progressHover: number;
      index: number;
      bars: number[];
    }) {
      if ((progressHover * bars.length) / 100 <= index) {
        if (progress > (index / bars.length) * 100) {
          return "text-gray-900";
        }
        return "text-gray-400";
      }
      return "text-blue";
    }
  }
});
