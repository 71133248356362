<template>
  <div>
    <div>
      <p class="help-headline ml-4 mt-4">Hilfe und Feedback</p>
      <p class="help-paragraph ml-4 mt-2 mr-3 mb-4">
        Haben Sie Fragen zur Anwendung <br />
        oder ist ein Problem aufgetreten? <br />
        Wir helfen gerne.
      </p>
    </div>
    <ui-list style="border-left: none; border-right: none">
      <ui-list-item
        v-for="(item, index) in linksConfig"
        :key="`help-item-${index}`"
        @list-item-click="openHelpLink(item.link, item.target)"
      >
        <ui-icon class="mr-3 help-item-icon" size="medium" :name="item.icon" />
        <span class="help-item-text">
          {{ item.title }}
        </span>
      </ui-list-item>
    </ui-list>
  </div>
</template>

<script>
import UiList from "@dpa-id-components/ui-list";
import UiListItem from "@dpa-id-components/ui-list-item";
import UiIcon from "@dpa-id-components/ui-icon";

export default {
  components: {
    UiList,
    UiListItem,
    UiIcon
  },
  props: {
    linksConfig: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const openHelpLink = (helpURL, target) => {
      window.open(helpURL, target);
    };

    return {
      openHelpLink
    };
  }
};
</script>

<style scoped>
.help-headline {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.help-paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8c8c8c;
}

.help-item-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.help-item-icon {
  min-height: 20px;
  min-width: 20px;
}
</style>
