import { computed } from "@/composition-api";
import useExpandedSearchModule from "@/composition/useExpandedSearchModule";
import useFullTextSearch from "@/components/AHSearch/useFullTextSearch";
import store from "@/store";

export default () => {
  const { numberOfSearchCriteria } = useExpandedSearchModule();
  const { fullTextFilterRules } = useFullTextSearch();

  const filterRulesCount = computed(() => {
    return fullTextFilterRules.value.length + numberOfSearchCriteria.value;
  });

  const hasFilterRules = computed(() => {
    const favoritesAndPlaylistFilterCount = store.state.filterRules.filterRules.reduce(
      (count, { type }) => {
        if (type === "FILTER_GUID" || type === "FILTER_IS_FAVORITE") {
          return count + 1;
        }
        return count;
      },
      0
    );

    return filterRulesCount.value + favoritesAndPlaylistFilterCount > 0;
  });

  const appliedFullTextRules = computed(
    () => store.getters["expandedSearch/appliedFullTextRules"]
  );

  const hasAppliedFilterRules = computed(
    () => appliedFullTextRules.value.length + numberOfSearchCriteria.value > 0
  );

  return {
    appliedFullTextRules,
    hasFilterRules,
    hasAppliedFilterRules
  };
};
