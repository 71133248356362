










import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    percentage: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {}
});
