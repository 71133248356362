<template>
  <button
    class="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus-visible:outline-blue"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {};
</script>
