var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isOpen)?_c('div',{staticClass:"fixed left-0 flex flex-col flex-1 h-full overflow-x-hidden overflow-y-auto bg-white shadow w-60",class:_vm.state.isDesktop ? 'top-16 h-screen' : 'top-0 z-50',style:(_vm.calculateMaxHeight)},[(!_vm.state.isDesktop)?[_c('div',{staticClass:"p-4"},[_c('ui-icon-close',{nativeOn:{"click":function($event){return _vm.close($event)}}})],1),_c('hr',{staticClass:"border-gray-200"})]:_vm._e(),_c('div',{staticClass:"py-3 overscroll-contain"},[_vm._l((_vm.state.navConfig),function(ref,index){
var label = ref.label;
var icon = ref.icon;
var query = ref.query;
var isExactLink = ref.isExactLink;
var path = ref.path;
return _c('nav-item',{key:index,attrs:{"icon":icon,"is-exact-link":isExactLink,"label":label,"to":{ path: path, query: query }},on:{"click":function($event){return _vm.onNavClick({
            query: query,
            isExactLink: isExactLink,
            label: label,
            trackEventType: 'quickfilters'
          })}}})}),[_c('hr',{staticClass:"my-4 border-gray-200"}),(_vm.isCuratedSearchAndPlaylistsEnabled && _vm.isCuratedSearch)?_vm._l((_vm.state.curatedSearchNavigation),function(ref,index){
          var label = ref.label;
          var icon = ref.icon;
          var to = ref.to;
          var isExactLink = ref.isExactLink;
return _c('nav-item',{key:index,attrs:{"icon":icon,"label":label,"to":to,"is-exact-link":isExactLink},on:{"click":function($event){_vm.onNavClick({ query: {}, isExactLink: isExactLink });
              _vm.resetFilterRules();}}})}):_vm._e(),(_vm.isCuratedSearchAndPlaylistsEnabled)?_vm._l((_vm.state.playlistNavigation),function(ref,index){
              var label = ref.label;
              var icon = ref.icon;
              var to = ref.to;
              var isExactLink = ref.isExactLink;
return _c('nav-item',{key:index,attrs:{"icon":icon,"label":label,"to":to,"is-exact-link":isExactLink},on:{"click":function($event){_vm.onNavClick({ query: {}, isExactLink: isExactLink });
              _vm.resetFilterRules();}}})}):_vm._e(),_vm._l((_vm.state.featureTypesConfig),function(ref,index){
              var label = ref.label;
              var icon = ref.icon;
              var query = ref.query;
              var isExactLink = ref.isExactLink;
              var path = ref.path;
return _c('nav-item',{key:index,attrs:{"icon":icon,"is-exact-link":isExactLink,"label":label,"to":{ path: path, query: query }},on:{"click":function($event){return _vm.onNavClick({
                query: query,
                isExactLink: isExactLink,
                label: label,
                trackEventType: 'favorites'
              })}}})})],(_vm.isAdminOrEditor && !_vm.isMobile)?_vm._l((_vm.state.uplaodAudioNavigation),function(ref,index){
              var label = ref.label;
              var icon = ref.icon;
              var to = ref.to;
              var isExactLink = ref.isExactLink;
return _c('nav-item',{key:index,attrs:{"icon":icon,"label":label,"to":to,"is-exact-link":isExactLink},on:{"click":function($event){return _vm.onNavClick({ query: {}, isExactLink: isExactLink })}}})}):_vm._e(),(_vm.state.savedSearches.length)?[_c('hr',{staticClass:"my-4 border-gray-200"}),_c('div',{staticClass:"mb-2 ml-4 text-xs font-semibold tracking-wider text-gray-500 uppercase"},[_vm._v(" Gespeicherte Suchen ")]),_vm._l((_vm.state.savedSearches),function(savedSearch,index){return _c('div',{key:index,on:{"mouseover":function($event){_vm.showByIndex = index},"mouseleave":function($event){_vm.showByIndex = null}}},[_c('nav-item',{staticClass:"static inline-flex",attrs:{"icon":savedSearch.icon,"icon-color":savedSearch.iconColor,"is-exact-link":savedSearch.isExactLink,"label":savedSearch.label,"to":{ path: savedSearch.path, query: savedSearch.query }},on:{"click":function($event){return _vm.onNavClick({
                query: savedSearch.query,
                isExactLink: savedSearch.isExactLink,
                trackEventType: 'savedsearches'
              })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByIndex === index && !_vm.isMobile),expression:"showByIndex === index && !isMobile"}],staticClass:"absolute z-50 inline-flex p-1 nav-item-context-menu"},[_c('nav-item-context-menu',{staticClass:"rounded-full hover:bg-gray-400 focus:bg-gray-400 focus:outline-none",attrs:{"saved-search":savedSearch},on:{"option-select":_vm.onSavedSearchSelected}})],1)])],1)})]:_vm._e(),_c('hr',{staticClass:"my-3 border-gray-200"}),_vm._l((_vm.state.actions),function(ref,index){
              var label = ref.label;
              var icon = ref.icon;
              var to = ref.to;
              var isExactLink = ref.isExactLink;
return _c('nav-item',{key:index,attrs:{"icon":icon,"label":label,"to":to,"is-exact-link":isExactLink},on:{"click":function($event){return _vm.onNavClick({ query: {}, isExactLink: isExactLink })}}})})],2)],2):_vm._e(),(_vm.isOpen)?_c('div',{staticClass:"fixed bottom-0 left-0 flex flex-wrap justify-between px-4 py-2 bg-white border-t w-60",class:_vm.isDesktop ? '' : 'z-50'},[_vm._l((_vm.state.legalLinks),function(ref,index){
              var label = ref.label;
              var to = ref.to;
              var isExactLink = ref.isExactLink;
return _c('nav-item-legal-link',{key:index,attrs:{"label":label,"to":to,"is-exact-link":isExactLink},on:{"click":function($event){return _vm.onNavClick({ query: {}, isExactLink: isExactLink })}}})}),(_vm.showCookiesLink)?_c('a',{staticClass:"text-xs font-medium leading-normal text-gray-700 hover:text-blue cursor-pointer",attrs:{"onclick":"window.OneTrust?.ToggleInfoDisplay()"}},[_vm._v("Cookies")]):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }