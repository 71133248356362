import { MutationTree } from "vuex";
import deepEqual from "fast-deep-equal";
import { FilterRulesState, FilterRulesMutationPayload } from "./types";

const mutations: MutationTree<FilterRulesState> = {
  setFilterRules(state, payload: FilterRulesMutationPayload) {
    state.filterRules = payload.filterRules;
  },

  addFilterRule(state, payload: FilterRulesMutationPayload) {
    state.filterRules = [...state.filterRules, payload.filterRule];
  },

  addFilterRules(state, payload: FilterRulesMutationPayload) {
    state.filterRules = [...state.filterRules, ...payload.filterRules];
  },

  removeFilterRules(state, payload: FilterRulesMutationPayload) {
    const unchangedFilters = state.filterRules.filter(
      rule =>
        !payload.filterRules.some(filterRule => deepEqual(rule, filterRule))
    );

    state.filterRules = [...unchangedFilters];
  },

  replaceFilterRuleByType(state, payload: FilterRulesMutationPayload) {
    const { type } = payload.filterRule;
    const ruleIndex = state.filterRules.findIndex(rule => rule.type === type);

    if (ruleIndex > -1) {
      state.filterRules.splice(ruleIndex, 1, payload.filterRule);
    }
  },

  replaceFilterRules(state, payload: FilterRulesMutationPayload) {
    const updatedFilterRules = state.filterRules.map(filterRule => {
      const updatedFilterRuleIndex = payload.filterRules.findIndex(rule =>
        deepEqual(rule, filterRule)
      );
      const updatedFilterRule =
        payload.updatedFilterRules[updatedFilterRuleIndex];

      if (updatedFilterRule) return updatedFilterRule;

      return filterRule;
    });

    state.filterRules = updatedFilterRules;
  },

  setFilterRulesByType(state, payload: FilterRulesMutationPayload) {
    const unchangedFilters = state.filterRules.filter(
      rule => rule.type !== payload.type
    );

    state.filterRules = [...unchangedFilters, ...payload.filterRules];
  },

  removeFilterRulesByType(state, payload: FilterRulesMutationPayload) {
    const unchangedFilters = state.filterRules.filter(rule => {
      if (rule.type !== payload.type) {
        return true;
      }
      return false;
    });

    state.filterRules = [...unchangedFilters];
  },

  /* Removes a specific FilterRule (checking deep-equality) */
  removeFilterRule(state, payload: FilterRulesMutationPayload) {
    const index = state.filterRules.findIndex(f =>
      deepEqual(payload.filterRule, f)
    );
    state.filterRules.splice(index, 1);
  },

  setFullTextSearch(state, text: string) {
    state.fullTextSearch = text;
  }
};

export default mutations;
