import intervalToDuration from "date-fns/intervalToDuration";
import { SERVICE_CODE_MAP } from "@/components/nav/config/index";
import { Atmo, Interview, OTon, Umfrage } from "../icons";
import useAudioTypeQuery from "@/composition/useAudioTypeQuery";

/**
 * Returns the names of a persons array.
 * @param {Array<Object>} persons
 * @returns {Array<String>}
 */
export const getSpeakers = persons => persons.map(({ name }) => name);

const extractServiceCodeFromUri = uri =>
  !uri ? "" : uri.substring(uri.lastIndexOf("/") + 1, uri.length);

/**
 * @param service
 * @returns {string|undefined}
 */
export const getAudioitemType = service => {
  const audioType = useAudioTypeQuery().audioType.value;
  let serviceCode = extractServiceCodeFromUri(service?.[0]?.uri);
  if (audioType && audioType !== "amyFavorites") {
    // We do this operation for audios that have multiple service code
    serviceCode = extractServiceCodeFromUri(
      service?.filter(it => extractServiceCodeFromUri(it.uri) === audioType)[0]
        .uri
    );
  }
  return SERVICE_CODE_MAP[serviceCode]?.name;
};

/**
 * Returns minutes and seconds for a given duration
 * @param {string} duration
 * @returns {Object} time
 * @returns {number} time.minutes
 * @returns {number} time.seconds
 */
export const getDuration = duration => {
  const start = new Date();
  start.setSeconds(0);
  const end = new Date(start);
  end.setSeconds(Number(duration));
  const { minutes, seconds } = intervalToDuration({ start, end });

  return { minutes, seconds };
};

/**
 * Returns an icon component for a given string
 */
export const getAudioIcon = (value: string) => {
  /**
   * Mapping of icon value strings to vue components
   */
  const ICONS_MAP = {
    Atmo,
    "O-Ton": OTon,
    Interview,
    Umfrage
  };

  return ICONS_MAP[value];
};

export const getAudioitemLink = (origin: string, guid: string) => {
  const detailPath = `app/audioitems/list/detail/${guid}`;
  return `${origin}/${detailPath}`;
};

export default {
  getSpeakers,
  getAudioitemType,
  getDuration,
  getAudioIcon,
  getAudioitemLink
};
