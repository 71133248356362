


















import { defineComponent, ref, computed } from "@/composition-api";
import DpaIdUser from "@dpa-connect/dpa-id-user";
import AHProgressBar from "./AHProgressBar.vue";
import { getDownloadQuota } from "@/composition/useAuthorizeToken";
import useDownloadBanner from "@/composition/useDownloadBanner";
import useAuthentication from "@dpa-connect/dpa-id-user/compositions/useAuthentication";
import store from "@/store";

export default defineComponent({
  components: {
    DpaIdUser,
    AHProgressBar
  },
  props: {
    // extracted props from header
    token: {
      type: String
    },
    profileUrl: {
      type: String
    },
    logout: {
      type: Function,
      default: () => undefined
    },
    closeSearch: {
      type: Function,
      default: () => undefined
    }
  },
  setup(props, { emit }) {
    const userMenuRef = ref();
    const isUserMenuOpen = computed(() => {
      return userMenuRef.value?.showOverlay;
    });
    const used = ref();
    const total = ref();
    const isVisibleProgressBar = ref();
    const { isAuthenticated } = useAuthentication(store)();

    const { toggleDownloadBanner } = useDownloadBanner();
    const onUserMenuClick = async () => {
      emit("close-search");

      if (isUserMenuOpen.value && isAuthenticated.value) {
        const {
          userQuotaStanding,
          accessStatus,
          quotaAvailable,
          quotaUsed,
          quotaTotal,
          unlimited
        } = await getDownloadQuota();

        // set Progressbar Visibility Status
        const progressBarStatus = computed(() => {
          // User-Status set Test
          if (accessStatus == "Test") {
            if (quotaTotal < 1) {
              return false;
            } else if (isNaN(quotaTotal) && isNaN(quotaUsed)) {
              return false;
            } else if (userQuotaStanding == "TRIAL_DEACTIVATED") {
              return false;
            } else {
              return true;
            }
          }
          // User-Status set Abo
          if (accessStatus == "Abo") {
            if (unlimited && userQuotaStanding == "SUBSCRIPTION_EXPIRED") {
              return false;
            } else if (
              !unlimited &&
              userQuotaStanding == "CUSTOMER_QUOTA_NOT_ACTIVE"
            ) {
              return false;
            } else if (quotaTotal < 1) {
              return false;
            } else if (isNaN(quotaTotal) && isNaN(quotaUsed)) {
              return false;
            } else {
              return true;
            }
          }
          // User-Status set Keine
          if (accessStatus == null) {
            return false;
          }
        });

        // refer the values and status
        used.value = Number(quotaUsed);
        total.value = Number(quotaTotal);
        isVisibleProgressBar.value = progressBarStatus.value;

        toggleDownloadBanner({
          userQuotaStanding,
          accessStatus,
          quotaAvailable,
          unlimited
        });
      }
    };

    return {
      onUserMenuClick,
      userMenuRef,
      isUserMenuOpen,
      used,
      total,
      isVisibleProgressBar
    };
  }
});
