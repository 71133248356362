import { state } from "./state";
import getters from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { Module } from "vuex";
import { PlaylistState } from "./types";
import { RootState } from "../../types";

const playlists: Module<PlaylistState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default playlists;
