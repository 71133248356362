import { computed } from "@/composition-api";
import store from "@/store";
import axios from "axios";

export default async (id: string) => {
  // get media endpoint url
  const legalURL = computed(() => store.state.stageconfig.config.legalURL);

  try {
    const res = await axios(`${legalURL.value}/${id}.html`);
    const resDOM = new DOMParser().parseFromString(res.data, "text/html");
    return resDOM.querySelector("body").innerHTML;
  } catch (e) {
    console.error(e);
  }
};
