import { RootState } from "@/store/types";
import { ActionTree } from "vuex";
import { AudioItemsState } from "../audioItems/types";

export const actions: ActionTree<AudioItemsState, RootState> = {
  replaceFilterRules: (
    { commit },
    {
      removedFilterRules,
      addedFilterRules
    }: {
      removedFilterRules: FilterRules.FilterRule[];
      addedFilterRules: FilterRules.FilterRule[];
    }
  ) => {
    if (removedFilterRules.length) {
      commit("removeFilterRules", { filterRules: removedFilterRules });
    }

    commit("addFilterRules", { filterRules: addedFilterRules });
  }
};
