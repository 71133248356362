import { AudioPlayerState } from "./types";

export const state: AudioPlayerState = {
  isActive: false,
  isLoading: false,
  isPlaying: false,
  progress: 0,
  seek: 0,
  duration: 0,
  audioId: ""
};
