<template>
  <div
    class="items-center h-8 px-2 text-base font-normal leading-normal text-gray-900 whitespace-no-wrap align-middle bg-gray-300 rounded-full cursor-pointer"
    :class="isVisible ? 'inline-flex' : 'hidden'"
  >
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  }
});
</script>
